import { Box, Divider, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import useRoles from '../Hooks/useRoles';
import { TripwireRoles } from '../../../constants/TripwireRoles';
import { NavLink } from 'react-router-dom';
import { initial } from 'lodash';
import { green, purple } from '@mui/material/colors';

const AccountMenu = () => {
    const isAdmin = useRoles([TripwireRoles.Admin]);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const open = Boolean(anchor);

    const auth = useAuth();

    const onHandleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }

    const onHandleClose = () => {
        setAnchor(null);
    }

    const initial = auth.user?.profile.preferred_username != null ? auth.user?.profile.preferred_username[0] : null

    const Icon = initial ? (
    <div style={{
        width:'32px',
        height:'32px',
        borderRadius:'50%',
        lineHeight:'32px',
        background: purple[700],
        textAlign:'center',
        verticalAlign:'middle',
        display:'inline-block',
        textTransform:'uppercase'
    }}>{initial}
        </div>)
 : <Avatar />

    return (
        <>
        <IconButton size='small' onClick={onHandleOpen}>{Icon}</IconButton>
            
            <Menu
                open={open}
                anchorEl={anchor}
                onClose={onHandleClose}
            >
                <MenuItem><ListItemText>{auth.user?.profile.preferred_username}</ListItemText></MenuItem>
                <Divider />
                <MenuItem onClick={() => void auth.signoutRedirect()}>Logout</MenuItem>
            </Menu>
        </>
    )
}

export default AccountMenu;