import { Box, Grid, Paper, Card, Typography, Menu, MenuItem } from "@mui/material";
import useAPI from "../../Hooks/useAPI";
import { IApiResult } from "../../Models/IApiResult";
import useNotifications from "../../Hooks/useNotifications";
import useWindowDimensions from "../../Hooks/useWindowDimension";
import DeviceFeed from "./DeviceFeed";
import { useContext, useState } from "react";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import { ReacherDevice } from "../Models/ReacherDevice";
import { ReacherConstants } from "../../../../constants/ReacherConstants";
import PendingDevice from "./PendingDevice";
import KnownDevice from "./KnownDevice";
import EditDrawer from "../Components/EditDrawer";
import { EditDrawerOptions } from "../Components/EditDrawerOptions";
import useViewPortDimensions from "../../Hooks/useViewPort";

const DeviceManagerContainer = () => {

    const { Put, Post } = useAPI();
    const { PushSuccess, PushError } = useNotifications();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [device, setDevice] = useState<null | ReacherDevice>(null);
    const { devices } = useContext(ReacherContext) as ReacherContextType;
    const { viewPortHeight } = useViewPortDimensions();

    const [editDrawerOptions, setEditDrawerOptions] = useState<null | EditDrawerOptions>(null);

    // TODO: Need to find a way of getting the one true source of GUIDs from `Core.Contracts.Common.ReacherDeviceRegistrationStates`
    // Dll import of C# lib?
    // extra build step where we export required static guids to a JS file?
    // API endpoint we hit once and store the results ?


    const contextTarget = (element: null | HTMLElement, device: ReacherDevice) => {
        setAnchorEl(element);
        setDevice(device);
    }

    const revertDevice = (device: null | ReacherDevice) => {
        if (device) {
            Put('/api/reacher/device/regchange', { DeviceId: device.id, NewState: ReacherConstants.DeviceRegistrationStates.Pending })
                .then((data: IApiResult) => {
                    if (data.success) {
                        PushSuccess(data.messages[0]);
                    }
                    else {
                        PushError(data.messages[0]);
                    }
                })
        }
        setAnchorEl(null);
    }

    const editDevice = (device: null | ReacherDevice) => {
        if (device) {
            setEditDrawerOptions({
                isOpen: true,
                onClose: (event) => closeEditDrawer(event),
                onCancel: (event) => closeEditDrawer(event),
                onConfirm: (event, data) => renameAction(event, data),
                onChange: (data) => editData(data),
                device: device
            })
        }
        setAnchorEl(null);
    }

    const closeEditDrawer = (event: any) => {
        setEditDrawerOptions(null);
        setAnchorEl(null);
        event.stopPropagation();
    };

    const renameAction = (event: any, data: EditDrawerOptions) => {
        if (device) {
            Post('/api/reacher/device/rename', { DeviceId: device.id, DeviceName: data.device.friendlyDeviceName })
                .then((data: IApiResult) => {
                    if (data.success) {
                        PushSuccess(data.messages[0]);
                    }
                    else {
                        PushError(data.messages[0]);
                    }
                });

            closeEditDrawer(event);
        }        
    }

    const editData = (modified: EditDrawerOptions) => {
        setEditDrawerOptions(modified);
    }

    const _pendingList = devices.filter(x => x.registrationState == ReacherConstants.DeviceRegistrationStates.Pending)
        .map(dev => <PendingDevice dev={dev} key={dev.id} />);
    const _knownList = devices.filter(x => x.registrationState == ReacherConstants.DeviceRegistrationStates.Approved ||
        x.registrationState == ReacherConstants.DeviceRegistrationStates.Rejected)
        .map(dev => <KnownDevice dev={dev} setTarget={contextTarget} key={dev.id} />);

    return (
        <Box sx={{ height: viewPortHeight }}>
            <Grid container spacing={2}>
                <Grid item sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 1px' }}>
                    {_pendingList && <DeviceFeed devices={_pendingList} title="Pending Approval" />}                    
                    <DeviceFeed devices={_knownList} title="Known Devices" />
                </Grid>
            </Grid>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => revertDevice(device)} >Revert to Pending</MenuItem>
                <MenuItem onClick={() => editDevice(device)}>Edit</MenuItem>
            </Menu>
            {editDrawerOptions && <EditDrawer {...editDrawerOptions} />}
        </Box>
    );
}

export default DeviceManagerContainer;