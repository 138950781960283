import { Button, Container, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import './Anonymous.scss';

const AnonymousApp = () => {

    const auth = useAuth();

    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect()
        }
    }, [auth.isLoading, auth.isAuthenticated    ])

    return (

       <></>
    );
}

export default AnonymousApp;