import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ReacherDialogOptions } from "./ReacherDialogOptions";

const ReacherDialog = (props: ReacherDialogOptions) => {

    return <Dialog open={props.isOpen || false} onClose={e => props.onClose(e)}>
        <DialogTitle>{props.title || ""}</DialogTitle>
        <DialogContent>
            <Typography>{props?.message}</Typography>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' onClick={e => props.onCancel(e)}>Cancel</Button>
            <Button variant='contained' onClick={e => props.onConfirm(e)}>Confirm</Button>
        </DialogActions>
    </Dialog>
}

export default ReacherDialog;