import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import { IPermission } from '../../Models/IPermission';
import { ShareOptions } from "./ShareOptions";

const ShareDialog = (props: ShareOptions) => {

    const setFull = (id: string, set: IPermission[], apply: (React.Dispatch<React.SetStateAction<IPermission[]>>)) => {
        if (id === props.Item.author) return;

        const updatedPermissions = [...set];
        let index = updatedPermissions.findIndex(p => p.id === id);
        if (index > -1) {
            let permission = updatedPermissions[index];

            if (permission.full)
                updatedPermissions[index] = { ...permission, full: false, open: false, edit: false, download: false, share: false }
            else
                updatedPermissions[index] = { ...permission, full: true, open: true, edit: true, download: true, share: true }

            apply(updatedPermissions);
        }
    }

    const setPermission = (id: string, name: string, set: IPermission[], apply: (React.Dispatch<React.SetStateAction<IPermission[]>>)) => {
        if (id === props.Item.author) return;

        const updatedPermissions = [...set];
        let index = updatedPermissions.findIndex(p => p.id === id);
        if (index > -1) {
            switch (name) {
                case "open":
                    updatedPermissions[index].open = !updatedPermissions[index].open;
                    break;
                case "edit":
                    updatedPermissions[index].edit = !updatedPermissions[index].edit;
                    break;
                case "download":
                    updatedPermissions[index].download = !updatedPermissions[index].download;
                    break;
                case "share":
                    updatedPermissions[index].share = !updatedPermissions[index].share;
                    break;
            }

            // Implicitly add open permission when all any of edit/download/share are set
            if (updatedPermissions[index].edit ||
                updatedPermissions[index].download ||
                updatedPermissions[index].share)
                updatedPermissions[index].open = true;

            // Implicitly add full permission when all are set
            if (updatedPermissions[index].open &&
                updatedPermissions[index].edit &&
                updatedPermissions[index].download &&
                updatedPermissions[index].share)
                updatedPermissions[index].full = true;
            else
                updatedPermissions[index].full = false;

            apply(updatedPermissions);
        }
    }

    const fullIcon = (p: IPermission) => {
        if (p.open &&
            p.edit &&
            p.download &&
            p.share)
            return <CheckBoxIcon />
        if (!p.open &&
            !p.edit &&
            !p.download &&
            !p.share)
            return <CheckBoxOutlineBlankIcon />
        return <IndeterminateCheckBoxIcon />
    };

    const buildRow = (p: IPermission, isGroup: boolean, set: IPermission[], apply: (React.Dispatch<React.SetStateAction<IPermission[]>>)) => {
        const icon = isGroup ? <PeopleIcon /> : <PersonIcon />

        if (p.id === props.Item.author)
            return (<TableRow key={p.id}>
                <TableCell align={"center"}>
                    <IconButton disabled>
                        <CheckBoxIcon />
                    </IconButton>
                </TableCell>
                <TableCell align={"center"} sx={{ color: grey[600] }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                        <Box sx={{ mr: 1 }}>{icon}</Box>
                        <Typography>{p.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell align={"center"}>
                    <IconButton disabled>
                        <CheckBoxIcon />
                    </IconButton>
                </TableCell>
                <TableCell align={"center"}>
                    <IconButton disabled>
                        <CheckBoxIcon />
                    </IconButton>
                </TableCell>
                <TableCell align={"center"}>
                    <IconButton disabled>
                        <CheckBoxIcon />
                    </IconButton>
                </TableCell>
                <TableCell align={"center"}>
                    <IconButton disabled>
                        <CheckBoxIcon />
                    </IconButton>
                </TableCell>
            </TableRow>);

        return (
            <TableRow key={p.id} >
                <TableCell align={"left"} sx={{ width: '75px' }} onClick={() => setFull(p.id, set, apply)}>
                    <IconButton>
                        {fullIcon(p)}
                    </IconButton>
                </TableCell>
                <TableCell align={"left"} sx={{ width: 160, maxWidth: 160 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }} >
                        <Box sx={{ mr: 1 }}>{icon}</Box>
                        <Typography>{p.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell align={"center"} onClick={() => setPermission(p.id, 'open', set, apply)}>
                    <IconButton>
                        {p.open && <CheckBoxIcon />}
                        {!p.open && <CheckBoxOutlineBlankIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align={"center"} onClick={() => setPermission(p.id, 'edit', set, apply)}>
                    <IconButton>
                        {p.edit && <CheckBoxIcon />}
                        {!p.edit && <CheckBoxOutlineBlankIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align={"center"} onClick={() => setPermission(p.id, 'download', set, apply)}>
                    <IconButton>
                        {p.download && <CheckBoxIcon />}
                        {!p.download && <CheckBoxOutlineBlankIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align={"center"} onClick={() => setPermission(p.id, 'share', set, apply)}>
                    <IconButton>
                        {p.share && <CheckBoxIcon />}
                        {!p.share && <CheckBoxOutlineBlankIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

    return (
        <Dialog open={props.isOpen || false} onClose={e => props.onClose(e)} maxWidth='lg' fullWidth={true}>
            <DialogTitle>Sharing {props.Item.name}</DialogTitle>
            <DialogContent>
                <TableContainer style={{ maxHeight: 500, minHeight: 200, overflow: 'auto', scrollbarWidth: 'thin' }}>
                    <Paper>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow >
                                    <TableCell></TableCell>
                                    <TableCell align={"center"}>User/Group</TableCell>
                                    <TableCell align={"center"}>Open</TableCell>
                                    <TableCell align={"center"}>Edit</TableCell>
                                    <TableCell align={"center"}>Download</TableCell>
                                    <TableCell align={"center"}>Share</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.GroupPermissions && props.GroupPermissions.map(p => buildRow(p, true, props.GroupPermissions, props.UpdateGroupPermissions))}
                                {props.Permissions && props.Permissions.map(p => buildRow(p, false, props.Permissions, props.UpdatePermissions))}
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
                <Button variant='contained' onClick={e => props.onCancel(e)}>Cancel</Button>
                <Button variant='contained' onClick={e => props.onConfirm(e)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShareDialog;