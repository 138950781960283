import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Paper, TextField, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";
import PageTemplate from "../Common/Page";
import useAPI from "../Hooks/useAPI";
import useNotifications from "../Hooks/useNotifications";
import { IApiResult } from "../Models/IApiResult";
import { ITripwireEventSource } from "../Models/ITripwireEventSource";
import ReacherDialog from "../Reacher/Components/ReacherDialog";
import { ReacherDialogOptions } from "../Reacher/Components/ReacherDialogOptions";

const EventSourcesEdit = () => {
    let initialDialogOptions: ReacherDialogOptions = {
        isOpen: false,
        title: "",
        action: "",
        message: "",
        onClose: () => { },
        onCancel: () => { },
        onConfirm: () => { }
    }

    const { id } = useParams();
    const { Get, Put, Delete } = useAPI();
    const [source, setSource] = useState<ITripwireEventSource | null>(null);
    const [dialogOptions, setDialogOptions] = useState<ReacherDialogOptions>(initialDialogOptions);
    const { PushSuccess, PushError } = useNotifications();
    const nav = useNavigate();

    useEffect(() => {

        Get("/api/tripwire/EventSources/" + id)
            .then(data => setSource(data));

    }, [id]);

    const onChangeEventSource = (e: any) => {
        if (source === null) return;

        const newState: ITripwireEventSource = { ...source, eventSource: e.target.value };
        setSource(newState);
    }

    const onChangeVerifyString = (e: any) => {
        if (source === null) return;
        const newState: ITripwireEventSource = { ...source, verifyString: e.target.value };
        setSource(newState);
    }

    const onChangeActive = (e: any) => {
        if (source === null) return;
        const newState: ITripwireEventSource = { ...source, active: e.target.checked };
        setSource(newState);
    }

    const onSubmit = () => {
        Put('/api/tripwire/EventSources', source)
            .then((data: IApiResult) => {
                if (data.success) {
                    nav(`/tripwire/triggercredentials`);
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            });
    }

    const onDelete = () => {
        Delete('/api/tripwire/EventSources', source)
            .then((data: IApiResult) => {
                if (data.success) {
                    nav(`/tripwire/triggercredentials`);
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            });
    }

    const closeDialog = () => {
        let newDialogOptions = { ...dialogOptions, isOpen: false, };
        setDialogOptions(newDialogOptions);
    }

    const deleteDialog = () => {
        let dialogOptions: ReacherDialogOptions = {
            isOpen: true,
            title: "DELETE CREDENTIALS",
            action: "DELETE CREDENTIALS",
            message: "Are you sure you wish to delete this set of tripwire trigger credentials?",
            onClose: () => closeDialog(),
            onCancel: () => closeDialog(),
            onConfirm: () => onDelete()
        };
        setDialogOptions(dialogOptions);
    }

    const componentStyle = {
        mt: 2
    };

    if (source === null) {
        return <PageTemplate heading="Edit Trigger Credentials" subtitle=""><Loading /></PageTemplate>
    }

    return (
        <PageTemplate heading="Edit Trigger Credentials" subtitle="">
            <ReacherDialog {...dialogOptions} />
            <Paper elevation={9} sx={{ p: 2, mt: 2 }}>
                <FormControl sx={{ width: '50ch' }}>
                    <FormGroup sx={componentStyle}>
                        <TextField label="Username" variant="outlined" value={source?.eventSource} onChange={onChangeEventSource} required />
                    </FormGroup>
                    <FormGroup sx={componentStyle}>
                        <TextField label="Password" variant="outlined" value={source?.verifyString} onChange={onChangeVerifyString} required />
                    </FormGroup>
                    <FormGroup sx={componentStyle}>
                        <FormControlLabel control={<Checkbox checked={source?.active} onChange={onChangeActive} />} label="Source Active" />
                    </FormGroup>
                    <FormGroup sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                        <Button variant="contained" onClick={onSubmit} sx={{ mr: 2 }}>Save Changes</Button>
                        <Button component={NavLink} to={"/tripwire/triggercredentials"} variant="outlined">Cancel</Button>
                        <Button sx={{ ml: "auto", justifyContent: "flex-end" }} variant="contained" color="error" onClick={deleteDialog}>Delete</Button>
                    </FormGroup>
                </FormControl>
            </Paper>

        </PageTemplate>
    );

}

export default EventSourcesEdit;