import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { RenameOptions } from "./RenameOptions";

interface IDialogFormState {
    IsValid: boolean,
    Message: string
}

const RenameDialog = (props: RenameOptions) => {

    const defaultState = { IsValid: true, Message: "\u00A0" };
    const [formState, setFormState] = useState<IDialogFormState>(defaultState);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = (e.target as HTMLInputElement).value;
        props.UpdateName(value);
    }

    const validateFilename = (value: string): IDialogFormState => {

        const illegalRgx = /[~"#%&*:<>?/\\{|}]+/g;

        if (value == "") {
            return {
                IsValid: false,
                Message: 'You must enter a value'
            }
        }

        // Validate name does not already exist here
        if (props.existing) {
            if (props.existing.findIndex(f => f.name == value + props.ext) > -1) {
                return {
                    IsValid: false,
                    Message: 'You must enter a unique name'
                }
            }
        }

        // Check against allowed file name characters
        if (value.match(illegalRgx)) {
            return {
                IsValid: false,
                Message: 'Name cannot contain ~"#%&*:<>?/\\{|}'
            }
        }
        return {
            IsValid: true,
            Message: "\u00A0"
        }
    }

    const dialogCancelled = (e: any) => {
        setFormState(defaultState);
        props.onCancel(e);
    }

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") onConfirm();
    }

    const onConfirm = () => {
        const trimmed_name = props.NewName.trim();
        const newValidationState: IDialogFormState = validateFilename(trimmed_name);
        setFormState(newValidationState);
        if (newValidationState.IsValid) props.onConfirm(trimmed_name)
    }

    return (
        <Dialog open={props.isOpen || false} onClose={e => props.onClose(e)}>
            <DialogTitle>Rename to...</DialogTitle>
            <DialogContent>
                <Box sx={{ width: 500, overflow: 'auto', p: 1 }}>
                    <TextField autoFocus fullWidth onChange={onValueChange} onKeyUp={onKeyUp} value={props.NewName} label='Name' error={!formState.IsValid} helperText={formState.Message} data-testid={`dialogvalue`} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={dialogCancelled}>Cancel</Button>
                <Button variant='contained' onClick={() => onConfirm()}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RenameDialog;