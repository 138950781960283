import { createContext, ReactElement, useEffect, useState } from "react";
import useAPI from "../Hooks/useAPI";
import { IAPIOptions } from "../Models/IAPIOptions";
import { FulcrumFile, IFile } from "../Models/IFile";
import { IUser } from "../Models/IUser";
import FulcrumConnector from "./FulcrumConnector";
import { useAuth } from "react-oidc-context";
import useNotifications from "../Hooks/useNotifications";
import { IGroup } from "../Models/IGroup";


export type FulcrumContextType = {
    folder: IFile | null,
    users: IUser[] | null,
    groups: IGroup[] | null,
    loadingState: FulcrumLoading,
    apiOptions: IAPIOptions | null,
    ChangeFolder: (FolderId: string) => void,
    GetFolder: (FolderId: string, Recurse: boolean) => Promise<IFile | null>
}

export const FulcrumContext = createContext<FulcrumContextType | null>(null);

type FulcrumContextProviderProps = {
    children: ReactElement | ReactElement[];
}

export interface FulcrumLoading {
    state: "LOADING" | "DONE" | "ERROR",
    message: string
}

const FulcrumProvider = (props: FulcrumContextProviderProps) => {


    const DEFAULT_FOLDER: string = '00000000-0000-0000-0000-000000000000';

    const [folder, setFolder] = useState<IFile | null>(null);
    const [loadingState, setLoadingState] = useState<FulcrumLoading>({ state: "LOADING", message: "Fetching files ..." });
    const [users, setUsers] = useState<IUser[] | null>(null);
    const [groups, setGroups] = useState<IGroup[] | null>(null);
    const [apiOptions, setApiOptions] = useState<IAPIOptions | null>(null);

    const auth = useAuth();

    const { PushError } = useNotifications();
    const { Get } = useAPI();
    //commented out during merge and FSO refactor
    //const { events, Register } = FulcrumConnector(auth.user != null ? auth.user.access_token : "");


    const OnError = (error: Error) => {
        console.error(error);
        PushError(error.message);
    }

    //commented out during merge and FSO refactor
    // function OnFolderUpdated(updatedFolder:IFile) {
    //     console.log('CURRENT:', folder);
    //     console.log('NEXT:', updatedFolder);
    //     if(folder === null || folder.id === updatedFolder.id) {
    //         setFolder(updatedFolder);
    //     }

    // }

    useEffect((): any => {

        const initComponent = async () => {
            const options = await Get('/api/settings/apis');
            setApiOptions(options);
        };

        initComponent();

        //commented out during merge and FSO refactor
        //events(OnFolderUpdated);

    }, []);

    useEffect(() => {

        if (apiOptions) {
            const LoadInitialData = async () => {
                try {
                    await ChangeFolder(DEFAULT_FOLDER);
                    await GetUsers();
                    await GetGroups();
                    setLoadingState({ state: "DONE", message: "" });
                }
                catch (exception) {
                    const error = exception as Error;
                    OnError(error);
                    setLoadingState({ state: "ERROR", message: "An error occured while initialising Fulcrum" });
                }
            }
            LoadInitialData();
        }
    }, [apiOptions]);


    const GetUsers = async () => {
        const userResponse = await Get('/api/usermanager')
            .catch(OnError);
        setUsers(userResponse);
    }

    const GetGroups = async () => {
        const groupResponse = await Get('/api/usermanager/groups')
            .catch(OnError);
        setGroups(groupResponse);
    }

    const GetFolder = async (FolderId: string, Recurse: boolean): Promise<IFile | null> => {
        const nextFolder: IFile = await Get(`${apiOptions?.files}/api/Files/list?folderId=${FolderId}&recurse=${Recurse}`)
            .catch(OnError);
        if (nextFolder === undefined) return null;

        const IFileSort = (a: IFile, b: IFile): number => {
            return a.name.localeCompare(b.name);
        }

        const folders = nextFolder.children.filter(f => f.isFolder).sort(IFileSort);
        const files = nextFolder.children.filter(f => !f.isFolder).sort(IFileSort);

        const activeFolderChildren = [...folders, ...files];
        const activeFolder = { ...nextFolder, children: activeFolderChildren }

        return activeFolder;
    }

    const ChangeFolder = async (FolderId: string) => {
        const folder = await GetFolder(FolderId, true);
        //commented out during merge and FSO refactor        
        //Register(folder.id);
        setFolder(folder);
    }

    return (
        <FulcrumContext.Provider value={{ folder, loadingState, apiOptions, ChangeFolder, users, groups, GetFolder }}>
            {props.children}
        </FulcrumContext.Provider>
    );
}

export default FulcrumProvider;