import { ReactElement, createContext, useEffect, useState } from "react";
import useAPI from "../Hooks/useAPI";
import useNotifications from "../Hooks/useNotifications";
import { ITripwireLogResponse } from "../Models/ITripwireLogResponse";
import { ILogQuery } from "./ILogQuery";

export type LogContextType = {
    options: LogFilterOptions | null,
    logs: ITripwireLogResponse | null,
    query: ILogQuery,
    loading: boolean,
    fetching: boolean,
    updateQuery: (field:string, value: string | string[]) => void,
    clearLogs: () => void
}

export interface LogFilterOptions  {
    logLevels: string[],
    messageIds: string[]
}

export const LogContext = createContext<LogContextType | null>(null);

type LogProviderProps = {
    children: ReactElement | ReactElement[]
}

const LogProvider = (props: LogProviderProps) => {
 
    const { PushError } = useNotifications();
    const { Get, Put } = useAPI();
    const [loading, setLoading] = useState<boolean>(true);
    const [fetching, setFetching] = useState<boolean>(true);
    const [options, setOptions] = useState<LogFilterOptions | null>(null);
    const [logData, setLogData] = useState<ITripwireLogResponse | null>(null);
    const [query, setQuery] = useState<ILogQuery>(
        {messageIds: [], severity:["Information"], searchValue:""});

    useEffect(() => {

        Get("/api/TripwireLog/filters")
            .then(data => {
                setOptions(data);
                setLoading(false);
                updateQuery("messageids", data.messageIds.filter((x:string) => x !== "SYS"));
            });


        return () => {

        };
    }, []);

    const updateQuery = (field:string, value: string | string[]):void => {
        switch(field.toLowerCase())
        {
            case "messageids":
                setQuery({... query, messageIds: value as string[]});
                break;
            case "loglevels":
                setQuery({... query, severity: value as string[]});
                break;
            case "searchvalue":
                setQuery({ ... query, searchValue: value as string});
                break;
            default:
                const errorMessage = "unsupported field set in setQueryOptions";
                PushError(errorMessage)
                console.error(errorMessage);
        }
    }

    //not 100% happy with using useEffect here, but it does provide the debounce so leaving in for now. 
    useEffect(() => {
        setFetching(true);
        const searchCall = setTimeout(() => {
            fetchLogs(query);
        }, 350);

        return () => clearTimeout(searchCall);
    }, [query]);


    const fetchLogs = async (logQUery:ILogQuery) => {
        setFetching(true);
        const url = `/api/TripwireLog?searchValue=${logQUery.searchValue}&logLevels=${logQUery.severity.join(',')}&messageIds=${logQUery.messageIds.join(',')}`;
        const data = await Get(url);
        setLogData(data);
        setFetching(false);
    }

    const  clearLogs = () => {
        setFetching(true);
        Put("/api/TripwireLog/clear").then(res => fetchLogs(query));

    }

    return (
        <LogContext.Provider value={{options, logs:logData, query, loading, fetching, updateQuery, clearLogs}}>
            {props.children}
        </LogContext.Provider>
    );
}

export default LogProvider;