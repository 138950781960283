import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import useAPI from '../../Hooks/useAPI';
import useNotifications from '../../Hooks/useNotifications';
import useSettings from '../../Hooks/useSettings';

import { IAPIOptions } from '../../Models/IAPIOptions';
import { FulcrumFile, IFile } from "../../Models/IFile";
import { IUser } from '../../Models/IUser';
import FileDownload from "./FileDownload";
import { IPermission } from '../../Models/IPermission';
import ShareDialog from './ShareDialog';
import { ShareOptions } from './ShareOptions';
import MoveDialog from './MoveDialog';
import { MoveOptions } from './MoveOptions';

import AdbIcon from '@mui/icons-material/Adb';
import WordIcon from '@mui/icons-material/DescriptionOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import ExcelIcon from '@mui/icons-material/InsertChartOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TextIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CsvIcon from '@mui/icons-material/InsertPageBreakOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShareIcon from '@mui/icons-material/Share';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import DeleteDialog from './DeleteDialog';
import { DeleteOptions } from './DeleteOptions';
import { FulcrumContext, FulcrumContextType } from '../FulcrumContext';
import { RenameOptions } from './RenameOptions';
import RenameDialog from './RenameDialog';
import { useAuth } from 'react-oidc-context';
import { IGroup } from '../../Models/IGroup';

interface FileItemProps {
    Item: IFile;
    Siblings: IFile[];
    OnOpenItem: (item: IFile) => void;
    OnImageClicked: (fileId: string, canDownload: boolean) => Promise<void>;
    Users: IUser[];
    Groups: IGroup[];
    RefreshItems: () => void;
}

const FileItem = ({ Item, OnOpenItem, OnImageClicked, Users, Groups, RefreshItems, Siblings }: FileItemProps) => {
    const { apiOptions } = useContext(FulcrumContext) as FulcrumContextType;
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [fileMenuAnchor, setFileMenuAnchor] = useState<HTMLElement | null>(null);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [userListOpen, setShareDialogOpen] = useState<boolean>(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>(Item.name);
    const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<IPermission[]>(Item.permissions);
    const [groupPermissions, setGroupPermissions] = useState<IPermission[]>(Item.groupPermissions);

    const { Get, Put, Post, Delete } = useAPI();

    const { PushSuccess } = useNotifications();
    const auth = useAuth();
    const userId = auth.user?.profile.sub ? auth.user?.profile.sub : "";
    const currentFile = new FulcrumFile(Item);

    useEffect(() => {
        if (!apiOptions) return;

        resetPermissions();
    }, []);

    const resetPermissions = () => {
        let allPerms = Users.map(u => {
            return {
                id: u.id,
                name: u.userName,
                full: false,
                open: false,
                edit: false,
                download: false,
                share: false,
            }
        })
        if (Item.permissions) {
            for (var i = 0; i < allPerms.length; i++) {
                let perm = Item.permissions.find(p => p.id == allPerms[i].id);
                if (perm) {
                    allPerms[i] = {
                        ...allPerms[i],
                        full: perm.full,
                        open: perm.open,
                        edit: perm.edit,
                        download: perm.download,
                        share: perm.share,
                    }
                }
            }
        }
        setPermissions(allPerms);

        let allGroupPerms = Groups.map(g => {
            return {
                id: g.id,
                name: g.name,
                full: false,
                open: false,
                edit: false,
                download: false,
                share: false,
            }
        })
        if (Item.groupPermissions) {
            for (var j = 0; j < allGroupPerms.length; j++) {
                let perm = Item.groupPermissions.find(p => p.id == allGroupPerms[j].id);
                if (perm) {
                    allGroupPerms[j] = {
                        ...allGroupPerms[j],
                        full: perm.full,
                        open: perm.open,
                        edit: perm.edit,
                        download: perm.download,
                        share: perm.share,
                    }
                }
            }
        }
        setGroupPermissions(allGroupPerms);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
        if (fileMenuAnchor === null) setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const onMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        const target = (e.target as HTMLElement);
        setFileMenuAnchor(target);
    }

    const onMenuClose = (action: () => void) => {
        setFileMenuAnchor(null);
        if (action && typeof (action) == "function") action();
    }

    const onDeleteConfirmed = () => {
        Delete(`${apiOptions?.files}/api/Files/delete?fileid=${Item.id}`).then((res) => {
            if (res && res.success) {
                PushSuccess(`Deleted file ${Item.name}`);
                RefreshItems();
            }
        });
        onMenuClose(() => { setDeleteDialogOpen(false) });
    }

    const deleteOptions: DeleteOptions = {
        isOpen: deleteDialogOpen,
        onClose: () => onMenuClose(() => { setDeleteDialogOpen(false) }),
        onCancel: () => onMenuClose(() => { setDeleteDialogOpen(false) }),
        onConfirm: () => onDeleteConfirmed(),
        filename: Item.name
    };

    const onShareConfirmed = () => {
        const prms = {
            fileSystemObjectId: Item.id,
            permissions: permissions.filter(p => p.id !== auth.user?.profile.sub),
            groupPermissions: groupPermissions
        }
        Post(`${apiOptions?.files}/api/Files/share?fileid=${Item.id}`, prms).then((res) => {
            if (res && res.success) {
                PushSuccess(`Share updated`);
            }
        });
        onMenuClose(() => { setShareDialogOpen(false) });
    }

    const shareOptions: ShareOptions = {
        isOpen: userListOpen,
        onClose: () => onMenuClose(() => { resetPermissions(); setShareDialogOpen(false); }),
        onCancel: () => onMenuClose(() => { resetPermissions(); setShareDialogOpen(false); }),
        onConfirm: () => onShareConfirmed(),
        Item: Item,
        Permissions: permissions.filter(p => p.id !== auth.user?.profile.sub),
        GroupPermissions: groupPermissions,
        UpdatePermissions: setPermissions,
        UpdateGroupPermissions: setGroupPermissions
    };

    const onMoveConfirmed = (folderId: string) => {
        const prms = {
            fileSystemObjectId: Item.id,
            targetFolderId: folderId
        }
        Put(`${apiOptions?.files}/api/Files/move`, prms).then((res) => {
            if (res && res.success) {
                PushSuccess(`Moved file ${Item.name}`);
                RefreshItems();
            }
        });

        onMenuClose(() => { setMoveDialogOpen(false) });
    };

    const moveOptions: MoveOptions = {
        isOpen: moveDialogOpen,
        subjectItem: Item,
        onClose: () => onMenuClose(() => { setMoveDialogOpen(false) }),
        onCancel: () => onMenuClose(() => { setMoveDialogOpen(false) }),
        onConfirm: (target) => onMoveConfirmed(target),
    };

    const onRenameConfirmed = (newname: string) => {
        const prms = {
            fileSystemObjectId: Item.id,
            newname: newname
        }
        Put(`${apiOptions?.files}/api/Files/rename`, prms).then((res) => {
            if (res && res.success) {
                PushSuccess(`Renamed ${Item.name}`);
                RefreshItems();
            }
        });

        onMenuClose(() => { setRenameDialogOpen(false) });
    };

    const renameOptions: RenameOptions = {
        isOpen: renameDialogOpen,
        onClose: () => onMenuClose(() => { setRenameDialogOpen(false) }),
        onCancel: () => onMenuClose(() => { setRenameDialogOpen(false) }),
        onConfirm: (target) => onRenameConfirmed(target),
        NewName: filename,
        UpdateName: setFilename,
        existing: Siblings,
        ext: Item.extension
    };

    const openRename = () => {
        setFilename(Item.name.replace(/\.[^/.]+$/, ""));
        setRenameDialogOpen(true);
    };

    const downloadItem = async (fileid: string) => {
        const response = await Get(`${apiOptions?.files}/api/Files/Token?FileId=${fileid}`);

        if (response.success) {
            const downloadUrl = `${apiOptions?.files}/api/Files/download?token=${response.token}`;
            window.open(downloadUrl, "_blank", "noopener,noreferrer");
        }
    }

    const GetEditor = (editor: string, fileid: string) => {

        switch (editor) {
            case 'cell':
            case 'word':
            case 'slide':
                if (!currentFile?.hasPermission('edit', userId))
                    return <></>
                return <IconButton size="small" component={NavLink} to={`/fulcrum/docs?fileid=${fileid}`} data-testid={`editor_${fileid}`}><EditIcon fontSize="small" /></IconButton>
            case 'image':
                if (!currentFile?.hasPermission('open', userId))
                    return <></>
                return <IconButton size="small" onClick={() => OnImageClicked(fileid, currentFile?.hasPermission('download', userId))} data-testid={`editor_${fileid}`}><ImageIcon fontSize="small" /></IconButton>;
            default:
                return <></>
        }
    }

    const GetIcon = (editor: string, extension: string, isFolder: boolean) => {

        if (isFolder) {
            return <FolderIcon fontSize="small" />;
        }

        switch (extension) {
            case '.apk':
                return <AdbIcon fontSize="small" />
            case '.csv':
                return <CsvIcon fontSize="small" />
            case '.txt':
                return <TextIcon fontSize="small" />
            case '.pdf':
                return <PictureAsPdfIcon fontSize="small" />
        }

        switch (editor) {
            case 'cell':
                return <ExcelIcon fontSize="small" />
            case 'word':
                return <WordIcon fontSize="small" />
            case 'slide':
                return <SlideshowIcon fontSize="small" />
            case 'image':
                return <ImageIcon fontSize="small" />
            default:
                return <InsertDriveFileIcon fontSize="small" />
        }
    }

    const cellSx = {
        p: 1
    };

    return (
        <>
            <TableRow onMouseMove={handleMouseMove} sx={{ border: 'none', p: 0, '&:hover': { bgcolor: grey[900] } }} data-testid={`row_${Item.id}`} onContextMenu={(e) => onMenuOpen(e)}>
                <TableCell sx={cellSx}>
                    <Box onClick={() => OnOpenItem(Item)} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '&:hover': {
                            cursor: Item.editor || Item.isFolder ? 'pointer' : 'cursor',
                        }
                    }} data-testid={`ff_${Item.id}`}>
                        <IconButton size="small" >
                            {GetIcon(Item.editor, Item.extension, Item.isFolder)}
                        </IconButton>
                        <Typography sx={{ ml: 1 }}>{Item.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell sx={cellSx} align="right" >{Item.authorName}</TableCell>
                <TableCell sx={cellSx} align="right">{!Item.isFolder && Math.round((Item.size) / 1000).toFixed(0) + "kB"}</TableCell>
                <TableCell sx={cellSx} align="right">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {GetEditor(Item.editor, Item.id)}

                        {(currentFile?.hasPermission('download', userId) && !Item.isFolder) && <FileDownload fileid={Item.id} disabled={Item.isFolder} />}
                        {currentFile?.hasPermission('share', userId) && !Item.isUserShareFolder && <IconButton size="small" onClick={() => setShareDialogOpen(true)} data-testid={`share_${Item.id}`}>
                            <ShareIcon fontSize="small" />
                        </IconButton>}
                        <IconButton size="small" sx={{ ml: 2 }} onClick={onMenuOpen} data-testid={`options_${Item.id}`}><MoreVertIcon fontSize="small" /></IconButton>
                    </Box>
                </TableCell>
            </TableRow>

            <Menu
                anchorEl={fileMenuAnchor}
                anchorReference="anchorPosition"
                anchorPosition={{ top: mousePosition.y, left: mousePosition.x }}
                onClose={onMenuClose}
                open={fileMenuAnchor !== null}>
                {currentFile?.hasPermission('open', userId) && <MenuItem onClick={() => OnOpenItem(Item)} data-testid={`openItem`}>Open</MenuItem>}
                {(currentFile?.hasPermission('download', userId) && !Item.isFolder) && <MenuItem onClick={() => downloadItem(Item.id)} data-testid={`downloadItem`}>Download</MenuItem>}
                {(currentFile?.hasPermission('move', userId) && !Item.isUserShareFolder) && <MenuItem onClick={() => setMoveDialogOpen(true)} data-testid={`openMoveDialog`}>Move</MenuItem>}
                {(currentFile?.hasPermission('rename', userId) && !Item.isUserShareFolder) && <MenuItem onClick={() => openRename()} data-testid={`openRenameDialog`}>Rename</MenuItem>}
                {(currentFile?.hasPermission('share', userId) && !Item.isUserShareFolder) && <MenuItem onClick={() => setShareDialogOpen(true)} data-testid={`openShareDialog`}>Share</MenuItem>}
                {(currentFile?.hasPermission('delete', userId) && !Item.isUserShareFolder) && <MenuItem onClick={() => setDeleteDialogOpen(true)} data-testid={`openDeleteDialog`}>Delete</MenuItem>}
            </Menu>

            <ShareDialog {...shareOptions} />
            <MoveDialog {...moveOptions} />
            <DeleteDialog {...deleteOptions} />
            <RenameDialog {...renameOptions} />
        </>
    )
}

export default FileItem;