import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useRef, useState } from "react";
import { CommandButtonProps } from "./CommandButtonProps"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Dictionary } from "lodash";

const TriggerCommandButton = ({ cmd, enabled, onSend, icon }: CommandButtonProps) => {

    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleMenuItemClicked = (event: any, nextState: boolean) => {
        const params: Dictionary<string> = {
            "state": String(nextState)
        };

        onSend(cmd.id, params);
        handleClose(event);
    }

    const handleClose = (event: any) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    }

    return (
        <>
            <ButtonGroup variant="contained" sx={{ width: '100%' }}>
                <Button data-testid={"command" + cmd.displayName + "Button"} variant="text" size="small" sx={{ width: '100%', color: 'white', border: '1px solid blue', borderRight: 'none' }} >{icon}{cmd.displayName}</Button>
                <Button
                    ref={anchorRef}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => setOpen(!open)}
                    disabled={!enabled}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={(handleClose)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem
                                        key="enable"
                                        onClick={(event) => handleMenuItemClicked(event, true)}
                                    >
                                        Enable
                                    </MenuItem>
                                    <MenuItem
                                        key="disable"
                                        onClick={(event) => handleMenuItemClicked(event, false)}
                                    >
                                        Disable
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default TriggerCommandButton;