import { Close, OpenInNew, RotateLeft, RotateRight, SwapHoriz, SwapVert, Undo, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, Modal, Paper } from "@mui/material";
import { grey } from '@mui/material/colors';
import * as React from 'react';
import PanViewer from '../../../Components/PanViewer';
import { ImageViewerProps } from "./ImageViewerProps";

const boxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'hidden',
    borderRadius:0,
    border:'0',
    height:'90vh',
    width:'90vw'
};

const buttonStyle = {
    textAlign: 'center',
    cursor: 'pointer',
    height: 40,
    width: 40,
    borderBottom: ' 1px solid #ccc',
    color: '#4C68C1'
};

const disabledStyle = {
    ...buttonStyle, color: grey[800], backgroundColor: grey[600]
};

const ImageViewer = ({ imageUrl, isOpen, handleClose, refreshURL, canDownload }: ImageViewerProps) => {
    const [dx, setDx] = React.useState(0);
    const [dy, setDy] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);
    const [rotation, setRotation] = React.useState(0);
    const [mirrorX, setMirrorX] = React.useState(false);
    const [mirrorY, setMirrorY] = React.useState(false);


    const resetAll = () => {
        setDx(0);
        setDy(0);
        setZoom(1);
        setRotation(0);
        setMirrorX(false);
        setMirrorY(false);
    };
    const zoomIn = () => {
        var newZoom = zoom * 1.2;
        newZoom = newZoom > 10 ? 10 : newZoom;
        setZoom(newZoom);
    };
    const zoomOut = () => {
        var newZoom = zoom * 0.8;
        newZoom = newZoom < 0.1 ? 0.1 : newZoom;
        setZoom(newZoom);
    };
    const rotateLeft = () => {
        if (rotation === -3) {
            setRotation(0);
        } else {
            setRotation(rotation - 1);
        }
    };
    const rotateRight = () => {
        if (rotation === 0) {
            setRotation(-3);
        } else {
            setRotation(rotation + 1);
        }
    };
    const mirrorHoriz = () => {
        setMirrorX(!mirrorX);
    };
    const mirrorVerti = () => {
        setMirrorY(!mirrorY);
    };
    const onPan = (dx: number, dy: number) => {
        setDx(dx);
        setDy(dy);
    };
    
    const openInNew = async () => {
        if(refreshURL)
        {
            const newUrl = await refreshURL(imageUrl);
            window.open(newUrl);
        }   
        else
        {
            window.open(imageUrl);
        } 
    }

    const onClose = (e: any) => {
        resetAll();
        handleClose(e);
    };



    return (
        <Modal open={isOpen} onClose={onClose} >
        

            <Box component={Paper} sx={boxStyle}>
            <Box sx={{
                    position:'absolute',
                    background: '#fff',
                    top:20,
                    right:20,
                    zIndex: 2,
                    display:'flex',
                    flexDirection:'column',
                    userSelect:'none'
                }}>
                    
                    <Box onClick={zoomIn} sx={buttonStyle}>
                        <ZoomIn sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={zoomOut} sx={buttonStyle}>
                        <ZoomOut sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={rotateLeft} sx={buttonStyle}>
                        <RotateLeft sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={rotateRight} sx={buttonStyle}>
                        <RotateRight sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={resetAll} sx={buttonStyle}>
                        <Undo sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={mirrorHoriz} sx={buttonStyle}>
                        <SwapHoriz sx={{ m: 1 }} />
                    </Box>
                    <Box onClick={mirrorVerti} sx={buttonStyle}>
                        <SwapVert sx={{ m: 1 }} />
                    </Box>
                    {canDownload && <Box onClick={openInNew} sx={buttonStyle}>
                        <OpenInNew sx={{ m: 1 }} />
                    </Box>}
                    {!canDownload && <Box sx={disabledStyle}>
                        <OpenInNew sx={{ m: 1 }} />
                    </Box>}
                    <Box onClick={onClose} sx={buttonStyle}>
                        <Close sx={{ m: 1 }} />
                    </Box>
                    
                </Box>
                <PanViewer
                    style={{
                        zIndex: 1
                    }}
                    zoom={zoom}
                    setZoom={setZoom}
                    pandx={dx}
                    pandy={dy}
                    onPan={onPan}
                    rotation={rotation}
                    key={imageUrl}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent:'center', justifyContent:'center'}}>
                        <img onContextMenu={(e) => { e.preventDefault(); } } loading="lazy"
                            style={{
                                width: 'auto', height:'95vh'
                                ,transform: `rotate(${rotation * 90}deg) scaleX(${mirrorX ? -1 : 1}) scaleY(${mirrorY ? -1 : 1})`
                            }}
                            src={imageUrl}
                        />
                    </div>
                </PanViewer>
            </Box>
   
        </Modal>
    );
}

export default ImageViewer;
