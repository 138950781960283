import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { LicenseInfo } from '@mui/x-license-pro';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const callbackFN = (_user: User | void): void => {
       window.history.replaceState(
          {},
        document.title,
          window.location.pathname
      )
   }


fetch("/config.json")
.then(response => response.json())
.then(config => {

  LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_PRO_KEY));

  const oidcConfig:AuthProviderProps =  {
    authority: config.REACT_APP_OIDC_AUTHORITY as string,
    client_id: config.REACT_APP_OIDC_CLIENT_ID  as string,
    redirect_uri: config.REACT_APP_OIDC_SIGNIN_CALLBACK  as string,
    post_logout_redirect_uri: config.REACT_APP_OIDC_SIGNIN_CALLBACK as string,
    scope: "openid profile email roles",
    onSigninCallback: callbackFN,
    userStore: new WebStorageStateStore({store: window.localStorage})
  }
  
  root.render(

      <AuthProvider {...oidcConfig }>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>

  );
});


// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//   <BrowserRouter basename={baseUrl}>
//     <App />
//   </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
