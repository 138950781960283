import { Box, Button, Drawer, Grid, Paper, TextField, Typography } from "@mui/material";
import { EditDrawerOptions } from "./EditDrawerOptions";

const EditDrawer = (props: EditDrawerOptions) => {

    const onKeyUp = (e: any) => {
        switch (e.key) {
            case "Enter":
                onConfirm(e);
                break;
            case "Escape":
                props.onCancel(e);
                break;
        }
    }

    const onChange = (e: any) => {
        let modified = {
            ...props, device: { ...props.device, friendlyDeviceName: e.target.value }
        };
        props.onChange(modified);
    };

    const onConfirm = (e: any) => {
        onChange(e);
        props.onConfirm(e, props);
    }

    const onCancel = (e: any) => {
        props.onCancel(e);
    }

    return <Drawer
        title="Edit"
        anchor="right"
        open={props.isOpen}
        onClose={e => props.onClose(e)}
        PaperProps={{ sx: { width: 360 } }} >
        <Paper >
            <Box sx={{ height: 1440 }}>
                <Box>
                    <Box sx={{ p: 1 }}>
                        <Typography sx={{ p: 1 }}>
                            Edit Device
                        </Typography>
                        <Typography component="div" variant="subtitle2">
                            Device ID: {props.device.androidDeviceId}
                        </Typography>
                        <Typography component="div" variant="subtitle2">
                            Device Name: {props.device.androidDeviceName}
                        </Typography>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <TextField label="Display name" sx={{ width: '100%' }}
                            onKeyUp={onKeyUp}
                            placeholder="Display name" size="small"
                            value={props.device.friendlyDeviceName}
                            inputProps={{ maxLength: 30 }}
                            onChange={(e) => onChange(e)}
                            variant="outlined" autoFocus />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <Button variant="contained" onClick={e => onConfirm(e)} sx={{ ml: 1 }}>Confirm</Button>
                        <Button variant="contained" onClick={e => onCancel(e)} sx={{ ml: 1 }}>Cancel</Button>
                    </Box>
                </Box>
            </Box>
        </Paper>
    </Drawer>
}

export default EditDrawer;