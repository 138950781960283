import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Divider, Fab, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreateFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';

import WordIcon from '@mui/icons-material/DescriptionOutlined';
import ExcelIcon from '@mui/icons-material/InsertChartOutlined';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TextIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CsvIcon from '@mui/icons-material/InsertPageBreakOutlined';

import FileUpload from "./FileUpload";
import CreateDialog, { CreateDialogOptions } from "./CreateDialog";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { IApiResult } from "../../Models/IApiResult";
import { IFile } from "../../Models/IFile";
import { FulcrumContext, FulcrumContextType } from "../FulcrumContext";
import useRoles from "../../Hooks/useRoles";

interface CreateProps {
    OnFileUploadComplete: () => void;
    CurrentFolderId: string;
    CurrentFiles: IFile[];
}

const CreateMenu = ({ OnFileUploadComplete, CurrentFolderId, CurrentFiles }: CreateProps) => {

    let initOptions = {
        title: '',
        ext: '',
        isOpen: false,
        existing: CurrentFiles,
        onClose: () => { },
        onCancel: () => { },
        onConfirm: () => { }
    };

    const canCreate = useRoles(["admin","fulcrum-create","fulcrum-admin"]);
    const { Post } = useAPI();
    const { PushSuccess, PushError } = useNotifications();
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const openCreateMenu = Boolean(menuAnchor);
    const [upload, setUpload] = useState<boolean>(false);
    const [createOptions, setCreateOptions] = useState<CreateDialogOptions>(initOptions);

    const {
        apiOptions
    } = useContext(FulcrumContext) as FulcrumContextType;


    const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    }

    const onMenuClose = () => {
        setMenuAnchor(null);
    }

    const onUploadStart = () => {
        onMenuClose();
        setUpload(true);
    }

    const onUploadComplete = () => {
        onMenuClose();
        setUpload(false);
        OnFileUploadComplete();
    }

    const createFolder = (name: string) => {
        setCreateOptions({ ...createOptions, isOpen: false });
        Post(`${apiOptions?.files}/api/Files/folder?parent=${CurrentFolderId}&name=${name}`, {})
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => {
                OnFileUploadComplete();
            });
    }

    const onNewFolder = () => {
        let createFolderOptions: CreateDialogOptions = {
            title: 'Create Folder',
            ext: "",
            isOpen: true,
            existing: CurrentFiles,
            onClose: () => setCreateOptions({ ...createOptions, isOpen: false }),
            onCancel: () => setCreateOptions({ ...createOptions, isOpen: false }),
            onConfirm: (name) => createFolder(name)
        };
        setCreateOptions(createFolderOptions);
        onMenuClose();
    }

    const createFile = (filename: string) => {
        setCreateOptions({ ...createOptions, isOpen: false });
        Post(`${apiOptions?.files}/api/Files/create?filename=${filename}&folderId=${CurrentFolderId}`, {})
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => {
                OnFileUploadComplete();
            });
    }

    const onNewFile = (ext: string, title:string) => {
        let createFileOptions: CreateDialogOptions = {
            title,
            ext: ext,
            isOpen: true,
            existing: CurrentFiles,
            onClose: () => setCreateOptions({ ...createOptions, isOpen: false }),
            onCancel: () => setCreateOptions({ ...createOptions, isOpen: false }),
            onConfirm: (name) => createFile(name)
        };
        setCreateOptions(createFileOptions);
        onMenuClose();
    }

    //check permissions, if no admin, fulcrum-admin or fulcurm-create do not return the component. 
    if (!canCreate) return <></>

    return (
        <>
            <FileUpload OnFileUploadComplete={onUploadComplete} CurrentFolderId={CurrentFolderId} OpenMenu={e => onOpenMenu(e)} Upload={upload} />
            <Menu
                open={openCreateMenu}
                anchorEl={menuAnchor}
                onClose={onMenuClose}
                sx={{
                    mt: 1
                }}
            >
                <MenuItem onClick={() => onUploadStart()}  data-testid={`menuUpload`}>
                    <ListItemIcon>
                        <UploadFileIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="Upload Files" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => onNewFolder()} data-testid={`menuNewFolder`}>
                    <ListItemIcon>
                        <CreateFolderIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New Folder"  />
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => onNewFile('.docx', 'Create new word document')} data-testid={`menuNewWord`}>
                    <ListItemIcon>
                        <WordIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New Word"  />
                </MenuItem>
                <MenuItem onClick={() => onNewFile('.xlsx', 'Create new excel file')} data-testid={`menuNewExcel`}>
                    <ListItemIcon>
                        <ExcelIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New Excel"  />
                </MenuItem>
                <MenuItem onClick={() => onNewFile('.pptx', 'Create new powerpoint presentation')} data-testid={`menuNewPPT`}>
                    <ListItemIcon>
                        <SlideshowIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New Powerpoint"  />
                </MenuItem>
                <MenuItem onClick={() => onNewFile('.txt', 'Create new text file')} data-testid={`menuNewTxt`}>
                    <ListItemIcon>
                        <TextIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New Text"  />
                </MenuItem>
                <MenuItem onClick={() => onNewFile('.csv', 'Create new CSV file')} data-testid={`menuNewCsv`}>
                    <ListItemIcon>
                        <CsvIcon  fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  primary="New CSV"  />
                </MenuItem>
            </Menu>

            {createOptions && <CreateDialog {...createOptions} />}
        </>

    );
}

export default CreateMenu;