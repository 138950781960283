import * as SignalR from "@microsoft/signalr";
import UserNotification from "../Models/UserNotification";


class UserNotificationConnector {
    private connection: signalR.HubConnection;
    public events: 
        (
            OnNotificationReceived:(notifcation:UserNotification)=>void,
            
        ) => void;
    static instance: UserNotificationConnector;

    constructor(accessToken:string) {
        this.connection = new SignalR.HubConnectionBuilder()
            .withUrl("/hubs/notifications",{
                accessTokenFactory: () => { return accessToken },
                transport: SignalR.HttpTransportType.WebSockets,
                logger: SignalR.LogLevel.None
            })      
            .withAutomaticReconnect()
            .build();

        this.connection.start().catch(err => console.error(err));
        this.events = (OnNotificationReceived) => {
            this.connection.on('OnNotificationSent', (notification:UserNotification) => OnNotificationReceived(notification));
        }
    }

    public static getInstance(accessToken:string) {
        if(!UserNotificationConnector.instance) 
        UserNotificationConnector.instance = new UserNotificationConnector(accessToken);
        return UserNotificationConnector.instance;
    }
}

export default UserNotificationConnector.getInstance;