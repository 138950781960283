import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { IFile } from "../../Models/IFile";
import { SettingsOverscanOutlined } from "@mui/icons-material";
import { copyResponse } from "workbox-core";

export interface CreateDialogOptions {
    title: string,
    ext: string,
    isOpen: boolean,
    existing: IFile[] | null,
    onClose: (event: any) => void,
    onCancel: (event: any) => void,
    onConfirm: (event: string) => void
}

interface ICreateDialogFormState {
    IsValid: boolean,
    Message: string
}

const CreateDialog = (props: CreateDialogOptions) => {

    useEffect(() => {
        setName("");
    }, [props.isOpen]);

    const [name, setName] = useState<string>("");

    const defaultState = {IsValid:true, Message:"\u00A0"};
    const [formState, setFormState] = useState<ICreateDialogFormState>(defaultState);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = (e.target as HTMLInputElement).value;
        setName(value);
    }

    const validateFilename = (value: string) : ICreateDialogFormState => {

        const illegalRgx = /[~"#%&*:<>?/\\{|}]+/g;
        
        if (value == "") {
            return {
                IsValid: false,
                Message: 'You must enter a value'
            }
        }

        // Validate name does not already exist here
        if (props.existing) {
            if (props.existing.findIndex(f => f.name == value) > -1) {
                return {
                    IsValid: false,
                    Message: 'You must enter a unique name'
                }
            }
        }

        // Check against allowed file name characters
        if (value.match(illegalRgx)) {
            return {
                IsValid: false,
                Message: 'Name cannot contain ~"#%&*:<>?/\\{|}'
            }
        }
        return {
            IsValid: true,
            Message: "\u00A0"
        }
    }

    const dialogCancelled = (e:any) => {
        setFormState(defaultState);
        props.onCancel(e);
    }

    const onKeyUp = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.code === "Enter") onConfirm();
    }

    const onConfirm = () => {
        const trimmed_name = name.trim();
        const newValidationState : ICreateDialogFormState = validateFilename(trimmed_name + props.ext);
        setFormState(newValidationState);
        if (newValidationState.IsValid) props.onConfirm(trimmed_name + props.ext)
    }

    return <Dialog open={props.isOpen || false} onClose={e => props.onClose(e)} maxWidth='sm' fullWidth disableRestoreFocus >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <Box sx={{py:1}}>
                <TextField autoFocus fullWidth onChange={onValueChange} onKeyUp={onKeyUp}  value={name} label='Name' error={!formState.IsValid} helperText={formState.Message} data-testid={`dialogvalue`} />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' onClick={dialogCancelled} data-testid={`cancel`}>Cancel</Button>
            <Button variant='contained' onClick={e => onConfirm()} data-testid={`confirm`}>Confirm</Button>
        </DialogActions>
    </Dialog>
}

export default CreateDialog;