import { IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import useAPI from "../../Hooks/useAPI";
import { useContext } from "react";
import { FulcrumContext, FulcrumContextType } from "../FulcrumContext";

interface IFileDownloadProps {
    fileid: string,
    disabled: boolean
}

const FileDownload = ({ fileid, disabled }:IFileDownloadProps) => {

    const { Get } = useAPI();
    const { apiOptions } = useContext(FulcrumContext) as FulcrumContextType;

    const requestToken = async () => {
        const response = await Get(`${apiOptions?.files}/api/Files/Token?FileId=${fileid}`);

        if(response.success) {
            const downloadUrl = `${apiOptions?.files}/api/Files/download?token=${response.token}`;
            window.open(downloadUrl, "_blank", "noopener,noreferrer");
        }

    }

    return <IconButton disabled={disabled} size="small" onClick={requestToken} data-testid={`startDownload`}><DownloadIcon fontSize="small" /></IconButton>

}

export default FileDownload;