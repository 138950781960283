import * as SignalR from "@microsoft/signalr";
import { ReacherDevice } from "./Models/ReacherDevice";
import { ReacherDeviceCommand } from "./Models/ReacherDeviceCommand";

class ReacherConnector {
    private connection: signalR.HubConnection;
    public events: 
        (
            OnDeviceUpdated:(deviceUpdate:ReacherDevice)=>void,
            OnDeviceCommandUpdated:(deviceCommandUpdate: ReacherDeviceCommand)=>void,
            OnDeviceStatusUpdated: (statusUpdates: ReacherDevice) => void,
            OnAlertCreated: (alert: ReacherDeviceCommand) => void
        ) => void;
    static instance: ReacherConnector;

    constructor(accessToken:string) {
        this.connection = new SignalR.HubConnectionBuilder()
            .withUrl("/hubs/reacher",{
                accessTokenFactory: () => { return accessToken },
                transport: SignalR.HttpTransportType.WebSockets,
                logger: SignalR.LogLevel.None
            })      
            .withAutomaticReconnect()
            .build();

        this.connection.start().catch(err => console.error(err));
        this.events = (OnDeviceUpdated, OnDeviceCommandUpdated,OnDeviceStatusUpdated, OnAlertCreated) => {
            this.connection.on('DeviceUpdate', (deviceUpdate: ReacherDevice) => OnDeviceUpdated(deviceUpdate));
            this.connection.on('DeviceCommandUpdate', (deviceCommandUpdate: ReacherDeviceCommand) => OnDeviceCommandUpdated(deviceCommandUpdate));
            this.connection.on('DeviceStatusUpdate', (statusUpdates: ReacherDevice) => OnDeviceStatusUpdated(statusUpdates));
            this.connection.on('AlertCreated', (alert: ReacherDeviceCommand) => OnAlertCreated(alert));
        }
    }

    public static getInstance(accessToken:string) {
        if(!ReacherConnector.instance) 
        ReacherConnector.instance = new ReacherConnector(accessToken);
        return ReacherConnector.instance;
    }
}

export default ReacherConnector.getInstance;