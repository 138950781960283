import { hasAuthParams, useAuth } from 'react-oidc-context';
import './custom.scss';
import SecureApp from './components/Secure/SecureApp';
import AnonymousApp from './components/Anonymous/AnonymousApp';
import AppTheme from './AppTheme';
import React from 'react';

const App = () => {

  const auth = useAuth();

  const applicationElement = auth.isAuthenticated ? <SecureApp /> : <AnonymousApp />;

  return <AppTheme>{applicationElement}</AppTheme>;
}

export default App;
