import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', padding: 4 }} >
            <CircularProgress color="secondary" />
            <Typography variant="subtitle1" sx={{marginTop:4}}>Loading</Typography>
        </Box>
    );

}

export default Loading;