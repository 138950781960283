import { Box, Paper, Typography } from "@mui/material";
import { ReactElement } from "react";
import useViewPortDimensions from "../Hooks/useViewPort";

interface IPageProps {
    heading: string,
    subtitle: string,
    children: ReactElement | ReactElement[]
}

const PageTemplate = (props: IPageProps) => {

    const { viewPortHeight } = useViewPortDimensions();

    return (
        <Box component={Paper} elevation={3} sx={{ padding: 3, height: viewPortHeight }}>
            <Paper elevation={6} sx={{ padding: 3, mb: 3 }}>
                <Typography variant="h5">
                    {props.heading}
                </Typography>
                <Typography variant="subtitle2">
                    {props.subtitle}
                </Typography>
            </Paper>

            {props.children}
        </Box>
    );
}



export default PageTemplate;