import { Box, Card, IconButton, Chip } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReacherDevice } from "../Models/ReacherDevice";
import { green, red } from "@mui/material/colors";
import { ReacherConstants } from "../../../../constants/ReacherConstants";

const KnownDevice = (props: { dev: ReacherDevice, setTarget: (target: null | HTMLElement, device: ReacherDevice) => void }) => {

    const colour = props.dev.alive ? green[500] : red[500];
    let deviceName = props.dev.androidDeviceName;
    deviceName += props.dev.friendlyDeviceName ? ` [${props.dev.friendlyDeviceName}]` : '';

    return <Card sx={{ display: 'flex', my: 1, p: 0 }} elevation={6}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center', pl: 0, pr: 1, py: 0 }}>
            <div style={{ height: '100%', width: '8px', backgroundColor: colour, content: ' ' }}></div>
            <p style={{ flexGrow: 1, padding: 0, margin: 0, marginLeft: 10 }}>{deviceName}</p>
            {props.dev.registrationState == ReacherConstants.DeviceRegistrationStates.Approved &&
                <Chip label="Approved" size="small" color="success" />
            }
            {props.dev.registrationState == ReacherConstants.DeviceRegistrationStates.Rejected &&
                <Chip label="Rejected" size="small" color="error" />
            }
            <IconButton onClick={(e) => props.setTarget(e.currentTarget, props.dev)}>
                <MoreVertIcon />
            </IconButton>
        </Box>
    </Card>
}

export default KnownDevice;