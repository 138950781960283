import { useContext, useEffect, useState } from "react";
import { MainMenuContext, MainMenuContextType } from "../Context/MainMenuContext";
import useWindowDimensions from "./useWindowDimension";

export default function useViewPortDimensions() {
    const { height, width } = useWindowDimensions();
    const { isOpen } = useContext(MainMenuContext) as MainMenuContextType;
    const offset = 84;
    const templateOffset = offset + 150;
    const border = 16;
    const menuWidth = 240;

    const [viewPortHeight, setViewPortHeight] = useState(height - offset);
    const [templatePageHeight, setTemplatePageHeight] = useState(height - templateOffset);
    const [viewportWidth, setViewPortWidth] = useState(width - border);

    useEffect(() => {
        setViewPortHeight(height - offset);
        setTemplatePageHeight(height - templateOffset);

        // Resize the view port width if the menu is opened
        if (isOpen) {
            setViewPortWidth(width - menuWidth - border);
        } else {
            setViewPortWidth(width - border);
        }

    }, [height, width, isOpen, templateOffset]);

    return { viewPortHeight, templatePageHeight, viewportWidth };
}