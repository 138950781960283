import { Badge, Box, Divider, IconButton, ListItemText, Menu, MenuItem, MenuList, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { UserNotificationContext, UserNotificationContextType } from "../Context/UserNotificationContext";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const NotificationMenu = () => {

    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const open = Boolean(anchor);
    const { notifications, notificationAction  } = useContext(UserNotificationContext) as UserNotificationContextType;
    const nav = useNavigate();

    useEffect(() => {

        if (!notifications || notifications.length  == 0) {
            setUnreadCount(0); 
            return;
        } 

        setUnreadCount(notifications.filter(x => !x.read).length);

    }, [notifications])

    const onHandleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }

    const onHandleClose = () => {
        setAnchor(null);
    }

    const notificationClicked = (NotificationId: string,  ActionUrl: string | null) => {
        //TODO:hit API mark as read, mark as read on context, navigate to URL.
        notificationAction(NotificationId, 2);
        onHandleClose();
        if (ActionUrl) {
            nav(ActionUrl);
        }

    }

    return (
        <>
            <Badge badgeContent={unreadCount} overlap="circular" color="primary" sx={{mr:1}}>
        <IconButton size='small' onClick={onHandleOpen}><NotificationsNoneIcon /></IconButton>
        </Badge>
            
            <Menu
                open={open}
                anchorEl={anchor}
                onClose={onHandleClose}
                sx={{
                    width:'440px',
                    maxHeight:'500px'
                }}
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
            >

                {notifications.length == 0 ? (
                    <MenuItem>
                        <ListItemText 
                            primary="No notifications to show"
                        />
                    </MenuItem>
                )
                :
                (
                    notifications.map(n => {
                        return (
                            <MenuItem key={n.id} sx={{ width: '400px', background: n.read ? '' : grey[700] }} onClick={(e) => notificationClicked(n.id, n.actionURL) } >
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="subtitle1" sx={{ whiteSpace: 'pre-line' }}>{n.message}</Typography>}
                                    secondary={<Typography variant="subtitle2" sx={{}}>{ n.dateCreated }</Typography>}
                                ></ListItemText>
                                
                            </MenuItem>
                        );
                    }) 
                )}
            </Menu>
        </>
    )
}

export default NotificationMenu;