import { ReactElement, createContext, useEffect, useReducer } from "react"
import UserNotification from "../Models/UserNotification";
import { useAuth } from "react-oidc-context";
import UserNotificationConnector from "./UserNotificationConnector";
import useAPI from "../Hooks/useAPI";

export type UserNotificationContextType = {
    notifications: UserNotification[],
    notificationAction: (NotificationId: string, Action:number) => void
}

export const UserNotificationContext = createContext<UserNotificationContextType | null>(null);

type UserNotificationProviderProps = {
    children: ReactElement | ReactElement[];
    
}

function NotifcationsReducer(state: UserNotification[], action: UserNotification): UserNotification[] {
    switch(action.action)
    {
        case 1: //CREATE
            if(action.read) return state;
            const index: number = state.map(x => x.id).indexOf(action.id);

            return index == -1 ? [...state, action] : state;
        case 2: //UPDATE
            return state.map(x => {
                if(x.id == action.id){
                    x.read = action.read;
                }
                return x;
            }).filter(x => !x.read);
        case 3: //DELETE
            return state.filter(x => x.id !== action.id);
        default:
            return state;
    }

}

const UserNotificationProvider = (props: UserNotificationProviderProps) => {

    const NOTIFICATION_API_URL = "/api/UserNotifications";
    const {Get, Put, Delete} = useAPI();
    const auth = useAuth();
    const [notifications, notificationsDispatch] = useReducer(NotifcationsReducer, []);

    const { events } = UserNotificationConnector(auth.user != null ? auth.user.access_token : "");

    useEffect(() => {

        const OnNotificationReceived = (notification: UserNotification) => {
            notificationsDispatch(notification);
        }
    
        events(OnNotificationReceived);

        Get(NOTIFICATION_API_URL).then(data => {
            for (var i = 0; i < data.notifications.length; i = i + 1) {
                notificationsDispatch(data.notifications[i]);
            }
        });

        return (): void => { }

    }, []);

    const notificationAction = (NotificationId: string, Action: number) => {
        var _notification = notifications.find(x => x.id == NotificationId);
        if (_notification) {
            const n = { ..._notification, read: true, action: Action };
            notificationsDispatch(n);
        }
        const actionUrl = `${NOTIFICATION_API_URL}/${NotificationId}`
        switch (Action) {
            case 2:
                Put(actionUrl);
                break;
            case 3:
                Delete(actionUrl);
                break;
        }
    }


    return (
        <UserNotificationContext.Provider value={{ notifications, notificationAction }} >
            {props.children}
        </UserNotificationContext.Provider>
    )
}

export default UserNotificationProvider;