import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DeleteOptions } from "./DeleteOptions";

const DeleteDialog = (props: DeleteOptions) => {

    return (
        <Dialog open={props.isOpen} onClose={e => props.onClose(e)} >
            <DialogTitle>Delete file</DialogTitle>
            <DialogContent>
                Are you sure you want to delete the file/folder {props.filename}?, this action can not be undone.
            </DialogContent>
            <DialogActions>
                <Button onClick={e => props.onConfirm(e)} variant="contained" color="error" data-testid={`confirmDelete`}>Delete</Button>
                <Button onClick={e => props.onCancel(e)} variant="contained" data-testid={`cancelDelete`}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;