import { useContext, } from "react";
import { SettingsContext, SettingsContextType } from "../Context/SettingsContext";
import { IAPIOptions } from "../Models/IAPIOptions";


const useSettings = ():IAPIOptions => {

    const { apiOptions } = useContext(SettingsContext) as SettingsContextType;

    return  apiOptions;
}

export default useSettings;