import { useContext, useEffect, useState } from "react";
import { EventContext, EventContextType } from "../Context/EventContext";
import { IPocketLitter } from "../Models/IPocketLitter";
import PocketLitter from "./PocketLitter";


const EventAlertContainer = () => {
    const {events, alertingEvents} = useContext(EventContext) as EventContextType;
    const [eventDetails, setEventDetails] = useState<IPocketLitter[]>([]);
    const [audio] = useState(new Audio("alert.mp3"));


    useEffect(() => {
        const eventList = events.filter(evt => {
            const index = alertingEvents.indexOf(evt.tripwireEventId);
            if(index >= 0) return evt;
        });

        setEventDetails(eventList);

    }, [events, alertingEvents]);    

    useEffect(() => {
        audio.addEventListener("ended", () => {
            audio.currentTime = 0;
            audio.play();
        }, false); 
        audio.play();
        return() => {
            audio.pause();
        }
    }, []);

    const eventElements = eventDetails.map(x => <PocketLitter key={x.tripwireEventId} {... x} />);

    return <div>{eventElements}</div>
}

export default EventAlertContainer;