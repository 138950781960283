import { Box, IconButton, TextField, Checkbox, FormControlLabel, Grid, Drawer, Chip, Paper, Typography, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState, useRef } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SelectAll from '@mui/icons-material/LibraryAddCheckOutlined';
import Deselect from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import useAPI from "../../Hooks/useAPI";
import { ReacherCommand } from "../Models/ReacherCommand";
import { ReacherFeedFilter } from "../Models/ReacherFeedFilter";

export interface FeedPanelFilterProps {
    onFilterChanged: (filters: ReacherFeedFilter[], deviceFilter: string) => void
}

const FeedFilters = (props: FeedPanelFilterProps, deviceFilter: string) => {

    const { Get } = useAPI();
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [commandFilters, setCommandFilters] = useState<ReacherFeedFilter[]>([]);
    const [commands, setCommands] = useState<ReacherCommand[]>([]);
    const [textFilter, setTextFilter] = useState<string>("");
    const [allSelected, setAllSelected] = useState<boolean>(true);
    const [showSummary, setShowSummary] = useState<Boolean>(true);

    useEffect(() => {
        props.onFilterChanged(commandFilters, textFilter);
    }, [commandFilters, textFilter]);

    useEffect((): void => {
        Get('api/reacher/commands')
            .then(data => { setCommands(data) })
    }, []);

    useEffect(() => {
        setCommandFilters(commands.map(c => {
            return { id: c.displayName, display: true, type: "command" }
        }))
    }, [commands]);

    useEffect(() => {
        setAllFilters(allSelected);
    }, [allSelected]);

    const setAllFilters = (display: boolean) => {
        const updated =
            commandFilters.map(f => {
                return { ...f, display: display }
            });

        setCommandFilters(updated);
    }

    const onCommandFilterToggle = (filter: ReacherFeedFilter) => {
        const updated = commandFilters.map(e => {
            if (e.id != filter.id) return e;
            return { id: filter.id, display: !e.display, type: filter.type }
        })

        setCommandFilters(updated);
    }

    const CreateFilterItem = (filter: ReacherFeedFilter) => {
        return <Grid item key={filter.id} width="100%">
            <FormControlLabel control={<Checkbox onChange={() => onCommandFilterToggle(filter)} />} label={filter.id} checked={filter.display} />
        </Grid>
    }

    const CreateFilterItemCompact = (filter: ReacherFeedFilter) => {
        return <Grid item key={filter.id} xs={4}>
            <FormControlLabel control={<Checkbox onChange={() => onCommandFilterToggle(filter)} />} label={filter.id} checked={filter.display} />
        </Grid>
    }

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        setShowDrawer(false);
    }

    // Manually arrange checkboxes to look nicer
    const filterStates = () => {
        return <Grid container padding="1em">
            <Grid container>
                {commandFilters.filter(f => f.id.indexOf('Triggered') < 0).map(filter => CreateFilterItemCompact(filter))}
            </Grid>
            <Grid container>
                {commandFilters.filter(f => f.id.indexOf('Triggered') > -1).map(filter => CreateFilterItem(filter))}
            </Grid>
        </Grid>
    }

    const onKeyUp = (e: any) => {
        const keysToClose: string[] = ["Enter", "Escape"];
        if (keysToClose.includes(e.key)) {
            setShowDrawer(false);
        }
    }

    let searchBox = <Box>
        <TextField label="Device filter" placeholder="filter devices" size="small"
            value={textFilter} onChange={e => setTextFilter(e.target.value)}
            onKeyUp={onKeyUp} variant="outlined" autoFocus />

    </Box>

    const showSearchDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
        setShowDrawer(true);
    };

    const filtersChecked = BuildUncheckedLabel(commandFilters);        

    return (
        <Box>
            <Grid container>
                <IconButton onClick={showSearchDrawer} >
                    <FilterAltIcon />
                </IconButton>
                {textFilter && <Chip label={'Device: ' + textFilter} onDelete={e => setTextFilter("")} sx={{ backgroundColor: blueGrey[500], m: 1 }} clickable />}
                {filtersChecked && <Chip label={filtersChecked} onDelete={e => setAllFilters(true)} sx={{ backgroundColor: blueGrey[500], m: 1 }} clickable />}
            </Grid>
            <Drawer
                anchor="right"
                open={showDrawer}
                onClose={toggleDrawer()}
                PaperProps={{ sx: { width: 360 } }}
                style={{ height: '100vh' }} >
                <Paper style={{ flex: 1 }}>
                    <Typography variant="h4" sx={{ m: 2 }}>Feed Filter</Typography>
                    <Box display="grid">
                        <Box gridColumn="span 4">
                            <Grid container padding="1em">
                                <Grid item md={12}>
                                    {searchBox}
                                </Grid>
                            </Grid>
                            <Button onClick={() => setAllSelected(!allSelected)} variant="text" sx={{ color: 'white', mx: '0.5em' }}>
                                {allSelected && <><Deselect /><Typography sx={{ m: 1 }}>Select None</Typography></>}
                                {!allSelected && <><SelectAll /><Typography sx={{ m: 1 }}>Select All</Typography></>}
                            </Button>
                            {filterStates()}

                        </Box>
                    </Box>
                </Paper>
            </Drawer>
        </Box>
    )
}

export default FeedFilters;

function BuildUncheckedLabel(commandFilters: ReacherFeedFilter[]): string {
    let unchecked = commandFilters.filter(f => !f.display).length;
    let total = commandFilters.length;
    if (unchecked == 0) return "";
    return unchecked + " / " + total + " types filtered";
}
