import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageTemplate from "../Page";

const Unauthorized = () => {

    const location = useLocation();


    return (
        <PageTemplate heading="401 Unauthorized" subtitle="">
            <Box>
                <p>Access to <i>{location.pathname}</i> is denied.</p>
            </Box>
        </PageTemplate>
    );
}

export default Unauthorized;
