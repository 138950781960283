import { Box, IconButton, Paper, Typography } from "@mui/material";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useContext, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IDevice } from "../../models/Device";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PolylineIcon from '@mui/icons-material/Polyline';
import {GeoDataUpdateContext, GeoDataUpdateContextType } from "../../GeoDataUpdateContext";
import { grey } from "@mui/material/colors";

export interface IDeviceCardProps {
    device: IDevice,
    panTo: (coords: number[]) => void
}

const DeviceCard = ({ device, panTo }: IDeviceCardProps) => {

    const [cardOpen, setCardOpen] = useState<boolean>(false);

    const { onToggleDevice  } = useContext(GeoDataUpdateContext) as GeoDataUpdateContextType;

    const genStyle = (visible:boolean) => {
        return {
            color: visible ? 'white' : grey[600]
        };
    }

    return (
        <Box>
            <Box component={Paper} elevation={6} boxShadow={0}
                key={device.id}
                sx={{
                    px: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <Typography variant="body1">{device.deviceName}</Typography>
                <Box
                    sx={{
                        ml: 'auto'
                    }}>
                    <IconButton data-testid={`atlas_device_${device.id}_device_toggle`} onClick={e => onToggleDevice(device.id, "LIVE", !device.visible)} size="small" sx={genStyle(device.visible)}><PhoneIphoneIcon /></IconButton>
                    <IconButton data-testid={`atlas_device_${device.id}_trail_toggle`} onClick={e => onToggleDevice(device.id, "TRAIL", !device.trailVisible)} size="small" sx={genStyle(device.trailVisible)}><PolylineIcon /></IconButton>
                    <IconButton data-testid={`atlas_device_${device.id}_panTo_button`} onClick={(e: any) => { panTo(device.geoJSON.geometry.coordinates) }} size="small"><LocationSearchingIcon /></IconButton>
                    <IconButton data-testid={`atlas_device_${device.id}_card_toggle`} onClick={(e:any) => {setCardOpen(!cardOpen)}}>
                        {cardOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                   
                </Box>
            </Box>
            {cardOpen &&
            <Box sx={{p:1}}>
                    <Typography data-testid={`atlas_device_${device.id}_gps_time`}>GPS Time: { device.geoJSON.properties.gps_time }</Typography>
                    <Typography data-testid={`atlas_device_${device.id}_lat`}>Lat: {device.geoJSON.geometry.coordinates[1]}</Typography>
                    <Typography data-testid={`atlas_device_${device.id}_lng`}>Lng: {device.geoJSON.geometry.coordinates[0]}</Typography>
            </Box>
            }
        </Box>
    );
}

export default DeviceCard;

