import { createContext, ReactElement, useState } from "react"

export type MainMenuContextType = {
    isOpen: boolean,
    SetState: (open: boolean) => void
}

export const MainMenuContext = createContext<MainMenuContextType | null>(null);

type MainMenuProviderProps = {
    children: ReactElement | ReactElement[];
}

const MainMenuProvider = (props: MainMenuProviderProps) => {

    const [open, setOpen] = useState<boolean>(false);

    const SetState = (open: boolean) => {
        setOpen(open);
    }

    return (
        <MainMenuContext.Provider value={{ isOpen: open, SetState }}>
            {props.children}
        </MainMenuContext.Provider>
    );
}



export default MainMenuProvider;