import { CardMedia, Skeleton } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import { Buffer } from 'buffer';
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { ReacherDeviceCommand } from "../../../Models/ReacherDeviceCommand";

const ReacherMediaAudio = (props: { deviceCommand: ReacherDeviceCommand, fileId: string }) => {

    const [data, setData] = useState("");
    const auth = useAuth();

    var config: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            "Content-Type": "application/json"
        },
        responseType: "arraybuffer"
    };

    const fetchData = () => {
        axios.get(`/api/reacher/data/download?DeviceCommandId=${props.deviceCommand.id}&fileId=${props.fileId}`, config)
            .then(r => {
                let d = Buffer.from(r.data).toString('base64');
                setData(`data:${r.headers['content-type']};base64, ${d}`)
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.deviceCommand.status]);


    const audio_memo = useMemo(() => {
        return <audio preload="metadata" controls style={{ background: 'black' }}>
            <source src={data} />
        </audio>
    }, [data]);

    const media = () => {
        if (props.deviceCommand.status.toUpperCase() !== "COMPLETED")
            return <Skeleton variant="rectangular" width={100} height={100}></Skeleton>

        if (!data) return <audio preload="metadata" controls style={{ background: 'black' }} />

        return audio_memo;
    }

    return <CardMedia sx={{ display: 'flex', flexDirection: 'row' }}>{media()}</CardMedia>;
}

export default ReacherMediaAudio;