import { Box, Paper } from '@mui/material';
import { useCallback, useContext, useEffect, } from 'react';
import { EventContext, EventContextType } from '../Context/EventContext';
import PocketLitter from './PocketLitter';
import { DataGridPro, GridColumns, GridToolbar } from '@mui/x-data-grid-pro';
import PageTemplate from '../Common/Page';
import Loading from '../Common/Loading';
import useViewPortDimensions from '../Hooks/useViewPort';


const EventFeed = () => {

    const {templatePageHeight} = useViewPortDimensions();

    const { events } = useContext(EventContext) as EventContextType;

    const columns: GridColumns = [
        { field: 'event', headerName: 'Event', valueGetter: (params) => "Profile Tripped", flex: 1 },
        { field: 'username', headerName: 'Username', flex: 1 },
        { field: 'verify', headerName: 'Password', flex: 1 },
        { field: 'date', headerName: 'Event Date', valueGetter: (params) => params.row.event.eventDate, flex: 1 }
    ];

    const getPanelDetail = (rowProps: any) => {
        return <PocketLitter {...rowProps.row} />
    }
    const getDetailPanelHeight = useCallback(() => 400, []);

    const pageTitle = "Alerts"
    if(events === null) {
        <PageTemplate heading={pageTitle} subtitle=''><Loading /></PageTemplate>
    }

    return (
        <PageTemplate heading={pageTitle} subtitle=''>
            <Box sx={{ width: '100%', height:templatePageHeight}} component={Paper} elevation={6}>
                <DataGridPro 
                    sx = {{
                        //'.MuiDataGrid-toolbarContainer>button': {color: 'white'}
                    }}
                    components={{ Toolbar: GridToolbar }}
                    columns={columns}
                    rows={events}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getPanelDetail}
                />
            </Box>
        </PageTemplate>
    );
}

export default EventFeed;