import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { CommandButtonProps } from "./CommandButtonProps"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Dictionary } from "lodash";

const LengthCommandButton = ({ cmd, enabled, onSend }: CommandButtonProps) => {

    const [open, setOpen] = useState<boolean>(false);
    const [time, setTime] = useState<string>("10");
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(true);

    const handleMenuItemClicked = (event: any, nextLength: string) => {
        const params: Dictionary<string> = {
            "length": String(formatTime(nextLength))
        };

        onSend(cmd.id, params);
        handleClose(event);
    }

    const handleClose = (event: any) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    }

    const onCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rgx = /^[0-9]*$/;
        let value = (e.target as HTMLInputElement).value;
        if (rgx.test(value)) {
            if (parseInt(value) < 10 || parseInt(value) > 120) {
                setValid(false);
            } else {
                setValid(true);
            }
            setTime(value);
        }
    }

    const formatTime = (timeString: string): string => {
        const totalSeconds = parseInt(timeString);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const fmtMinutes = minutes.toString().padStart(2, "0");
        const fmtSeconds = seconds.toString().padStart(2, "0");
        return `00:${fmtMinutes}:${fmtSeconds}`;
    }

    const onConfirmClicked = (e: React.MouseEvent<HTMLElement>) => {

        let cappedTime = time ? time : "10";
        const params: Dictionary<string> = {
            "length": formatTime(cappedTime)
        };

        onSend(cmd.id, params);
        closeAll();
    }

    const closeAll = () => {
        setTime("10");
        setModalOpen(false);
    }

    return (
        <>
            <ButtonGroup variant="contained" sx={{ width: '100%' }}>
                <Button data-testid={"command" + cmd.displayName + "Button"} variant="text" size="small" sx={{ width: '100%', color: 'white', border: '1px solid blue', borderRight: 'none' }}  >{cmd.displayName}</Button>
                <Button
                    ref={anchorRef}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => setOpen(!open)}
                    disabled={!enabled}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={(handleClose)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem
                                        key="10s"
                                        onClick={(event) => handleMenuItemClicked(event, "10")} >
                                        10s
                                    </MenuItem>
                                    <MenuItem
                                        key="30s"
                                        onClick={(event) => handleMenuItemClicked(event, "30")} >
                                        30s
                                    </MenuItem>
                                    <MenuItem
                                        key="custom"
                                        onClick={() => setModalOpen(true)} >
                                        Custom
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <DialogTitle>Capture {cmd.displayName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a length in seconds to capture
                    </DialogContentText>
                    <Box sx={{ m: 1 }} >
                        <TextField sx={{ width: '100%' }} onChange={onCustomChange} value={time} />
                    </Box>
                    <Box>
                        <Typography color={valid ? 'success' : 'error'} >Length must be between 10 and 120 seconds</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="error" onClick={() => closeAll()}>Close</Button>
                    <Button variant="contained" onClick={e => onConfirmClicked(e)} disabled={!valid}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LengthCommandButton;