import { Box, Button, Paper } from "@mui/material";
import blue from "@mui/material/colors/blue";
import { ReactElement } from "react";

export const NomadMapControlContainer = (props: any) => {
    return (
        <Box component={Paper} sx={{
            position: 'absolute',
            background: '#fff',
            top: 10,
            right: 10,
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            userSelect: 'none'
        }}>
            {props.children}
        </Box>
    )
}

export interface INomadMapButtonProps {
    onClick: (e: any) => void,
    icon: ReactElement,
    active: boolean
}

export const NomadMapControl = ({ onClick, icon, active }: INomadMapButtonProps) => {
    return (
        <Box component={Button} onClick={onClick} sx={{
            textAlign: 'center',
            cursor: 'pointer',
            borderBottom: ' 1px solid #ccc',
            color: active ? blue[600] : 'black',
            borderRadius:0,
            p: 0,
            m: 0,
            minWidth: 29,
            width: 29,
            height: 29
           
        }}>
            {icon}
        </Box>
    );
}