import { User } from "oidc-client-ts";
import { ReactElement } from "react"
import Unauthorized from "./Errors/401-Unauthorized";
import { useAuth } from "react-oidc-context";

export interface IProtectedRouteProps {
    roles: string[],
    children: ReactElement
}

export const ProtectedRoute = ({ roles, children }: IProtectedRouteProps) => {
    const auth = useAuth();
    const user: User | null | undefined = auth.user;

    // No roles provided?, then everyone can see it. 
    if (roles.length == 0) return <>{children}</>;
    const usersRoles: string[] = (user?.profile as any).roles;
    const hasRole: boolean = usersRoles.filter(r => roles.includes(r)).length > 0;
    if (hasRole) {
        return <>{children}</>
    }
    return <Unauthorized />
}