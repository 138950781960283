import { Box } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import { Buffer } from 'buffer';
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import ImageViewer from './ImageViewer';

const ImageThumbnail = (props: { DeviceCommandId: string, imageId: string }) => {
    const [data, setData] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const auth = useAuth();


    var config: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            "Content-Type": "application/json"
        },
        responseType: "arraybuffer"
    };

    axios.get(`/api/reacher/data/download?DeviceCommandId=${props.DeviceCommandId}&fileId=${props.imageId}`, config)
        .then(r => {
            let d = Buffer.from(r.data).toString('base64');
            setData(`data:${r.headers['content-type']};base64, ${d}`)
        })

    return (
        <Box sx={{ marginLeft: '10px' }}>
            <img loading="lazy" src={data} height={90} alt="" style={{ margin: '2', paddingLeft: '0', cursor: 'pointer' }} onClick={handleOpen} />
            <ImageViewer imageUrl={data} key={props.imageId} isOpen={open} handleClose={handleClose} canDownload={false} />
        </Box>);
}

export default ImageThumbnail;