import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";
import { blue, green, orange, pink, red, grey } from '@mui/material/colors';

const AppTheme = (props) => {


    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: blue[800]
            },
            secondary: {
                main: green[800]
            },
            error: {
                main: red[800]
            },
            warning: {
                main: orange[800]
            },
            success: {
                main: green[600]
            },
            pending: {
                main: grey[800]
            }
        },
    });


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );

}

export default AppTheme;