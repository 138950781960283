import { Box, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimension";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import FeedFilters from "./FeedFilters";
import { ReacherFeedFilter } from "../Models/ReacherFeedFilter";
import { ReacherDeviceCommand } from "../Models/ReacherDeviceCommand";
import { ReacherCommand } from "../Models/ReacherCommand";
import useAPI from "../../Hooks/useAPI";
import ReacherFeedItem from "./ReacherFeedItem";
import useViewPortDimensions from "../../Hooks/useViewPort";

const ReacherFeed = () => {

    const { Get } = useAPI();
    const [filter, setFilter] = useState<ReacherFeedFilter[]>([]);
    const [textFilter, setTextFilter] = useState<string>("");
    const { viewPortHeight } = useViewPortDimensions();
    const { deviceCommands } = useContext(ReacherContext) as ReacherContextType;
    const [filteredCommands, setFilteredCommands] = useState<ReacherDeviceCommand[]>([]);
    const [commands, setCommands] = useState<ReacherCommand[]>([]);

    const deviceCommandlist = filteredCommands.map((x) => <ReacherFeedItem {...x} key={x.id} />);

    useEffect(() => {
        ApplyFilters(filter, textFilter);
    }, [deviceCommands, filter, textFilter]);

    // Initial feed filter state
    useEffect((): void => {
        Get('api/reacher/commands')
            .then(data => { setCommands(data) })
    }, []);

    useEffect(() => {
        setFilter(commands.map(c => {
            return { id: c.displayName, display: true, type: "command" }
        }))
    }, [commands]);

    const ApplyFilters = (commandFilters: ReacherFeedFilter[], textFilter: string) => {
        let filterText = textFilter.toUpperCase();
        const updated = deviceCommands.filter(x => x.status.toUpperCase() !== "DELETED")
            .filter(x => {
            return commandFilters.findIndex(f => f.display && f.id.toUpperCase() == x.command.toUpperCase()) > -1 &&
                x.deviceName?.toUpperCase().indexOf(filterText) > -1
        });
        setFilteredCommands(updated);
    }

    return (
        <Box component={Paper} elevation={2} sx={{ height: viewPortHeight, overflowX: 'auto', p: 1, scrollbarWidth: 'thin' }}>
            <FeedFilters onFilterChanged={(f, t) => { setFilter(f); setTextFilter(t) }} />
            {deviceCommandlist}
        </Box>
    );
}

export default ReacherFeed;