import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Dictionary } from "lodash";
import { useState } from "react";
import { CommandButtonProps } from "./CommandButtonProps";

const MessageCommandButton = ({cmd, enabled, onSend}:CommandButtonProps) => {

    const MAX_MESSAGE_LENGTH:number = 500;
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [message, setMessage] = useState<string>("");

    const onMessageKeyUp = (e:React.KeyboardEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        if(value.length <= MAX_MESSAGE_LENGTH) {
            setMessage(value);
        }
    }

    const onSendClicked = (e:React.MouseEvent<HTMLElement>) => {
        const params:Dictionary<string> = {
            "text": message
        };

        onSend(cmd.id, params);
        setModalOpen(false);
    }

    return (
        <>
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <DialogTitle>Send Message to Devices</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a message to send to the devices, the message can not be longer than {MAX_MESSAGE_LENGTH} characters.
                </DialogContentText>
                <Box sx={{mt:2}} >
                    <TextField sx={{width:'100%'}} multiline rows={3} onKeyUp={onMessageKeyUp} inputProps={{maxLength:MAX_MESSAGE_LENGTH}} />
                    <p>{message.length}/{MAX_MESSAGE_LENGTH}</p>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={() => setModalOpen(false)}>Close</Button>
                <Button variant="contained" disabled={message.length == 0} onClick={onSendClicked}>Send</Button>
            </DialogActions>
        </Dialog>
        <Button data-testid={"command"+cmd.displayName+"Button"} disabled={!enabled} variant="contained" size="small" onClick={() => setModalOpen(true)} sx={{width:'100%'}}>{cmd.displayName}</Button>
        </>
    );
}

export default MessageCommandButton;