import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material"
import { IFeatureCollection } from "../../types/IFeatureCollection"
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PolylineIcon from '@mui/icons-material/Polyline';
import * as turf from "@turf/turf";
import { useMemo, useState } from "react";
import { ColourPicker } from "../EditMapDrawer";
import debounce from 'lodash.debounce';
import { grey } from "@mui/material/colors";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

export interface IAtlasFeatureListProps {
    userdata: IFeatureCollection,
    panTo: (coords: number[]) => void,
    onEditFeature: (id: string, label: string, colour: string) => void,
    onDeleteFeature: (id: string) => void,
}

export const AtlasFeatureList = (props: IAtlasFeatureListProps) => {

    const list_memo = useMemo(() => {
        const items = props.userdata.features.map(f => <AtlastFeatureItem key={f.properties.UserDataId} feature={f} panTo={props.panTo} onEditFeature={props.onEditFeature} onDeleteFeature={props.onDeleteFeature} />);
        return items
    }, [props.userdata]);

    return <>
        {/* <Box 
        sx={{
            width:'33%',
            height:'150px',
            position:'fixed',
            top:'60',
            left:'33%',
            zIndex:1000,
            background:'black'
        }}
        >
            edit box
        </Box> */}
        <List>{list_memo}</List></>
}

interface IAtlastFeatureItemProps {
    feature: any,
    panTo: (coords: number[]) => void,
    onEditFeature: (id: string, label: string, colour: string) => void,
    onDeleteFeature: (id: string) => void,
}

const AtlastFeatureItem = ({ feature, panTo, onDeleteFeature, onEditFeature }: IAtlastFeatureItemProps) => {

    const [cardOpen, setCardOpen] = useState<boolean>(false);

    const id = feature.properties.UserDataId;
    const label = feature.properties.label !== undefined ? feature.properties.label : feature.properties.UserDataId;
    const color = feature.properties.fillColour !== undefined ? feature.properties.fillColour : "#000";

    const [data, setData] = useState({
        id,
        label,
        color
    });

    const icon = (geotype: string) => {
        switch (geotype) {
            case "Point":
                return <CircleIcon />
            case "Polygon":
            case "LineString":
                return <PolylineIcon />
            default:
        }
    }

    const getPanToCoords = () => {
        switch (feature.geometry.type) {
            case 'Point':
                panTo(feature.geometry.coordinates)
                break;
            case 'LineString':
                var box = turf.bbox(turf.lineString(feature.geometry.coordinates));
                var lineCentre = turf.centroid(turf.bboxPolygon(box));
                panTo(lineCentre.geometry.coordinates);
                break;
            case 'Polygon':
                var centre = turf.centroid(turf.polygon(feature.geometry.coordinates));
                panTo(centre.geometry.coordinates);
                break;
            default:
                console.error(`Unsupported feature type ${feature.geometry.type}`);
                break;
        }
    }

    const onColourChanged = debounce((colourHex: string) => {
        setData({ ...data, color: colourHex })
    }, 100);


    const onNameValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {

        let value = (e.target as HTMLInputElement).value;
        setData({ ...data, label: value });
    }

    const onKeyUp = () => (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            onEditFeature(data.id, data.label, data.color);
        }
    }

    const onCancelChanges = (e: any) => {
        setData({ id, label, color });
        setCardOpen(false);
    }

    const onSave = (e: any) => {
        onEditFeature(data.id, data.label, data.color);
        setCardOpen(false);
    }

    return (
        <>
            <ListItemButton key={data.id} sx={{ py: 0.5 }} onClick={() => getPanToCoords()}>
                <IconButton>{icon(feature.geometry.type)}</IconButton>
                <ListItemText primary={label} />
                <IconButton onClick={(e: any) => { setCardOpen(!cardOpen) }}>
                    {cardOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
                {/* <IconButton><EditOutlinedIcon /></IconButton>
            <IconButton onClick={(e:any) => onDeleteFeature(id)}><DeleteOutlinedIcon /></IconButton> */}
            </ListItemButton>
            {cardOpen &&
                <Box sx={{ p: 2, background: grey[800], display: 'flex', flexDirection: 'column' }}>
                    <TextField sx={{ mt: 1 }} autoFocus fullWidth onKeyUp={onKeyUp} onChange={onNameValueChanged} value={data.label} label='Label' data-testid={`dialogvalue`} />
                    <ColourPicker hex={data.color} onUpdate={onColourChanged} />

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton onClick={(e: any) => onDeleteFeature(id)} color="error"><DeleteOutlinedIcon /></IconButton>
                        <Box sx={{ ml: 'auto' }}>
                            <Button onClick={onCancelChanges} sx={{ color: 'white' }}>Cancel</Button>
                            <Button onClick={onSave} variant="contained" color="success">Save</Button>
                        </Box>
                    </Box>
                </Box>
            }
        </>

    )
}