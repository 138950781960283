import { Button } from "@mui/material";
import { Dictionary } from "lodash";
import { useState } from "react";
import ReacherDialog from "../../../Components/ReacherDialog";
import { ReacherDialogOptions } from "../../../Components/ReacherDialogOptions";
import { CommandButtonProps } from "./CommandButtonProps";

const ConfirmCommandButton = ({ cmd, enabled, onSend }: CommandButtonProps) => {

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const onSendClicked = (e: React.MouseEvent<HTMLElement>) => {
        const params: Dictionary<string> = {};
        onSend(cmd.id, params);
        setModalOpen(false);
    }

    let message = null;
    switch (cmd.commandText) {
        case "CLEAR":
            message = "This action will clear all pending commands for the device, are you sure?";
            break;
        case "RESET":
            message = "WARNING! This will force a factory reset on the device, are you sure?";
            break;
        default:
            message = "";
    }

    let dialogOptions: ReacherDialogOptions = {
        isOpen: modalOpen,
        title: cmd.displayName,
        action: cmd.displayName,
        message: message,
        onClose: () => setModalOpen(false),
        onCancel: () => setModalOpen(false),
        onConfirm: (e) => onSendClicked(e)
    };

    return (
        <>
            <ReacherDialog {...dialogOptions} />
            <Button data-testid={"command" + cmd.displayName + "Button"} disabled={!enabled} variant="contained" size="small" onClick={() => setModalOpen(true)} sx={{ width: '100%' }}>{cmd.displayName}</Button>
        </>
    );
}

export default ConfirmCommandButton;