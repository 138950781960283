import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageTemplate from "../Page";


const PageNotFound = () => {

    const location = useLocation();


    return (
        <PageTemplate heading="404 Page not found" subtitle="">
            <Box>
                <p><i>{location.pathname}</i> is not a valid route.</p>
            </Box>
        </PageTemplate>
    );
}


export default PageNotFound;
