import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useAPI from "../../Hooks/useAPI";
import { IUser } from "../../Models/IUser";
import useViewPortDimensions from "../../Hooks/useViewPort";
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import { IGroup } from "../../Models/IGroup";
import FancyLoader from "../../Common/FancyLoader";

interface ILoadingState {
    loading: boolean,
    error: boolean,
    message: string
}

const UserManagerList = () => {

    const theme = useTheme();

    const [users, setUsers] = useState<IUser[]>([]);
    const { Get } = useAPI();
    const { viewPortHeight } = useViewPortDimensions();
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [loadingState, setLoadingState] = useState<ILoadingState>({
        loading: true,
        error: false,
        message: "Fetching user data"
    });

    useEffect(() => {

        const userPromise: Promise<IUser[]> = Get('/api/usermanager');
        const groupPromise: Promise<IGroup[]> = Get('/api/usermanager/groups');

        Promise.all([userPromise, groupPromise])
            .then((values) => {
                setUsers(values[0]);
                setGroups(values[1]);
                setLoadingState({ ...loadingState, loading: false, error: false });
            })
            .catch(err => {
                setLoadingState({ ...loadingState, loading: true, error: true, message: `An error occured while fetching the data, ${err.message}` })
                console.error(err);
            });

    }, []);


    if (loadingState.loading || loadingState.error) {

        return (
            <Box component={Paper} sx={{ p: 1, height: viewPortHeight }}>
                <FancyLoader error={loadingState.error} message={loadingState.message} icon={<PersonIcon />} />
            </Box>
        )
    }


    return (
        <Box sx={{ p: 1, height: viewPortHeight }}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">User Manager</Typography>

                </Grid>
                <Grid item xs={12} sm={12} md={6}

                >
                    <Paper elevation={4} sx={{ p: 0 }}>

                        <List
                            sx={{
                                pb: 0,
                                [theme.breakpoints.down('md')]: {
                                    maxHeight: (viewPortHeight / 2)  - 40,
                                    overflowY:'auto'
                                }
                        }}>
                            <ListItem>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText
                                    primary="Users"
                                    primaryTypographyProps={{
                                        fontSize: 20,
                                        fontWeight: 'medium',
                                        letterSpacing: 0
                                    }}
                                />
                                <Button startIcon={<PersonAddAlt1Icon />} variant="contained" color="success" component={NavLink} to={`/settings/users/create`} data-testid="btn_createuser">
                                    Add User
                                </Button>
                            </ListItem>
                            {users.map((user: IUser, index: number) => {

                                const isLast: boolean = index === users.length - 1;

                                return (
                                    <ListItem divider={!isLast} key={user.id}>
                                        <ListItemText primary={user.userName} />
                                        <Button startIcon={<EditIcon />} component={NavLink} to={`/settings/users/${user.id}`} variant="contained" size="small" data-testid={`btn_edituser_${user.userName}`}>Edit</Button>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={4}
                        sx={{
                            p: 0,
                            [theme.breakpoints.down('md')]: {
                                maxHeight: (viewPortHeight / 2) - 40,
                                overflowY: 'auto'
                            }
                        }}>
                        <List>
                            <ListItem>
                                <ListItemIcon><GroupIcon /></ListItemIcon>
                                <ListItemText
                                    primary="Groups"
                                    primaryTypographyProps={{
                                        fontSize: 20,
                                        fontWeight: 'medium',
                                        letterSpacing: 0
                                    }}
                                />
                                <Button data-testid="btn_createusergroup" startIcon={<GroupAddIcon />} variant="contained" color="success" component={NavLink} to={`groups/create`} >
                                    Add Group
                                </Button>
                            </ListItem>

                            {groups.length === 0 ?
                                (
                                    <Box sx={{
                                        m: '0 auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '50%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 300

                                    }}>
                                        <GroupIcon sx={{ fontSize: 64 }} />
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}> No groups have been created yet, create a group and it will appear here.</Typography>



                                    </Box>
                                ) :
                                (
                                    groups.map((group: IGroup, index: number) => {
                                        const isLast: boolean = index === groups.length - 1;

                                        return (
                                            <ListItem divider={!isLast} key={group.id}>
                                                <ListItemText primary={group.name} />
                                                <Button data-testid={`btn_editusergroup_${group.name}`} startIcon={<EditIcon />} component={NavLink} to={`/settings/users/groups/${group.id}`} variant="contained" size="small">Edit</Button>
                                            </ListItem>
                                        )
                                    })
                                )}


                        </List>

                    </Paper>
                </Grid>

            </Grid>

        </Box>
    );

}

export default UserManagerList;