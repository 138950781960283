import { createContext, ReactElement, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { IAPIOptions } from "../Models/IAPIOptions";

export type SettingsContextType = {
    apiOptions: any
}

export const SettingsContext = createContext<SettingsContextType>({ apiOptions: null });

type SettingsProviderProps = {
    children: ReactElement | ReactElement[];
}

const SettingsProvider = (props: SettingsProviderProps) => {

    const auth = useAuth();

    const [apiOptions, setApiOptions] = useState<IAPIOptions | null>(null);


    useEffect(() => {

        const requestParams: RequestInit = {
            headers: {
                "Authorization": "Bearer " + auth.user?.access_token,
                "Content-Type": "application/json"
            }
        };

        fetch('/api/settings/apis', requestParams)
            .then(response => response.json())
            .then((data: IAPIOptions) => setApiOptions(data));

    }, [auth.user?.access_token]);

    return (
        <SettingsContext.Provider value={{ apiOptions }}>
            {apiOptions ? props.children : <>Loading ... </>}
        </SettingsContext.Provider>
    );
}



export default SettingsProvider;