import { Alert, Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PageTemplate from "../../Common/Page";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { INomadLink } from "../../Models/INomadLink";

interface INewNomadLink {
    linkText: string,
    linkUrl: string,
    createdBy: string
}

const LinkManagerCreate = () => {

    const [link, setLink] = useState<INewNomadLink>({ linkText: "", linkUrl: "", createdBy: "" });
    const [errors, setErrors] = useState<string[]>([]);
    const { PushSuccess } = useNotifications();
    const { Post } = useAPI();
    const nav = useNavigate();

    const createLink = () => {

        let _errors = [];

        if (!link.linkText && link.linkText.length < 2) {
            //link text is required
            _errors.push("Link text is required");
        }

        if (!link.linkUrl || !link.linkUrl.startsWith("http")) {
            _errors.push("Link URL must be a valid URL");
            //link url must be populated and start with http. 
        }

        setErrors(_errors);

        if (_errors.length === 0) {

            Post('/api/nomad/linkmanager', link)

                .then((data: INomadLink[]) => {
                    PushSuccess("Link created");
                    nav("/settings/links");
                })

        }
    }

    return (
        <PageTemplate heading="Create Link" subtitle="Create a new link to go in the Tools section of the navigation menu.">
            <Box >

                {errors.length > 0 &&
                    <Box>

                        {errors.map((err, index) => <Alert key={index} sx={{ my: 1 }} severity="error">{err}</Alert>)}

                    </Box>
                }

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField sx={{ my: 1 }} label="Link Text" onKeyUp={(e: any) => setLink({ ...link, linkText: e.target.value })} required />
                    <TextField sx={{ my: 1 }} label="Link URL" onKeyUp={(e: any) => setLink({ ...link, linkUrl: e.target.value })} required />
                    <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                        <Button sx={{ mr: 1 }} variant="contained" onClick={e => createLink()} >Create</Button>
                        <Button component={NavLink} to={`/settings/links`} variant="contained" color="error"  >Cancel</Button>
                    </Box>

                </Box>

            </Box>
        </PageTemplate>
    );
}

export default LinkManagerCreate;