
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, ReactElement, useState } from "react"
import _ from "lodash";

export type NotificationContextType = {
    PushError:(message:string) => void,
    PushSuccess:(messsage:string) => void
}

export const NotificationContext = createContext<NotificationContextType | null>(null);

type NotificationProviderProps = {
    children: ReactElement | ReactElement[];
}

interface NomadNotification {
    id:string,
    message:string,
    severity:AlertColor, 
    Unset:(id:string) => void
}


const NomadAlert = (notification:NomadNotification) : ReactElement => {

    const [open, setOpen] = useState<boolean>(true);

    const handleClose = () => {setOpen(false); notification.Unset(notification.id) };

    //TEMP HACK: need to look into how errors remain open and success hides after 5 seconds. .
    const duration = notification.severity === "error" ? 999999 : 5000;

    return (
        <Snackbar open={open} onClose={handleClose} autoHideDuration={duration}>
            <Alert severity={notification.severity} onClose={handleClose} sx={{width: 500}} variant="filled" elevation={6}>
                {notification.message}
            </Alert>
        </Snackbar>
    )
}

const NotificationProvider = (props: NotificationProviderProps) => {

    const [Notifications, SetNotifications] = useState<NomadNotification[]>([]);
    

    const PushError = (message:string) => {
        console.error('ERROR:', message);
        PushNotification(message, "error");
    }

    const PushSuccess = (message:string) => {
        PushNotification(message, "success");
    }

    const PushNotification = (message:string, severity:AlertColor):void => {
        
        const _id:string = _.uniqueId();
        const n:NomadNotification = {
            id: _id,
            message: message,
            severity: severity,
            Unset: RemoveNotification
        }

        SetNotifications([...Notifications, n]);
    }

    const RemoveNotification = (id:string):void => {    
        const n = Notifications.filter(x => x.id !== id);
        SetNotifications(n);
    }

    return(
        <NotificationContext.Provider value={{ PushError, PushSuccess }}>
            {Notifications.map(x => <NomadAlert key={x.id} {... x} />)}
            {props.children}
        </NotificationContext.Provider>
    );
}



export default NotificationProvider;