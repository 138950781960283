import { orderBy } from "lodash";
import { createContext, ReactElement, useEffect, useReducer } from "react";
import { useAuth } from "react-oidc-context";
import useAPI from "../Hooks/useAPI";
import { GetCommandsResponse } from "./Models/GetCommandResponse";
import { ReacherDevice } from "./Models/ReacherDevice";
import { ReacherDeviceCommand } from "./Models/ReacherDeviceCommand";
import ReacherConnector from "./ReacherConnector";

export type ReacherContextType = {
    devices: ReacherDevice[],
    deviceCommands: ReacherDeviceCommand[],
    SelectDevice(deviceId: string): void,
    DeSelectDevice(deviceId: string): void,
    alerts: ReacherDeviceCommand[]
}

export const ReacherContext = createContext<ReacherContextType | null>(null);

type ReacherProviderProps = {
    children: ReactElement | ReactElement[];
}

const ReacherProvider = (props: ReacherProviderProps) => {

    const { Get } = useAPI();
    const auth = useAuth();

    const { events } = ReacherConnector(auth.user != null ? auth.user.access_token : "");

    const [deviceCommands, deviceCommandDispatch] = useReducer(deviceCommandReducer, []);
    const [devices, deviceDispatch] = useReducer(deviceReducer, []);
    const [alerts, alertDispatch] = useReducer(alertReducer, []);


    function alertReducer(state: ReacherDeviceCommand[], action: ReacherDeviceCommand): ReacherDeviceCommand[] {

        switch (action.status.toUpperCase()) {
            case 'COMPLETED': {
                return state.filter(x => x.id !== action.id);
            }
            case 'ALERT CREATED': {
                const index = state.findIndex(x => x.id == action.id);
                const inState = index > -1;
                if (!inState) {
                    // Add
                    return [...state, action];
                } else {
                   return state.map(x => {
                    if(x.id == action.id) {
                        return action;
                    }
                    return x;
                   });
                }
            }
        }

        return state;
    }

    function deviceReducer(state: ReacherDevice[], action: ReacherDevice | ReacherDevice[]): ReacherDevice[] {
        //this is not idea, Shift it up so we add an update type to the inbound request so we don't have to infer type.
        if(Array.isArray(action))
        {

            const newState = state.map(x => {
                var _item = action.find(f => f.id === x.id);
                if (!_item) return x;

                return {... x, alive: _item.alive, lastHeartbeat: _item.lastHeartbeat, testMode: _item.testMode};

            });
            return newState;
        }
        else
        {

            switch (action.status.toUpperCase()) {
                case 'CREATED':
                    const index = state.findIndex(x => x.id === action.id);
                    if (index === -1) return [action, ...state];
                    return state;
                case 'UPDATED': {
                    const index = state.findIndex(x => x.id === action.id);
                    if (index === -1) {
                        return [action, ...state];
                    }
                    else {
                        const newState = [...state];
                        newState[index] = { ...newState[index], ...action };
                        return newState;
                    }
                }
                case 'DELETING':
                case 'DELETED': {
                    return state.filter(x => x.id !== action.id);
                }
                default:
                    throw new Error("UNKNOWN Status of reacher device.");
            }
        }

    }


    function deviceCommandReducer(state: ReacherDeviceCommand[], action: ReacherDeviceCommand | ReacherDeviceCommand[]): ReacherDeviceCommand[] {
        if (Array.isArray(action)) {
            return action;
        }
        else {
            switch (action.status.toUpperCase()) {
                case 'CREATED':
                    const index = state.findIndex(x => x.id === action.id);
                    if (index === -1) {
                        return orderBy([action, ...state], 'dateCreated', 'desc');;
                    }
                    return state;
                case 'UPDATED':
                case 'COMPLETED': {

                    const index = state.findIndex(x => x.id === action.id);
                    if (index === -1) {
                        return orderBy([action, ...state], 'dateCreated', 'desc');
                    }
                    else {
                        const newState = [...state];
                        newState[index] = action;
                        return orderBy(newState, 'dateCreated', 'desc');
                    }
                }
                case 'DELETING':
                case 'DELETED': {
                    return state.filter(x => x.id !== action.id);
                }
                default:
                    throw new Error("UNKNOWN Status of reacher device command.");
            }
        }
    }

    useEffect((): (() => void) => {

        Get('/api/reacher/device').then((data: ReacherDevice[]) => {
            data.forEach(element => {
                deviceDispatch(element);
            });
        });

        Get('/api/reacher/device/command').then((data: GetCommandsResponse) => {
            deviceCommandDispatch(data.commands);
            data.alerts.forEach(alert => {
                alertDispatch(alert);
            })
        });

        const updateDeviceList = (deviceUpdate: ReacherDevice) => {
            deviceDispatch(deviceUpdate);
        };

        const updateDeviceCommands = (deviceCommandUpdate: ReacherDeviceCommand) => {
            deviceCommandDispatch(deviceCommandUpdate);
        }

        const updateDeviceStatus = (statusUpdates: ReacherDevice) => {
            deviceDispatch(statusUpdates);
        }

        const onAlertUpdated = (alert: ReacherDeviceCommand) => {
            alertDispatch(alert);
        }

        events(updateDeviceList, updateDeviceCommands, updateDeviceStatus, onAlertUpdated);

        return (): void => {
        }
    }, []);

    const SelectDevice = (deviceId: string): void => {
        const device: ReacherDevice | undefined = devices.find(x => x.id == deviceId);
        if (device !== undefined) {
            device.selected = true;
            device.status = "Updated";
            deviceDispatch(device);
        }
    }

    const DeSelectDevice = (deviceId: string): void => {
        const device: ReacherDevice | undefined = devices.find(x => x.id == deviceId);
        if (device !== undefined) {
            device.selected = false;
            device.status = "Updated";
            deviceDispatch(device);
        }
    }

    return (
        <ReacherContext.Provider value={{ devices, deviceCommands, SelectDevice, DeSelectDevice, alerts }}>
            {props.children}
        </ReacherContext.Provider>
    );

}

export default ReacherProvider;