import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAPI from "../Hooks/useAPI";
import useSettings from "../Hooks/useSettings";
import useViewPortDimensions from "../Hooks/useViewPort";
import { IDocConfig } from "../Models/IDocConfig";

const DocsContainer = () => {
    const { Get } = useAPI();
    const apiOptions = useSettings();
    const [searchParams, setSearchParams] = useSearchParams();
    const { viewPortHeight } = useViewPortDimensions();
    const fileid = searchParams.get("fileid")?.toLowerCase();
    const [docToken, setDocToken] = useState<IDocConfig>();
    const [editWarning, setEditWarning] = useState(false);

    useEffect(() => {
        fetchDetail();
    }, [apiOptions]);

    const fetchDetail = () => {
        if (!fileid) return;
        if (!apiOptions) return;

        Get(`${apiOptions?.files}/api/office/doctoken?fileid=${fileid}&genKey=true`)
            .then((res: IDocConfig) => {
                setDocToken(res);
        });
    }

    if (!fileid || !docToken) return <Skeleton sx={{ height: 1100 }}></Skeleton>

    const onDocumentReady = function (event: any) {
    };

    const onLoadComponentError = function (errorCode: any, errorDescription: any) {
        switch (errorCode) {
            case -1: // Unknown error loading component
                console.log(errorDescription);
                break;

            case -2: // Error load DocsAPI from http://documentserver/
                console.log(errorDescription);
                break;

            case -3: // DocsAPI is not defined
                console.log(errorDescription);
                break;
        }
    };

    return (
        <>
            {docToken &&
                <Snackbar open={editWarning} anchorOrigin={{ vertical: "top", horizontal: "center" }} data-testid="readonly-banner">
                    <Alert severity="error" sx={{ width: 500 }} variant="filled" elevation={6}>
                        {docToken.document.title} is read-only. You will not see live updates.
                    </Alert>
                </Snackbar>
            }
            <Box sx={{ height: viewPortHeight }} data-testid={`onlyOfficeContainer`}>
                {docToken && <DocumentEditor
                    id="editor"
                    documentServerUrl={apiOptions!.docs!}
                    config={docToken}
                    events_onDocumentReady={onDocumentReady}
                    onLoadComponentError={onLoadComponentError}
                />}
            </Box>
        </>
    )
}

export default DocsContainer;