import { Battery0Bar, Battery1Bar, Battery2Bar, Battery4Bar, Battery5Bar, Battery6Bar, BatteryFull } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Card, CardContent, CardMedia, Chip, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { blue, blueGrey, green, grey } from "@mui/material/colors";
import dayjs from 'dayjs';
import { useState } from "react";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { IApiResult } from "../../Models/IApiResult";
import { ReacherDeviceCommand } from "../Models/ReacherDeviceCommand";
import { LocationItem } from "./Components/FeedItems/Location";
import ReacherMediaAudio from "./Components/FeedItems/ReacherMediaAudio";
import ReacherMediaPicture from "./Components/FeedItems/ReacherMediaPicture";
import ReacherMediaVideo from "./Components/FeedItems/ReacherMediaVideo";

//TODO: Refactor magic strings
const ReacherFeedItem = (deviceCommand: ReacherDeviceCommand) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { Post } = useAPI();
    const { PushSuccess, PushError } = useNotifications();

    const canSave = ["PICTURE", "VIDEO", "AUDIO", "LOCATION"];

    const colours: Record<string, string> =
    {
        "CREATED": blueGrey[500],
        "UPDATED": blue[400],
        "COMPLETED": green[500]
    };

    const _colour = colours[deviceCommand.status.toUpperCase()];

    let MediaBox = <></>;
    //refactor this, need less magic strings and not happy with the pattern here.
    switch (deviceCommand.commandText) {
        case "ACCEPTTRIGGERED":
        case "INPUTTRIGGERED":
        case "POWERTRIGGERED":
        case "PINTRIGGERED":
        case "PICTURE":
            MediaBox = <ReacherMediaPicture {...deviceCommand} />;
            break;
        case "VIDEO":
            MediaBox = <ReacherMediaVideo deviceCommand={deviceCommand} fileId={deviceCommand.fileIds[0]} />;
            break;
        case "AUDIO":
            MediaBox = <ReacherMediaAudio deviceCommand={deviceCommand} fileId={deviceCommand.fileIds[0]} />;
            break;
        case "MOTIONTRIGGERED":
        case "NOTIFYTRIGGERED":
        case "LOCATION":
            MediaBox = <CardMedia sx={{ display: 'flex', flexDirection: 'row' }}><LocationItem {...deviceCommand} /></CardMedia>;
            break;
        case "MSG":
            MediaBox = <ReacherMediaMessage {...deviceCommand} />;
            break;
        case "ACCEPT":
        case "INPUT":
        case "MOTION":
        case "POWER":
        case "PIN":
        case "TEST":
        case "NOTIFY":
        case "TRACK":
            MediaBox = <ReacherMediaTrigger {...deviceCommand} />;
            break;
        case "STATUS":
            MediaBox = <ReacherContentDeviceState {...deviceCommand} />
            break;
        default:
            MediaBox = <></>;
    }

    const testIndicator = (deviceCommand.isTest && deviceCommand.command.toUpperCase() !== "TEST") ? <Chip color="info" label="test" sx={{ mr: 1 }} /> : <></>;


    const onMediaMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    }

    const onMediaMenuClose = (event: any) => {
        setAnchorEl(null);
        event.stopPropagation();
    }

    const menuAction = (event: any, command: ReacherDeviceCommand, action: string) => {
        switch (action) {
            case "SAVE":
                Post(`/api/reacher/data/save/${command.id}`, null)
                    .then((data: IApiResult) => {
                        if (data.success) {
                            PushSuccess(data.messages[0]);
                        }
                        else {
                            PushError(data.messages[0]);
                        }
                    })
                    .finally(() => onMediaMenuClose(event));
                break;
            default:
        }

        event.stopPropagation();
    };

    return (
        <Card elevation={6} key={deviceCommand.id} sx={{ mt: 0, mb: 1, p: 1, display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '10px', backgroundColor: _colour, height: 'auto', borderRadius: '3px' }}></div>

            <CardContent sx={{ flex: '1 0 auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignmentBaseline: 'bottom', mb: 1 }}>
                    <Typography component="div" variant="h6" sx={{ lineHeight: 1.2 }}>{deviceCommand.command}</Typography>
                    <Typography component="div" variant="overline" sx={{ ml: 1, lineHeight: 2.4 }}>{deviceCommand.parameters.length}</Typography>

                    <IconButton onClick={onMediaMenuOpen} sx={{ justifySelf: 'center', m: 0, ml: 'auto', p: '2px' }}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="menu-command"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={onMediaMenuClose}
                    >
                        <MenuItem disabled={!canSave.includes(deviceCommand.commandText) ||
                            deviceCommand.status.toUpperCase() !== "COMPLETED"} onClick={e => menuAction(e, deviceCommand, "SAVE")}>Save files</MenuItem>
                    </Menu>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                        <Typography component="div" variant="subtitle1">{testIndicator}{deviceCommand.deviceName}</Typography>
                        {deviceCommand.command.toLowerCase().indexOf("triggered") > -1 ?
                            (
                                <>
                                    <Typography component="div" variant="subtitle2">Triggered {dayjs(deviceCommand.dateCreated).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                                    <Typography component="div" variant="subtitle2">Acknowledged {dayjs(deviceCommand.acknowledgedDate).format("DD-MM-YYYY HH:mm:ss")} by {deviceCommand.acknowledgedBy}</Typography>
                                </>
                            )
                            :
                            (
                                <Typography component="div" variant="subtitle2">{dayjs(deviceCommand.lastUpdated).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                            )}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', ml: 'auto' }}>
                        {MediaBox}
                    </Box>
                </Box>
            </CardContent>


        </Card>
    )
}

interface TriggerStateProps {
    name: string,
    state: boolean
}

const TriggerState = ({ name, state }: TriggerStateProps) => {
    const style = { flexGrow: 1, borderRadius: 0, width: '70px' };
    return state ? <Chip sx={style} label={name} color="success" /> : <Chip sx={style} label={name} color="error" />
}

const ReacherContentDeviceState = (deviceCommand: ReacherDeviceCommand) => {


    const GetBattery = (batteryLevel: number) => {

        const style = { transform: 'rotate(90deg)', mr: 1 };
        let batteryIcon = <BatteryFull sx={{ style }} />

        if (batteryLevel < 5) {
            batteryIcon = <Battery0Bar sx={style} />
        }
        else if (batteryLevel >= 5 && batteryLevel < 20) {
            batteryIcon = <Battery1Bar sx={style} />
        }
        else if (batteryLevel >= 20 && batteryLevel < 40) {
            batteryIcon = <Battery2Bar sx={style} />
        }
        else if (batteryLevel >= 40 && batteryLevel < 60) {
            batteryIcon = <Battery4Bar sx={style} />
        }
        else if (batteryLevel >= 60 && batteryLevel < 80) {
            batteryIcon = <Battery4Bar sx={style} />
        }
        else if (batteryLevel >= 80 && batteryLevel < 90) {
            batteryIcon = <Battery5Bar sx={style} />
        }
        else if (batteryLevel >= 90 && batteryLevel < 100) {
            batteryIcon = <Battery6Bar sx={style} />
        }
        else {
            batteryIcon = <BatteryFull sx={style} />
        }
        //{ flexGrow: 1, borderRadius: 0, width: '70px' }
        return <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: '', alignItems: '', flexGrow: 1, borderRadius: 0, width: '70px' }}>{batteryIcon} {batteryLevel}%</Box>

    }

    if (deviceCommand.deviceState == null) return <></>

    return (<Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto 0' }}>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {GetBattery(deviceCommand.deviceState.batteryLevel)}
            <TriggerState name={"Track"} state={deviceCommand.deviceState.track} />
            <TriggerState name={"Accept"} state={deviceCommand.deviceState.accept} />
            <TriggerState name={"Input"} state={deviceCommand.deviceState.input} />
            <TriggerState name={"Motion"} state={deviceCommand.deviceState.motion} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: '', alignItems: '', flexGrow: 1, borderRadius: 0, width: '70px' }}></Box>
            <TriggerState name={"Notify"} state={deviceCommand.deviceState.notify} />
            <TriggerState name={"Power"} state={deviceCommand.deviceState.power} />
            <TriggerState name={"PIN"} state={deviceCommand.deviceState.pin} />
            <TriggerState name={"Test"} state={deviceCommand.deviceState.test} />
        </Box>
    </Box>
    )
}

//TODO: Refactor these, a lot of repeated code. 

const ReacherMediaMessage = (deviceCommand: ReacherDeviceCommand) => {
    return <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto 0' }}>
        <Typography sx={{ width: '400px', height: 100, overflowY: 'auto', scrollbarWidth: 'thin', border: `1px solid ${grey[900]}`, background: grey[800], padding: '4px' }} variant="body2" >{deviceCommand?.parameters?.text}</Typography>
    </Box>
}

const ReacherMediaTrigger = (deviceCommand: ReacherDeviceCommand) => {
    return <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto 0' }}>
        {deviceCommand?.parameters?.state == 'true' ? <Chip sx={{ borderRadius: 0 }} label="Armed" color="success" /> : <Chip sx={{ borderRadius: 0 }} label="Disarmed" color="error" />}
    </Box>
}


export default ReacherFeedItem;