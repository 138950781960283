import { Box, Button, Card, CardContent, Link, Skeleton, Typography } from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { useContext } from "react";
import useAPI from "../../Hooks/useAPI";
import { ReacherDeviceCommand } from "../Models/ReacherDeviceCommand";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import { LocationItemSkeleton } from "./Components/FeedItems/Location";
import ImageThumbnail from "./Components/Modals/ImageThumbnail";

const AlertFeed = () => {

    const { alerts } = useContext(ReacherContext) as ReacherContextType;
    const { Post } = useAPI();

    if (alerts.length == 0) {
        return <></>
    }

    const onAckClicked = (commandId: string) => {
        var body = {
            commandId
        };
        Post('/api/reacher/device/acknowledge', body);
    }

    const alertContent = (alert: ReacherDeviceCommand) => {

        const commandType = alert.command.substring(0, alert.command.indexOf(':')).toUpperCase();
        switch (commandType) {
            case 'MOTION':
            case 'NOTIFY':
                if (!alert.latitude) return <LocationItemSkeleton />;
                return (<Link href={`https://www.google.com/maps/search/?api=1&query=${alert?.latitude}%20${alert?.longitude}`} target="_blank" rel="noreferrer" underline="hover" color={grey[50]}>
                    <Typography component="div" variant="subtitle2">Lat: {alert?.latitude}</Typography>
                    <Typography component="div" variant="subtitle2">Lng: {alert?.longitude}</Typography>
                    <Typography component="div" variant="subtitle2">Time: {dayjs(alert?.timeCaptured).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                </Link>);
            default:
                if (!alert.fileIds?.length) return <Skeleton variant="rectangular" width={100} height={100}></Skeleton>;
                return alert.fileIds.map((f) => <ImageThumbnail DeviceCommandId={alert.id} imageId={f} key={f} />);
        }

    }

    const alertItems = alerts.map(alert => {

        const background = alert.isTest ? blue[900] : red[900];

        return (
            <Card elevation={6} key={alert.id} sx={{ mt: 0, mb: 1, display: 'flex', backgroundColor: background }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">{alert.command}</Typography>
                        <Typography component="div" variant="subtitle1">{alert.deviceName}</Typography>
                        <Typography component="div" variant="subtitle2">{dayjs(alert.lastUpdated).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                    </CardContent>
                    {alertContent(alert)}
                    <Button variant="contained" sx={{ borderRadius: 0, m: 0, ml: 1, height: '100%', backgroundColor: red[700] }} color="error" onClick={() => onAckClicked(alert.id)}>Acknowledge</Button>
                </Box>

            </Card>
        );
    });

    return <div>{alertItems}</div>
}

export default AlertFeed;