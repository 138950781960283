import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PageTemplate from "../../Common/Page";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { INomadLink } from "../../Models/INomadLink";

const LinkManager = () => {

    const { PushSuccess } = useNotifications();
    const [links, setLinks] = useState<INomadLink[]>([]);
    const { Get, Delete } = useAPI();

    useEffect(() => {
        getLinks();
        
    }, []);

    const getLinks = () => {
        Get('/api/nomad/linkmanager')
        .then((data: INomadLink[]) => {
            setLinks(data);
        })

    }

    const deleteLink = (id: string | null) => {
        if (id === null) return;

        Delete("/api/nomad/linkmanager/" + id)
            .then(() =>{
                PushSuccess("Link Deleted");
                getLinks();
             });
    }

    return (
   
        <PageTemplate heading="Link Manager" subtitle="Configuration of the external links found under Tools in the navigation menu." >
            
            <Box component={Paper} elevation={6} sx={{p:1}}>
                <Button component={NavLink} to={`/settings/links/create`} variant="contained">Create New Link</Button>
            </Box>
                <TableContainer component={Paper} sx={{my:2}} elevation={6}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {links.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.linkText}</TableCell>
                                    <TableCell>{row.linkUrl}</TableCell>
                                    <TableCell align="right">
                                        <Button component={NavLink} to={`/settings/links/${row.id}`} variant="contained" sx={{ mr: 1 }}>Edit</Button>
                                        <Button variant="contained" color="error" onClick={e => deleteLink(row.id)} >Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </PageTemplate>
    )
}

export default LinkManager;