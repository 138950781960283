import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PageTemplate from "../Common/Page";
import useAPI from "../Hooks/useAPI";
import useNotifications from "../Hooks/useNotifications";
import { IApiResult } from "../Models/IApiResult";
import { ITripwireEventSource } from "../Models/ITripwireEventSource";

const EventSourcesCreate = () => {

    const { Post } = useAPI();
    const [source, setSource] = useState<ITripwireEventSource>({id:'00000000-0000-0000-0000-000000000000', eventSource:'', verifyString: '', active:false});
    const { PushSuccess, PushError } = useNotifications();
    const navigate = useNavigate();

    const onChangeEventSource = (e: any) => {
        if (source === null) return;
        const newState:ITripwireEventSource = { ...source, eventSource: e.target.value };
        setSource(newState);
    }

    const onChangeVerifyString = (e: any) => {
        if (source === null) return;
        const newState: ITripwireEventSource = { ...source, verifyString: e.target.value };
        setSource(newState);
    }

    const onChangeActive = (e: any) => {
        if (source === null) return;
        const newState: ITripwireEventSource = { ...source, active: e.target.checked };
        setSource(newState);
    }

    const onSubmit = () => {
        console.log(source);
        Post('/api/tripwire/EventSources', source)
        .then((data:IApiResult) => {
            if (data.success) {
                PushSuccess(data.messages[0]);
                navigate("/tripwire/triggercredentials");
            }
            else {
                PushError(data.messages[0]);
            }
        });
    }

    const componentStyle = {
        mt: 2
    };

    return (
        <PageTemplate heading="Create Trigger Credentials" subtitle=""> 
            <Paper elevation={6} sx={{p:2, mt:2}}>
                <FormControl>
                    <FormGroup sx={componentStyle}>
                        <TextField label="Username" variant="outlined" value={source?.eventSource} onChange={onChangeEventSource} required />
                    </FormGroup>
                    <FormGroup sx={componentStyle}>
                        <TextField label="Password" variant="outlined" value={source?.verifyString} onChange={onChangeVerifyString} required />
                    </FormGroup>
                    <FormGroup sx={componentStyle}>
                        <FormControlLabel control={<Checkbox checked={source?.active} onChange={onChangeActive} />} label="Active" />
                    </FormGroup>
                    <FormGroup sx={{mt:2, dispaly:'flex', flexDirection:'row'}}>
                        <Button variant="contained" onClick={onSubmit} sx={{mr:2}}>Create Credentials</Button>
                        <Button component={NavLink} to={"/tripwire/triggercredentials"} variant="outlined">Cancel</Button>
                    </FormGroup>
                </FormControl>
            </Paper>

        </PageTemplate>
    );

}

export default EventSourcesCreate;