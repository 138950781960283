import { CardMedia, Skeleton } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import { Buffer } from 'buffer';
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { ReacherDeviceCommand } from "../../../Models/ReacherDeviceCommand";

const ReacherMediaVideo = (props: { deviceCommand: ReacherDeviceCommand, fileId: string }) => {

    const [data, setData] = useState("");
    const auth = useAuth();

    var config: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${auth.user?.access_token}`,
            "Content-Type": "application/json"
        },
        responseType: "arraybuffer"
    };

    const fetchData = () => {
        axios.get(`/api/reacher/data/download?DeviceCommandId=${props.deviceCommand.id}&fileId=${props.fileId}`, config)
        .then(r => {
            let d = Buffer.from(r.data).toString('base64');
            setData(`data:${r.headers['content-type']};base64, ${d}`)
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.deviceCommand.status]);
    

        const media = () => {
            if(props.deviceCommand.status.toLocaleUpperCase() !== "COMPLETED") {
                return <Skeleton variant="rectangular" width={220} height={120}></Skeleton>
            }

            if(!data) return <video preload="metadata" controls width={220} height={120} style={{background:'black'}} />

            return video_memo;
        }

    const video_memo = useMemo(() => {
        
        return <video preload="metadata" controls width={220} height={120} style={{ background: 'black' }}>
                <source src={data} />
            </video>;

        
    }, [data]);

    return <CardMedia sx={{ display: 'flex', flexDirection: 'row' }}>
            {media()}
</CardMedia>;
}

export default ReacherMediaVideo;