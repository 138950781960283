import BackFolderIcon from '@mui/icons-material/DriveFolderUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { useAuth } from 'react-oidc-context';
import { useNavigate } from "react-router-dom";
import useAPI from "../Hooks/useAPI";
import useNotifications from "../Hooks/useNotifications";
import useViewPortDimensions from "../Hooks/useViewPort";
import { FulcrumFile, IFile } from '../Models/IFile';
import ImageViewer from "../Reacher/DeviceCommander/Components/Modals/ImageViewer";
import { ImageViewerProps } from '../Reacher/DeviceCommander/Components/Modals/ImageViewerProps';
import CreateMenu from "./Components/CreateMenu";
import FileItem from "./Components/FileItem";
import { FulcrumContext, FulcrumContextType } from './FulcrumContext';

const FileContainer = () => {

    const {
        apiOptions,
        loadingState,
        folder,
        ChangeFolder,
        users,
        groups
    } = useContext(FulcrumContext) as FulcrumContextType;

    const { viewPortHeight } = useViewPortDimensions();
    const { Get } = useAPI();
    const { PushError } = useNotifications();
    const nav = useNavigate();
    const auth = useAuth();
    const userId = auth.user?.profile.sub ? auth.user?.profile.sub : "";

    const [imageViewerProps, setImageViewerProps] = useState<ImageViewerProps>({ imageUrl: "", isOpen: false, handleClose: OnImageClosed, canDownload: false });

    useEffect(() => {
        if (!apiOptions) return;

        if (apiOptions?.files) {
            const handleNav = (e: any) => {
                let targetFolder = '00000000-0000-0000-0000-000000000000';
                if (e.state && e.state.usr) {
                    targetFolder = e.state.usr.folderId;
                }
                ChangeFolder(targetFolder);
            }

            window.addEventListener('popstate', handleNav);
            return () => {
                window.removeEventListener('popstate', handleNav);
            }
        }

    }, [apiOptions]);

    const openFileFolder = (item: IFile) => {
        if (item.isFolder) {
            if (new FulcrumFile(item)?.hasPermission('open', userId)) {
                nav(`/fulcrum/files`, { state: { folderId: item.id } });
                ChangeFolder(item.id);
            }
        } else if (item.editor === "image") {
            if (new FulcrumFile(item)?.hasPermission('open', userId))
                OnImageClicked(item.id, new FulcrumFile(item)?.hasPermission('download', userId));
        } else if (item.editor) {
            if (new FulcrumFile(item)?.hasPermission('open', userId) || new FulcrumFile(item)?.hasPermission('edit', userId))
                nav(`/fulcrum/docs?fileid=${item.id}`, { state: { folderId: item.id } });
        }
    }

    const onFolderUp = () => {
        ChangeFolder(folder?.parentId!);
    }

    if (loadingState.state !== "DONE") {

        const hasErrored: boolean = loadingState.state === "ERROR";
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: viewPortHeight }}>
                <Box sx={{ position: 'relative' }}>
                    <Fab sx={{
                        ...(hasErrored && {
                            bgcolor: red[800],
                            '&:hover': {
                                bgcolor: red[900]
                            }
                        })
                    }}>
                        <InsertDriveFileIcon />
                    </Fab>
                    {!hasErrored &&
                        <CircularProgress size={68} sx={{
                            position: 'absolute',
                            top: -6,
                            left: -6

                        }} />
                    }
                </Box>
                <Typography variant="h6" mt={2}>{loadingState.message}</Typography>
            </Box>
        );
    }

    const cellSx = {
        p: 1
    };

    const OnImageClicked = async (fileId: string, canDownload: boolean) => {
        const response = await Get(`${apiOptions?.files}/api/Files/Token?FileId=${fileId}`);
        if (response.success) {
            const imageUrl = `${apiOptions?.files}/api/Files/download?token=${response.token}`;
            setImageViewerProps({ ...imageViewerProps, imageUrl, isOpen: true, refreshURL: refreshFileURL, canDownload: canDownload });
        }
        else {
            PushError("Unable to open image");
            //We should not have anything set in the props, but just be safe
            OnImageClosed();
        }
    }

    const refreshFileURL = async (url: string): Promise<string> => {
        const params = new URLSearchParams(url.substring(url.indexOf('?')));
        const token = params.get('token');
        const response = await Get(`${apiOptions?.files}/api/Files/token-refresh?token=${token}`);
        return `${apiOptions?.files}/api/Files/download?token=${response.token}`;
    }

    function OnImageClosed() {
        setImageViewerProps({ ...imageViewerProps, imageUrl: "", isOpen: false });
    }

    return (
        <Box component={Paper} sx={{ p: 2 }} onContextMenu={e => { e.preventDefault(); return false; }}>
            <Box >
                <CreateMenu OnFileUploadComplete={() => ChangeFolder(folder?.id!)} CurrentFolderId={folder?.id!} CurrentFiles={folder?.children || []} data-testid={`createMenu`} />
            </Box>
            <TableContainer component={Paper} elevation={4} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Owner</TableCell>
                            <TableCell align="right">Size</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={cellSx}>
                                <Box onClick={() => onFolderUp()} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    }
                                }} data-testid={`parentFolder`}>
                                    <IconButton sx={{ mr: 1 }} size="small" disabled={!folder?.path}><BackFolderIcon fontSize="small" /></IconButton>
                                    <Typography>{folder?.path}/..</Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={cellSx} align="right"></TableCell>
                            <TableCell sx={cellSx} align="right"></TableCell>
                            <TableCell sx={cellSx} align="right"></TableCell>
                        </TableRow>
                        {folder?.children.map(f => {
                            return <FileItem key={f.id} Item={f} OnOpenItem={openFileFolder} OnImageClicked={OnImageClicked} Users={users || []} Groups={groups || []} RefreshItems={() => ChangeFolder(folder?.id)} Siblings={f.children} />
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            {imageViewerProps.isOpen &&
                <ImageViewer {...imageViewerProps} />
            }

        </Box>
    )
}

export default FileContainer;