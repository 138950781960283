import { Box, Grid,} from "@mui/material";
import useWindowDimensions from "../../Hooks/useWindowDimension";
import AlertFeed from "./AlertFeed";
import CommandPanel from "./CommandPanel";
import DevicePanel from "./DevicePanel";
import ReacherFeed from "./ReacherFeed";
import useViewPortDimensions from "../../Hooks/useViewPort";

const DeviceCommanderContainer = () => {

    const { viewPortHeight } = useViewPortDimensions();

    return (
        <Box sx={{ height: viewPortHeight}}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', flex:'1 1 1px' }}>
                    <DevicePanel />
                    <CommandPanel />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <AlertFeed />
                    <ReacherFeed />
                </Grid>
            </Grid>
        </Box>
    );
}

export default DeviceCommanderContainer;