import { Box, Button, Paper } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { TripwireRoles } from "../../../constants/TripwireRoles";
import PageTemplate from "../Common/Page";
import useAPI from "../Hooks/useAPI";
import useRoles from "../Hooks/useRoles";
import useViewPortDimensions from "../Hooks/useViewPort";
import { ITripwireEventSource } from "../Models/ITripwireEventSource";

const EventSources = () => {

    const isAdmin = useRoles([TripwireRoles.Admin]);
    const [eventSources, setEventSources] = useState<ITripwireEventSource[]>([]);
    const { templatePageHeight } = useViewPortDimensions();
    const { Get } = useAPI();

    useEffect(() => {
        Get('/api/tripwire/EventSources')
            .then(data => setEventSources(data));
    }, []);

    const columns: GridColDef[] = isAdmin ? [
        { field: 'eventSource', headerName: 'Username', flex: 1 },
        { field: 'verifyString', headerName: 'Password', flex: 1 },
        { field: 'active', headerName: 'Source Active', flex: 1 },
        {
            field: 'action',
            headerName: '',
            sortable: false,
            renderCell: (params) => {
                return <Button disabled={!isAdmin} component={NavLink} to={'/tripwire/eventsources/' + params.id} variant="contained">Edit</Button>
            }
        }
    ]
    :
    [{ field: 'eventSource', headerName: 'Username', flex: 1 },
    { field: 'verifyString', headerName: 'Password', flex: 1 },
    { field: 'active', headerName: 'Source Active', flex: 1 }
    ];

    return (
        <PageTemplate heading="Trigger Credentials" subtitle="Trigger credentials are used to validate incoming pocket litter requests.">
            <>
            {isAdmin && <Paper elevation={6} sx={{ mt: 2, mb: 2, p: 2 }}>
                <Button disabled={!isAdmin} component={NavLink} to={'/tripwire/eventsources/create'} variant="contained">Create Credentials</Button>
            </Paper>}
            

            <Box sx={{ height: templatePageHeight - 110, width: '100%' }} component={Paper} elevation={6}>

                <DataGridPro
                    rows={eventSources}
                    columns={columns}
                    pageSize={10}
                />

            </Box>
            </>
        </PageTemplate>
    );
}

export default EventSources;