import { ProtectedRoute } from "./components/Secure/Common/ProtectedRoute";
import { TripwireRoles } from "./constants/TripwireRoles";

import LicensePage from "./components/Secure/About/Licenses";
import EventFeed from "./components/Secure/EventFeed/EventFeed";
import LogContainer from "./components/Secure/LogReader/LogContainer";
import DeviceCommanderContainer from "./components/Secure/Reacher/DeviceCommander/DeviceCommanderContainer";
import DeviceManagerContainer from "./components/Secure/Reacher/DeviceManager/DeviceManagerContainer";
import ReacherProvider from "./components/Secure/Reacher/ReacherContext";
import LinkManagerCreate from "./components/Secure/Settings/LinkManager/LinkManagerCreate";
import LinkManagerEdit from "./components/Secure/Settings/LinkManager/LinkManagerEdit";
import LinkManagerList from "./components/Secure/Settings/LinkManager/LinkManagerList";
import EventSources from "./components/Secure/Tripwire/EventSources";
import EventSourcesCreate from "./components/Secure/Tripwire/EventSourcesCreate";
import EventSourcesEdit from "./components/Secure/Tripwire/EventSourcesEdit";
import UserManagerCreate from "./components/Secure/Settings/UserManager/UserManagerCreate";
import UserManagerEdit from "./components/Secure/Settings/UserManager/UserManagerEdit";
import UserManagerList from "./components/Secure/Settings/UserManager/UserManagerList";
import FileContainer from "./components/Secure/Fulcrum/FileContainer";
import DocsContainer from "./components/Secure/Fulcrum/DocsContainer";
import FulcrumProvider from "./components/Secure/Fulcrum/FulcrumContext";
import LogProvider from "./components/Secure/LogReader/LogContext";
import CreateUserGroup from "./components/Secure/Settings/UserManager/CreateUserGroup";
import MapContainer from "./components/Secure/Atlas/MapContainer";
import GeoDataUpdateProvider from "./components/Secure/Atlas/GeoDataUpdateContext";

const AppRoutes = [
    {
        index: true,
        element: <ProtectedRoute roles={[]}><EventFeed /></ProtectedRoute>
    },
    {
        path: "/logs",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><LogProvider><LogContainer /></LogProvider></ProtectedRoute>
    },
    {
        path:"/nomad/licenses",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><LicensePage /></ProtectedRoute>
    },
    {
        path:"/tripwire/triggercredentials",
        element: <ProtectedRoute roles={[]}><EventSources /></ProtectedRoute>
    },
    {
        path: "/tripwire/eventsources/create",
        element: <ProtectedRoute roles={[]}><EventSourcesCreate /></ProtectedRoute>
    },
    {
        path: "/tripwire/eventsources/:id",
        element: <ProtectedRoute roles={[]}><EventSourcesEdit /></ProtectedRoute>
    },
    {
        path: "/reacher/commands",
        element: <ProtectedRoute roles={[]}><ReacherProvider><DeviceCommanderContainer /></ReacherProvider></ProtectedRoute>
    },
    {
        path: "/reacher/devices",
        element: <ProtectedRoute roles={[]}><ReacherProvider><DeviceManagerContainer /></ReacherProvider></ProtectedRoute>
    },
    {
        path: "/settings/links",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><LinkManagerList /></ProtectedRoute>
    },
    {
        path:"/settings/links/create",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><LinkManagerCreate /></ProtectedRoute>
    },
    {
        path:"/settings/links/:id",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><LinkManagerEdit /></ProtectedRoute>
    },
    {
        path: "/settings/users",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><UserManagerList /></ProtectedRoute>
    },
    {
        path: "/settings/users/create",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><UserManagerCreate /></ProtectedRoute>
    },
    {
        path: "/settings/users/:id",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><UserManagerEdit /></ProtectedRoute>
    },
    {
        path: "/settings/users/groups/create",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><CreateUserGroup /></ProtectedRoute>
    },
    {
        path: "/settings/users/groups/:groupid",
        element: <ProtectedRoute roles={[TripwireRoles.Admin]}><CreateUserGroup /></ProtectedRoute>
    },
    {
        path: "/fulcrum/files",
        element: <ProtectedRoute roles={[]}><FulcrumProvider><FileContainer></FileContainer></FulcrumProvider></ProtectedRoute>
    },
    {
        path: "/fulcrum/docs",
        element: <ProtectedRoute roles={[]}><DocsContainer></DocsContainer></ProtectedRoute>
    },
    {
        path: "/atlas/map",
        element: <ProtectedRoute roles={[TripwireRoles.Admin,TripwireRoles.AtlasUser]}><GeoDataUpdateProvider><MapContainer /></GeoDataUpdateProvider></ProtectedRoute>
    }
];

export default AppRoutes;
