import useAPI from "../../Hooks/useAPI";
import { Box, Card, Button } from "@mui/material";
import { ReacherDevice } from "../Models/ReacherDevice";
import { blue, green, red } from "@mui/material/colors";
import { ReacherConstants } from "../../../../constants/ReacherConstants";
import { IApiResult } from "../../Models/IApiResult";
import useNotifications from "../../Hooks/useNotifications";

const PendingDevice = (props: { dev: ReacherDevice }) => {

    const { Put } = useAPI();
    const { PushSuccess, PushError } = useNotifications();

    const approveDevice = (device: ReacherDevice) => {
        Put('/api/reacher/device/regchange', { DeviceId: device.id, NewState: ReacherConstants.DeviceRegistrationStates.Approved })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
    };

    const rejectDevice = (device: ReacherDevice) => {
        Put('/api/reacher/device/regchange', { DeviceId: device.id, NewState: ReacherConstants.DeviceRegistrationStates.Rejected })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
    };

    return <Card sx={{ display: 'flex', my: 1, p: 0 }} elevation={6} >
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center', p: 0 }}>
            <div style={{ height: '100%', width: '8px', backgroundColor: green[500], content: ' ' }}></div>
            <p style={{ flexGrow: 1, padding: 0, margin: 0, marginLeft: 10 }}>{props.dev.androidDeviceName}</p>
            <Box sx={{ p: 1 }}>
                <Button size="small" onClick={() => approveDevice(props.dev)} variant='contained' color="success">Approve</Button>
                <Button size="small" onClick={() => rejectDevice(props.dev)} variant='contained' color="error" sx={{ ml: 1 }}>Reject</Button>
            </Box>
        </Box>
    </Card>
}

export default PendingDevice;