import { Alert, Box, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimension";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import Device from "./Device";
import { ReacherDevice } from "../Models/ReacherDevice";
import DevicePanelFilters from "./DeviceFilter";
import { orderBy } from "lodash";
import { ReacherConstants } from "../../../../constants/ReacherConstants";
import useViewPortDimensions from "../../Hooks/useViewPort";

const DevicePanel = () => {

    //This is not owned by the component, it will be recived by DevicePanelFilters but we need a copy to store 
    //so we can filter devices if the device list is updated.
    const [filter, setFilter] = useState<string>("");
    const [allSelected, setAllSelected] = useState<boolean>(true);

    const { devices, SelectDevice, DeSelectDevice } = useContext(ReacherContext) as ReacherContextType;
    const { viewPortHeight } = useViewPortDimensions();
    const [filteredDevices, setFilteredDevices] = useState<ReacherDevice[]>([]);

    const deviceList = orderBy(filteredDevices, ['alive', 'androidDeviceName', 'friendlyDeviceName'] , 'desc').map(x => {
        return <Device {...x} key={x.id} />
    })

    useEffect(() => {
        ApplyFilters(filter);
    }, [devices, filter]);

    useEffect(() => {
        filteredDevices.forEach((device) => {
            if (allSelected)
                SelectDevice(device.id);
            else
                DeSelectDevice(device.id);
        })

    }, [allSelected]);

    const ApplyFilters = (deviceFilter: string) => {
        let filterText = deviceFilter.toUpperCase();
        const _devices = devices
            .filter(x => x.androidDeviceName?.toUpperCase().indexOf(filterText) > -1 || x.friendlyDeviceName?.toUpperCase().indexOf(filterText) > -1)
            .filter(x => x.registrationState == ReacherConstants.DeviceRegistrationStates.Approved);
        setFilteredDevices(_devices);
    }
    
    return (
        <Box component={Paper} elevation={2} sx={{ p: 1, overflowY: 'auto', minHeight: 0,  scrollbarWidth: 'thin' }}>
            <DevicePanelFilters onFilterChanged={f => setFilter(f)} onSelectAll={s => setAllSelected(s)} />
            {devices.length == 0 &&
                <Alert variant="filled" severity="info">No devices registered</Alert>}
            <Box sx={{height:viewPortHeight-490, overflowY:'scroll',scrollbarWidth: 'thin'}}>
            {deviceList}
            </Box>
        </Box>
    );
}

export default DevicePanel;