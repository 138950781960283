import { ReactElement } from "react";
import useViewPortDimensions from "../Hooks/useViewPort";
import { Box, CircularProgress, Fab, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

 export interface IFancyLoaderProps {
    error:boolean,
    message: string,
    icon: ReactElement,
    errorElement?: ReactElement
}

const FancyLoader = ({error, message, icon, errorElement}:IFancyLoaderProps) => {

    const { viewPortHeight } = useViewPortDimensions();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: viewPortHeight }}>
        <Box sx={{ position: 'relative' }}>
            <Fab
                sx={{
                    ...(error && {
                        bgcolor: red[800],
                        '&:hover': {
                            bgcolor: red[900]
                        }
                    })
                }}
            >
               {icon}
            </Fab>
            {!error &&
                <CircularProgress size={68} sx={{
                    position: 'absolute',
                    top: -6,
                    left: -6

                }} />
            }
        </Box>
            <Typography variant="h6" mt={2}>{message}</Typography>
            {errorElement }
    </Box>
    )
}

export default FancyLoader;