import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Loading from "../Common/Loading";
import PageTemplate from "../Common/Page";
import useNotifications from "../Hooks/useNotifications";


interface ILicenseData {
    timestamp: number,
    dependencies: IPackage[]
}

interface IPackage {
    name: string,
    version: string,
    license: string,
    license_content: string
}

const LicensePage = () => {

    const { PushError } = useNotifications();
    const [licenseData, setLicenseData] = useState<ILicenseData | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentPackage, setCurrentPackage] = useState<IPackage | null>(null);


    const handleOpen = (selectedPackage: IPackage) => {
        setModalOpen(true);
        setCurrentPackage(selectedPackage);
    }

    const handleClose = () => {
        setModalOpen(false);
        setCurrentPackage(null);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: '75%',
        overflow:'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {

        fetch('package-licenses.json')
            .then(res => res.json())
            .then(data => setLicenseData(data))
            .catch((err) => {
                PushError('Unable to find license data');
            });

    }, []);

    const pageTitle = "Overwatch License information"
    if (licenseData === null) {
        return <PageTemplate heading={pageTitle} subtitle=''><Loading /></PageTemplate>
    }

    const date = new Date(licenseData.timestamp);
    return (

        <PageTemplate heading={pageTitle} subtitle={`License information generated ${date.toDateString()}`}>
            <TableContainer component={Paper} elevation={6}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Package</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>License</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenseData.dependencies.map(row => (

                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.version}</TableCell>
                                <TableCell>{row.license}</TableCell> 
                                <TableCell>{row.license_content !== null && <Button variant="contained" onClick={() => handleOpen(row)}>view</Button>}</TableCell>
                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component={Paper} elevation={9}>
                   <Typography sx={{textAlign:'center'}} variant="h5">{currentPackage?.name}@{currentPackage?.version}</Typography>
                   <pre>{currentPackage?.license_content}</pre>
                </Box>
            </Modal>
        </PageTemplate>


    );
}

export default LicensePage;