import CircleIcon from '@mui/icons-material/Circle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PolylineIcon from '@mui/icons-material/Polyline';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material";
import useViewPortDimensions from "../../Hooks/useViewPort";
import { EditMapDrawerOptions } from "./EditMapDrawerOptions";
import React, { useRef, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';


interface IDialogFormState {
    isOpen: boolean
}

interface UserData {
    id: string,
    label: string,
    color: string
}

const EditMap = (props: EditMapDrawerOptions) => {

    const defaultState = { isOpen: false };
    const [formState, setFormState] = useState<IDialogFormState>(defaultState);
    const [userData, setUserData] = useState<UserData>({ id: "", label: "", color: "#000" });
    const { viewPortHeight } = useViewPortDimensions();

    const icon = (geotype: string) => {
        switch (geotype) {
            case "Point":
                return <CircleIcon />
            case "Polygon":
            case "LineString":
                return <PolylineIcon />
            default:
        }
    }

    const onEditClicked = (feature: any) => {

        const id = feature.properties.UserDataId;
        const label = feature.properties.label !== undefined ? feature.properties.label : id;
        const color = feature.properties.fillColour !== undefined ? feature.properties.fillColour : "#000";

        setFormState({ ...formState, isOpen: true });
        setUserData({ ...userData, id: id, label: label, color: color });
    }

    const onDialogClosed = () => {
        setFormState({ ...formState, isOpen: false });
    }

    const onEditConfirmed = () => {
        props.onUpdateData(userData.id, userData.label, userData.color);
        onDialogClosed();
    }

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let value = (e.target as HTMLInputElement).value;
        setUserData({ ...userData, label: value });
    }

    const onColourChanged = debounce((colourHex: string) => {
        setUserData({ ...userData, color: colourHex })
    }, 100);


    const onKeyUp = () => (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            onEditConfirmed();
        }
    }

    const itemList = useMemo(() => {

        return (
            <List component="div" disablePadding>
                {props.data.features.map((f: any) => <DrawerItem key={f.properties.UserDataId} feature={f} onDelete={props.onDeleteData} onPan={props.onPanTo} onEdit={onEditClicked} />)}
            </List>
        );

    }, [props.data])

    return (
        <Drawer
            title={props.title}
            anchor="right"
            open={props.isOpen}
            onClose={e => props.onClose(e)}
            PaperProps={{ sx: { width: 420 } }} >
            <Paper>
                <Box sx={{ height: viewPortHeight + 84 }}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={props.title}
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                    </List>
                    {itemList}
                </Box>
            </Paper>
            <Dialog
                open={formState?.isOpen}
                onClose={onDialogClosed}
            >
                <DialogTitle>Update Feature</DialogTitle>
                <DialogContent>
                    <TextField sx={{ mt: 1 }} autoFocus fullWidth onKeyUp={onKeyUp} onChange={onValueChange} value={userData.label} label='Label' data-testid={`dialogvalue`} />
                    <ColourPicker hex={userData.color} onUpdate={onColourChanged} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={onDialogClosed}>Cancel</Button>
                    <Button variant='contained' onClick={onEditConfirmed}>Update</Button>
                </DialogActions>
            </Dialog>
        </Drawer>
    );
}

export interface IColourPickerProps {
    hex: string,
    onUpdate: (hex: string) => void
}

export const ColourPicker = ({ hex, onUpdate }: IColourPickerProps) => {

    const inputRef = useRef<HTMLInputElement | null>(null);


    const update = (e: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate(e.target.value);
    }

    return (
        <Box sx={{
            my: 1
        }}>
            <input type='color' ref={inputRef} value={hex} onChange={update} style={{ display: 'none' }} data-testid="atlas_feature_colourpicker_input" />
            <Box
                data-testid="atlas_feature_colourpicker_current"
                onClick={() => inputRef?.current?.click()}
                sx={{
                    width: '100%',
                    height: 25,
                    backgroundColor: hex,
                    border: '1px solid white',
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }} >

            </Box>
        </Box>
    )

}

interface IDrawItemProps {
    feature: any,
    onEdit: (feature: any) => void,
    onDelete: (featureId: string) => void,
    onPan: (featureId: string) => void
}

const DrawerItem = ({ feature, onEdit, onDelete, onPan }: IDrawItemProps) => {
    const id = feature.properties.UserDataId;
    const canEdit = !feature.properties.SourceType;
    const label = feature.properties.label !== undefined ? feature.properties.label : id;

    const icon = (geotype: string) => {
        switch (geotype) {
            case "Point":
                return <CircleIcon />
            case "Polygon":
            case "LineString":
                return <PolylineIcon />
            default:
        }
    }

    return (
        <ListItemButton key={id} sx={{ py: 0.5 }} onClick={() => onPan(id)}>
            <IconButton>{icon(feature.geometry.type)}</IconButton>
            <ListItemText primary={label} primaryTypographyProps={{ fontSize: '0.9em' }} />
            <IconButton disabled={!canEdit} onClick={() => onEdit(feature)}><EditOutlinedIcon /></IconButton>
            <IconButton disabled={!canEdit} onClick={() => onDelete(id)}><DeleteOutlinedIcon /></IconButton>
        </ListItemButton>
    )
}



export default EditMap;