
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Chip, Link, Paper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { EventContext, EventContextType } from '../Context/EventContext';
import EventAlertContainer from '../EventFeed/EventAlertContainer';
import { NavLink } from 'react-router-dom';
import { IMenu } from '../Models/IMenu';
import useAPI from '../Hooks/useAPI';
import { INomadLink } from '../Models/INomadLink';
import { TripwireRoles } from '../../../constants/TripwireRoles';
import useRoles from '../Hooks/useRoles';
import AccountMenu from './AccountMenu';
import NotificationMenu from './NotificationMenu';
import useSettings from '../Hooks/useSettings';
import { MainMenuContext, MainMenuContextType } from '../Context/MainMenuContext';


export interface LayoutProps {
    menu: IMenu[],
    children: React.ReactNode | React.ReactNode[]
}

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
}));


interface INomadMenuLinkProps {
    to: string,
    text: string,
    external: boolean
}
const NomadMenuLink = ({ to, text, external }: INomadMenuLinkProps) => {
    return (
    <ListItemButton  component={NavLink} to={to} sx={{"&.active": { background: 'rgba(255, 255, 255, 0.08)'},m:0, py:0}}>
        <ListItemText primary={text} primaryTypographyProps={{
            fontSize:'1rem'
        }}/>
    </ListItemButton>
    );
}

const NomadExternalMenuLink = (props:INomadLink) => {
    return (
    <ListItemButton id={'nav_' + props.id} component={Link} target="_blank" rel="noopener noreferrer" href={props.linkUrl}  sx={{m:0, py:0}}>
        <ListItemText primary={props.linkText} /> <OpenInNewIcon sx={{ pr: 1 }} />
    </ListItemButton>
    )
}

interface INomadMenuHeaderProps {
    title:string
}

const NomadMenuHeader = ({title}:INomadMenuHeaderProps) => {
    return (
        <ListItem sx={{my:0, py:0}}>
        <ListItemText primary={title}  primaryTypographyProps={{
            fontWeight:'bold',
            fontSize: '1rem'
        }} />
    </ListItem>  
    );
}

const Layout = (props: LayoutProps) => {

    const isAdmin = useRoles([TripwireRoles.Admin]);
    const apiOptions = useSettings();
    const { Get } = useAPI();
    const { alertingEvents } = useContext(EventContext) as EventContextType;
    const element = alertingEvents.length > 0 ? <EventAlertContainer /> : props.children;
    const theme = useTheme();
    const [toolLinks, setToolLinks] = useState<INomadLink[]>([]);
    const { isOpen, SetState } = useContext(MainMenuContext) as MainMenuContextType;

    const handleDrawerOpen = () => {
        SetState(true);
    };

    const handleDrawerClose = () => {
        SetState(false);
    };

    useEffect(() => {
        Get('/api/nomad/linkmanager')
            .then((data: INomadLink[]) => {
                setToolLinks(data);
            })
    }, []);

    const menu_list = props.menu.filter(f => f.title.toLowerCase() !== "admin").map(m => {
        return (
            <List key={m.title}>
                <>
                    <NomadMenuHeader title={m.title} />
                    {m.menuItems.map(item => {
                        return (<NomadMenuLink key={`${m.title}_${item.text}`} to={item.url} text={item.text} external={false} />)
                    })}
                </>
            </List>
        )
    });

    const admin_list = props.menu.filter(f => f.title.toLowerCase() === "admin").map(m => {
        return (
            <List key={m.title}>
                <>
                    <NomadMenuHeader title={m.title} />
                    {m.menuItems.map(item => {
                        return (<NomadMenuLink key={`${m.title}_${item.text}`} to={item.url} text={item.text} external={false} />)
                    })}
                </>
            </List>
        )
    });

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isOpen}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(isOpen && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        NOMAD Overwatch <span style={{ opacity: 0.5 }}>(Beta Early Access)</span>
                    </Typography>
                    <NotificationMenu />
                    <AccountMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    flexDirection: 'column',
                    display: 'flex'
                }}
                variant="persistent"
                anchor="left"
                open={isOpen}
            >
                <DrawerHeader>
                    <Box sx={{display:'flex', flexDirection:'column', flexGrow:1, pl:2}}>
                    <Typography sx={{m:0, p:0}} variant='h6'>NOMAD Overwatch</Typography>
                    <Chip label="Beta Early Access" size="small" color="warning"></Chip>
                    </Box>
                    <IconButton onClick={handleDrawerClose} sx={{justifySelf:'flex-end'}}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <>{menu_list}</>

                {toolLinks.length > 0 && (
                    <>
                        <NomadMenuHeader title={`Links`} />

                        <List>
                            {
                                toolLinks.map(item => <NomadExternalMenuLink key={item.id} {...item} />)
                            }
                        </List>
                    </>
                )}

                {isAdmin &&
                                <>{admin_list}</>
                }
                <Typography sx={{ marginTop: 'auto', p: 1, color: 'grey' }} variant='body2' >
                    Beta 1.0 (build {apiOptions?.build})
                </Typography>
            </Drawer>
            <DrawerHeader />
            <Main open={isOpen}>
                <Paper elevation={0} sx={{ mt: 8, p: 1 }}>
                    {element}
                </Paper>
            </Main>
            
        </Box>
    );
}

export default Layout;
