import FolderIcon from '@mui/icons-material/Folder';
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IFile } from "../../Models/IFile";
import { FulcrumContext, FulcrumContextType } from '../FulcrumContext';
import { MoveOptions } from "./MoveOptions";

const MoveDialog = (props: MoveOptions) => {
    const { GetFolder } = useContext(FulcrumContext) as FulcrumContextType;
    
    const [selectedItem, setSelectedItem] = useState<string>("");
    const [folders, setFolders] = useState<IFile | null>(null);

    useEffect(() => {
        if(props.isOpen)
        {
            GetFolders();
        }
        
    }, [props.isOpen])

    const GetFolders = () => {
        GetFolder("00000000-0000-0000-0000-000000000000", true)
        .then(data => setFolders(data));
    }

    const selectItem = (id: string) => {
        setSelectedItem(id);
    }

    const isSelfOrParent = (id: string): boolean => {
        return props.subjectItem.id == id || props.subjectItem.parentId == id;
    };

    const buildTree = (folder: IFile, level: number, descendant: boolean) => {
        const name = level == 0 ? "\\" : folder.name;
        const baseStyle = { m: 0, p: 0, pl: level + 1 };
        
        const element = folder.isFolder ?
            <ListItemButton disabled={isSelfOrParent(folder.id) || descendant} sx={baseStyle} onClick={() => selectItem(folder.id)} selected={selectedItem == folder.id}>
                <IconButton><FolderIcon /></IconButton>
                <ListItemText primary={name} />
            </ListItemButton> : <span key={`${folder.id}`}></span>;

        return (
            <List key={folder.id} component="div" disablePadding>
                {element}
                <Collapse in={true} children={folder.children && folder.children.map(c => buildTree(c, level + 1, props.subjectItem.id == folder.id))} />
            </List>
        );
    }

    return (
        <Dialog open={props.isOpen || false} onClose={e => props.onClose(e)}>
            <DialogTitle>Move to...</DialogTitle>
            <DialogContent>
                <Paper style={{ width: 400, maxHeight: 400, minHeight: 400, overflow: 'auto' }}>
                    <List>
                        {folders && buildTree(folders, 0, false)}
                    </List>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={e => props.onCancel(e)}>Cancel</Button>
                <Button variant='contained' onClick={() => props.onConfirm(selectedItem)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MoveDialog;
