import { IPermission } from "./IPermission";

export interface IFile {
    id: string,
    author: string,
    authorName: string,
    description: string,
    extension: string,
    fullname: string,
    key: string,
    name: string,
    size: number,
    fileType: string,
    editor: string,
    isFolder: boolean,
    isUserShareFolder: boolean,
    parentId: string,
    path: string,
    children: IFile[],
    permissions: IPermission[],
    groupPermissions: IPermission[],
    hasPermission: (name: string, userid: string) => boolean
}

export class FulcrumFile implements IFile {
    id: string;
    author: string;
    authorName: string;
    description: string;
    extension: string;
    fullname: string;
    key: string;
    name: string;
    size: number;
    fileType: string;
    editor: string;
    isFolder: boolean;
    isUserShareFolder: boolean;
    parentId: string;
    path: string;
    children: IFile[];
    permissions: IPermission[];
    groupPermissions: IPermission[];

    constructor(file: IFile) {
        this.id = file.id;
        this.author = file.author;
        this.authorName = file.authorName;
        this.description = file.description;
        this.extension = file.extension;
        this.fullname = file.fullname;
        this.key = file.key;
        this.name = file.name;
        this.size = file.size;
        this.fileType = file.fileType;
        this.editor = file.editor;
        this.isFolder = file.isFolder;
        this.isUserShareFolder = file.isUserShareFolder;
        this.parentId =file.parentId;
        this.path = file.path;
        this.children = file.children;
        this.permissions = file.permissions;
        this.groupPermissions = file.groupPermissions;
    }

    private isOwner = (userid: string): boolean => {
        return this.author === userid;
    };

    hasPermission = (name: string, userid: string): boolean => {
        if (this.isOwner(userid)) return true;
        let hasFull = this.groupPermissions?.some(p => p.full) ||
            this.permissions?.some(p => p.full);
        if (hasFull) return true;

        let canOpen = this.groupPermissions?.some(p => p.open) || this.permissions?.some(p => p.open);
        let canEdit = this.groupPermissions?.some(p => p.edit) || this.permissions?.some(p => p.edit);
        let canDownload = this.groupPermissions?.some(p => p.download) || this.permissions?.some(p => p.download);
        let canShare = this.groupPermissions?.some(p => p.share) || this.permissions?.some(p => p.share);

        switch (name) {
            case "open":
                return canOpen;
            case "edit":
                return canEdit;
            case "download":
                return canDownload;
            case "share":
                return canShare;
        }

        return false;
    };
}