import { Box, Button, Fab, Icon, IconButton, LinearProgress, LinearProgressProps, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { green } from "@mui/material/colors";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import ErrorIcon from '@mui/icons-material/Error';
import ClearIcon from '@mui/icons-material/Clear';
import { FulcrumContext, FulcrumContextType } from "../FulcrumContext";

interface FileUploadProps {
    OnFileUploadComplete: () => void;
    OpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
    CurrentFolderId: string;
    Upload: boolean;
}

interface FileUploadRequest {
    filename: string,
    status: string, //pending, uploading, completed, error,
    file: File,
    progress: number,
    rate: number,
    time: number,
    reason?: string,
    abortController: AbortController
}

interface FileUploadAction {
    type: 'ADDED' | 'UPLOADING' | 'COMPLETED' | 'ERROR' | 'REMOVED' | 'CLEAR' | 'CANCEL',
    request: FileUploadRequest | null
}

function fileReducer(state: FileUploadRequest[], action: FileUploadAction): FileUploadRequest[] {

    switch (action.type) {
        case 'ADDED':
            if (action.request === null) return state;
            return [...state, { ...action.request, status: action.type }];
        case 'UPLOADING':
        case 'COMPLETED':
        case 'ERROR':
        case 'CANCEL':
            return state.map(f => {
                if (f.filename === action.request?.filename) {
                    return { ...f, status: action.type, reason: action.request.reason, progress: action.request.progress, time: action.request.time };
                }
                return f;
            });
        case 'REMOVED':
            return state.filter(f => f.filename != action.request?.filename);
        case 'CLEAR':
            return [];
        default:
            console.error(`Unknown file action '${action.type}', no changes made.`);
            return state;
    }
}

enum FileUploadComponentState {
    CLOSED,
    PENDING,
    UPLOADING,
    FINISHED
}

const FileUpload = ({ OnFileUploadComplete, CurrentFolderId, OpenMenu, Upload }: FileUploadProps) => {

    
    const {
        apiOptions
    } = useContext(FulcrumContext) as FulcrumContextType;
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const [files, filesDispatch] = useReducer(fileReducer, []);
    const auth = useAuth();
    const [componentState, setComponentState] = useState<FileUploadComponentState>(FileUploadComponentState.CLOSED);

    useEffect(() => {
        if (Upload) onFileSelect(null);
    }, [Upload]);

    const onFileChanged = async (e: any) => {

        const fileArray: File[] = Array.from(e.target.files);

        fileArray.forEach(f => {
            const request: FileUploadRequest = {
                filename: f.name,
                status: '',
                file: f,
                progress: 0,
                rate: 0,
                time: 0,
                abortController: new AbortController()
            };

            const action: FileUploadAction = {
                type: 'ADDED',
                request
            };

            filesDispatch(action);
        });

    }

    const onFileSelect = (event: any) => {
        window.addEventListener('focus', handleFocusBack);
        if (hiddenFileInput.current == null) return;
        hiddenFileInput.current.click();
        setComponentState(FileUploadComponentState.PENDING);
    }

    const handleFocusBack = (e: any) => {
        window.removeEventListener('focus', handleFocusBack);
        OnFileUploadComplete();
    }

    const UploadFiles = async (event: any) => {
        setComponentState(FileUploadComponentState.UPLOADING);
        if (files == null) return;

        for (let index: number = 0; index < files.length; index++) {

            filesDispatch({ type: 'UPLOADING', request: files[index] });

            const file = files[index];

            // NOTE: `file` MUST be appended last due to the way large files are handled
            const formData = new FormData();
            formData.append("folderId", CurrentFolderId);
            formData.append("overwrite", "false");
            formData.append("file", file.file);

            const uploadUrl = `${apiOptions?.files}/api/Files/upload`;

            var config = {
                headers: {
                    'Authorization': `Bearer ${auth.user?.access_token}`
                },
                onUploadProgress: function (progressEvent: any) {
                    filesDispatch({ type: 'UPLOADING', request: { ...file, progress: Math.round(progressEvent.progress * 100), time: Math.round(progressEvent.estimated) } });
                },
                signal: file.abortController.signal
            };

            try {
                const response = await axios.post(uploadUrl, formData, config);
                if (response.data.success) {
                    filesDispatch({ type: 'COMPLETED', request: file });
                }
                else {

                    const request = { ...file, reason: response.data.messages ? response.data.messages[0] : "" };
                    filesDispatch({ type: 'ERROR', request });
                }
            }
            catch (error: any) {
                const request = { ...file, reason: error.toJSON().message };
                filesDispatch({ type: 'ERROR', request });
            }

        }
        setComponentState(FileUploadComponentState.FINISHED);
        OnFileUploadComplete();
    }

    const CloseUploadSummary = (event: any) => {
        setComponentState(FileUploadComponentState.CLOSED);
        filesDispatch({ type: 'CLEAR', request: null });
        //OnFileUploadComplete();
    }

    return (

        <Box>
            <input ref={hiddenFileInput} type="file" onChange={onFileChanged} style={{ display: 'none' }} multiple data-testid={`hiddenInput`} />


            {files.length > 0 ?
                <Box component={Paper} elevation={6} sx={{ p: 1 }}>
                    <List>

                        {files.map(f => {

                            switch (f.status) {
                                case 'ADDED':
                                    return (

                                        <ListItem key={f.filename} alignItems="flex-start" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <IconButton sx={{ mr: 1 }} color="error" onClick={(e) => filesDispatch({ type: 'REMOVED', request: f })} data-testid={`removePendingFile-${f.filename}`}><DeleteIcon /></IconButton>

                                                <ListItemText
                                                    primary={f.filename}
                                                    secondary={"Pending"}
                                                />
                                            </Box>
                                            <Box sx={{ width: '100%' }}>
                                                <LinearProgress variant="determinate" color="info" value={0} />
                                            </Box>

                                        </ListItem>
                                    );
                                case 'UPLOADING':
                                    return (

                                        <ListItem key={f.filename} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <IconButton sx={{ mr: 1 }} color="error" onClick={(e) => f.abortController.abort()} data-testid={`abortFile-${f.filename}`}><ClearIcon /></IconButton>
                                                <ListItemText
                                                    primary={f.filename}
                                                    secondary={`${f.progress}%`}
                                                />
                                            </Box>
                                            <Box sx={{ width: '100%' }}>
                                                 {f.progress < 100 ? ( 
                                                    <LinearProgress variant="determinate" value={f.progress} />)
                                                 :
                                                    (
                                                    <LinearProgress />
                                                    )}
                                                
                                            </Box>
                                        </ListItem>
                                    );
                                case 'COMPLETED':
                                    return (

                                        <ListItem key={f.filename} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <IconButton  sx={{ mr: 1 }}><DoneIcon color="success" /></IconButton>
                                                <ListItemText
                                                    primary={f.filename}
                                                    secondary={`Completed`}
                                                />
                                            </Box>
                                            <Box sx={{ width: '100%' }}>
                                                <LinearProgress variant="determinate" color="secondary" value={100} />
                                            </Box>
                                        </ListItem>
                                    );
                                case 'ERROR':
                                case 'CANCEL':
                                    return (

                                        <ListItem key={f.filename} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <IconButton sx={{ mr: 1 }}><ErrorIcon  color="error" /></IconButton>
                                                <ListItemText
                                                    primary={f.filename}
                                                    secondary={<Typography color="error">{f.reason}</Typography>}
                                                />
                                            </Box>
                                            <Box sx={{ width: '100%' }}>
                                                <LinearProgress variant="determinate" color="error" value={100} />
                                            </Box>
                                        </ListItem>
                                    );
                            }



                        })}
                    </List>

                    {componentState === FileUploadComponentState.PENDING &&
                        <Box>
                            <Button sx={{ ml: 2 }} size="small" variant="contained" onClick={UploadFiles} data-testid={`startUpload`}>Upload Files</Button>
                        </Box>
                    }

                    {componentState === FileUploadComponentState.UPLOADING &&
                        <Box>
                            <Button sx={{ ml: 2 }} size="small" variant="contained" disabled >Upload Files</Button>
                        </Box>
                    }

                    {componentState === FileUploadComponentState.FINISHED &&
                        <Box>
                            <Button sx={{ ml: 2 }} variant="contained" size="small" color="error" onClick={CloseUploadSummary} data-testid={`closeUpload`}>Close</Button>
                        </Box>
                    }

                </Box>
                :
                <><Fab size="medium" onClick={OpenMenu} color="primary" sx={{ mb: 0 }} data-testid={`filesMenu`}>
                    <AddIcon />
                </Fab></>
            }
        </Box>

    );
}


function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default FileUpload;