import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { ReacherConstants } from "../../../../constants/ReacherConstants";
import useAPI from "../../Hooks/useAPI";
import { ReacherDialogOptions } from "../Components/ReacherDialogOptions";
import { ReacherCommand } from "../Models/ReacherCommand";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import { GenericCommandButton, MessageCommandButton, TriggerCommandButton } from "./Components/CommandButtons";
import ConfirmCommandButton from "./Components/CommandButtons/ConfirmCommandButton";
import LengthCommandButton from "./Components/CommandButtons/LengthCommandButton";
import ScienceIcon from '@mui/icons-material/Science';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

interface Dictionary<T> {
    [key: string]: T
}

const CommandPanel = () => {

    const [commands, setCommands] = useState<ReacherCommand[]>([]);
    const { Get, Post } = useAPI();

    const { devices } = useContext(ReacherContext) as ReacherContextType;
    const [commandsEnabled, setCommandsEnabled] = useState<boolean>(false);

    useEffect(() => {

        const anyDevicesSelected = devices
            .filter(x => x.registrationState == ReacherConstants.DeviceRegistrationStates.Approved)
            .filter(x => x.selected);
        setCommandsEnabled(anyDevicesSelected.length > 0);
    }, [devices]);

    useEffect((): void => {

        Get('api/reacher/commands').then(data => setCommands(data));

    }, []);

    const onCommandClicked = (commandId: string, data: Dictionary<string> | null) => {
        const deviceIds = devices.filter(x => x.selected).map(x => x.id);
        const newCommandRequest = {
            commandId,
            deviceIds,
            params: data
        };

        Post('/api/reacher/device/command', newCommandRequest).then(data => {
            //Do we want to say anything here????
        });
    }

    const CreateCommmandButton = (cmd: ReacherCommand, gridSize: number) => {

        let element = null;
        switch (cmd.commandText) {
            case "ACCEPT":
            case "INPUT":
            case "MOTION":
            case "POWER":
            case "PIN":
            case "NOTIFY":
                element = <TriggerCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon='' />
                break;
            case "TRACK":
                element = <TriggerCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon={<GpsFixedIcon sx={{ mr: 1 }} />} />
                break;
            case "TEST":
                element = <TriggerCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon={<ScienceIcon sx={{ mr: 1 }} />} />
                break;
            case "MSG":
                element = <MessageCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon='' />;
                break;
            case "RESET":
            case "CLEAR":
                element = <ConfirmCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon='' />;
                break;
            case "AUDIO":
            case "VIDEO":
                element = <LengthCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon='' />;
                break;
            default:
                element = <GenericCommandButton cmd={cmd} enabled={commandsEnabled} onSend={onCommandClicked} icon='' />

        }

        return <Grid key={cmd.id} item xs={gridSize}>{element}</Grid>
    }

    const immediate_commands = commands.filter(x => x.section.startsWith("Imm")).map(cmd => CreateCommmandButton(cmd, 4));
    const trigger_commands = commands.filter(x => x.section.startsWith("Arm")).map(cmd => CreateCommmandButton(cmd, 4));
    const reset_commands = commands.filter(x => x.section.startsWith("Reset")).map(cmd => CreateCommmandButton(cmd, 6));

    return (
        <Box component={Paper} elevation={2} sx={{ p: 1, mt: 2 }}>
            <Typography sx={{ textAlign: 'center', my: 1 }} variant="h6" component="h6">Immediate Actions</Typography>
            <Grid container spacing={1}>{immediate_commands}</Grid>
            <Typography sx={{ textAlign: 'center', my: 1 }} variant="h6" component="h6">Arm/Disarm Triggers</Typography>
            <Grid container spacing={1}>{trigger_commands}</Grid>
            <Typography sx={{ textAlign: 'center', my: 1 }} variant="h6" component="h6">Reset Commands</Typography>
            <Grid container spacing={1} >{reset_commands}</Grid>
        </Box>
    );
}

export default CommandPanel;