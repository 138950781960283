import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";


export interface ISignalrEvent {
    eventName: string,
    callback: any
}


export const useSignalr = (url:string, events:ISignalrEvent[]) => {

    const auth = useAuth();
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {

        var _conn = new HubConnectionBuilder()
        .withUrl(url, {
            accessTokenFactory: () => { return auth.user?.access_token as (string) },
            transport: HttpTransportType.WebSockets,
            logger: LogLevel.Error
        })
        .withAutomaticReconnect()
        .build();

        setConnection(_conn);

        return () => {
            if(connection) {
                events.forEach(evt => connection.off(evt.eventName));
                connection?.stop();
                setConnection(null);
            }
        }
    }, [auth.user?.access_token]);

    useEffect(() => {

        if(!connection) return;
        if(connection?.state === HubConnectionState.Disconnected)
        {
            connection.start().catch(err => console.error(err));
            events.forEach(evt => {
                connection.off(evt.eventName);
                connection.on(evt.eventName, evt.callback);
            });
        }

    }, [connection]);

    return {
        connectionState: connection == null ? 'Unknown' : connection.state
    };

}
