import { Box, Button, Chip, Icon, IconButton, TextField, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SelectAll from '@mui/icons-material/LibraryAddCheckOutlined';
import Deselect from '@mui/icons-material/IndeterminateCheckBoxOutlined';

export interface DevicePanelFilterProps {
    onFilterChanged: (deviceFilter: string) => void,
    onSelectAll: (selected: boolean) => void
}

const DevicePanelFilters = (props: DevicePanelFilterProps) => {

    const [textFilter, setTextFilter] = useState<string>("");
    const [showSummary, setShowSummary] = useState<Boolean>(true);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const filterToggleButton = <IconButton onClick={() => setShowSummary(!showSummary)}><FilterAltIcon /> </IconButton>;

    useEffect(() => {
        props.onFilterChanged(textFilter);
    }, [textFilter]);

    useEffect(() => {
        props.onSelectAll(allSelected);
    }, [allSelected]);


    const onKeyUp = (e: any) => {
        const keysToClose: string[] = ["Enter", "Escape"];
        if (keysToClose.includes(e.key)) {
            setShowSummary(true);
        }
    }

    const selectAll =
        <IconButton onClick={() => setAllSelected(!allSelected)} sx={{ml:'8px'}}>
            {allSelected && <Deselect />}
            {!allSelected && <SelectAll />}
        </IconButton>

    if (showSummary) {
        return (
            <Box>
                {selectAll}
                {filterToggleButton}
                {textFilter && <Chip label={textFilter} onClick={e => { setShowSummary(false) }} onDelete={e => setTextFilter("")} sx={{ backgroundColor: blueGrey[500] }} clickable />}
            </Box>
        );

    }

    return (
        <Box>
            {selectAll}
            {filterToggleButton}
            <TextField sx={{ width: 'auto' }} placeholder="filter devices" size="small" value={textFilter} onChange={e => setTextFilter(e.target.value)} onKeyUp={onKeyUp} variant="outlined" />
        </Box>
    )

}

export default DevicePanelFilters;