import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import useViewPortDimensions from "../Hooks/useViewPort";
import { LogContext, LogContextType } from "./LogContext";
import dayjs from "dayjs";
import Loading from "../Common/Loading";
var _ = require('lodash');


const HighlightedText = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return <p>{text}</p>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <p>
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
            ))}
        </p>
    )
}

const LogViewer = () => {

    const { viewPortHeight } = useViewPortDimensions();
    const {logs, fetching} = useContext(LogContext) as LogContextType;
    if (fetching || logs == null) {
        <Loading></Loading>
    }

    return (
        <>
            <TableContainer component={Paper} elevation={3} sx={{ height: viewPortHeight - 140, overflowY: 'auto', scrollbarWidth: 'thin' }}>
                <Table stickyHeader size="small" data-testid="logviewer_table_logs">
                    <TableHead>
                        <TableRow>
                            <TableCell width="120">Log Level</TableCell>
                            <TableCell width="175">Time stamp</TableCell>
                            <TableCell width="50">MessageID</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs?.logEntries.map(r => {
                            return (
                                <TableRow key={r.id}>
                                    <TableCell>{r.logLevel}</TableCell>
                                    <TableCell>{dayjs(r.timeStamp).format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                    <TableCell>{r.messageId}</TableCell>
                                    <TableCell>{r.message}</TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box component={Paper} elevation={0}>
                <Typography variant="subtitle2">{`Showing ${logs?.count} / ${logs?.total} log entries, logs retrieved ${dayjs(logs?.dateRetrieved).format("DD-MM-YYYY HH:mm:ss")}`}</Typography>
            </Box>
        </>
    );
}

export default LogViewer;