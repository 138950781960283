import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import PageNotFound from "./Common/Errors/404-PageNotFound";
import EventProvider from "./Context/EventContext";
import MainMenuProvider from "./Context/MainMenuContext";
import NotificationProvider from "./Context/NotificationContext";
import SettingsProvider from "./Context/SettingsContext";
import UserNotificationProvider from "./Context/UserNotificationContext";
import Layout from "./Layout/Layout";
import { IMenu } from "./Models/IMenu";

const SecureApp = () => {

    const [menu, setMenu] = useState<IMenu[] | null>(null);
    const auth = useAuth();

    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    //once signed in this is effectivly the app loading. Show a temporary 
    //loading screen while the menus and any other initial data is loaded in. 
    useEffect(() => {
        var options = {
            headers: {
                "Authorization": "Bearer " + auth.user?.access_token,
                "Content-Type": "application/json"
            }
        };

        fetch('/api/menu', options)
            .then(res => {
                if (!res.ok)
                    return [{ "title": "", "menuItems": [] }];
                return res.json();
            })
            .then(data => {
                setMenu(data);
            });


    }, []);

    if (menu === null) {
        //Todo put a prettier loading screen here. 
        return <></>
    }

    return (
        <SettingsProvider>
            <NotificationProvider>
                <UserNotificationProvider>
                    <EventProvider>
                        <MainMenuProvider>
                            <Layout menu={menu}>
                                <Routes>
                                    {AppRoutes.map((route, index) => {
                                        const { element, ...rest } = route;
                                        return <Route key={index} {...rest} element={element} />;
                                    })}
                                    <Route path="*" element={<PageNotFound />} />
                                </Routes>
                            </Layout>
                        </MainMenuProvider>
                    </EventProvider>
                </UserNotificationProvider>
            </NotificationProvider>
        </SettingsProvider>);


}

export default SecureApp;