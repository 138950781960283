import { IDrawingAction } from "../types/IDrawingAction";
import { IFeatureCollection } from "../types/IFeatureCollection";


export const updateUserData = (state: IFeatureCollection, action: IDrawingAction): IFeatureCollection => {
    const { type, event } = action;

    switch (type) {
        case 'INIT':
            return { ...state, lastUpdated: new Date().getTime(), features: event.features }
        case 'CREATE':

            const eventFeature = { ... event.features[0]};
            const nextFeatures = [...state.features, eventFeature];
            const nextUserData = {
                ...state,
                features: nextFeatures,
                lastUpdated: new Date().getTime()
            };
            return nextUserData;
        case 'UPDATE':

            
            const newFeature = event.features[0];
            const id = newFeature.properties.UserDataId;

            const updatedFeatures = state.features.map((f:any) => {
                if(f.properties.UserDataId === id) {
                    return { 
                        ... f,
                        geometry: newFeature.geometry,
                        properties: newFeature.properties
                    }
                }
                return f;
            });

            return {
                ...state,
                lastUpdated: new Date().getTime(),
                features: updatedFeatures
            };


        case 'DELETE':
            const deletedFeature = event.features[0];

            const featuresAfterDelete = state.features.filter(f => f.properties.UserDataId !== deletedFeature.properties.UserDataId);

            return {
                ...state,
                lastUpdated: new Date().getTime(),
                features: featuresAfterDelete
            };
        default:
            console.error(`Unsupported operation of type ${action.type} on user data.`);
            return state;
    }

}