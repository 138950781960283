import { useContext, useMemo } from "react";
import { GeoDataUpdateContext, GeoDataUpdateContextType } from "../../GeoDataUpdateContext";
import { IDevice } from "../../models/Device";
import DeviceCard from "./DeviceCard";

export interface IDeviceListProps {
    panTo:(coords:number[]) => void
}

const DeviceList = ({panTo}:IDeviceListProps) => {
    const { devices  } = useContext(GeoDataUpdateContext) as GeoDataUpdateContextType;


    const device_list_memo = useMemo(() => {

        return Array.from(devices.values()).map((d: IDevice) => {

            return (
                <DeviceCard key={d.id} device={d} panTo={panTo} />
            )
        })
    }, [devices]); //panTo ommitted from dependencies, including it caused the map to slow down. 

    return <>{device_list_memo}</>;
    
}

export default DeviceList;