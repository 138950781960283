import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { Alert, Box, Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import validator from 'validator';
import FancyLoader from "../../Common/FancyLoader";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import useViewPortDimensions from "../../Hooks/useViewPort";
import { IApiResult } from "../../Models/IApiResult";
import { IGroup } from "../../Models/IGroup";
import { IRole } from "../../Models/IRole";
import { IUser } from "../../Models/IUser";
import { IUserRequest } from "../../Models/IUserRequest";
import ReacherDialog from "../../Reacher/Components/ReacherDialog";
import { ReacherDialogOptions } from "../../Reacher/Components/ReacherDialogOptions";

const UserManagerEdit = () => {
    let initialDialogOptions: ReacherDialogOptions = {
        isOpen: false,
        title: "",
        action: "",
        message: "",
        onClose: () => { },
        onCancel: () => { },
        onConfirm: () => { }
    }

    const { viewPortHeight } = useViewPortDimensions();
    const { id } = useParams();
    const [user, setUser] = useState<IUser | null>(null);
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);

    const [groups, setGroups] = useState<IGroup[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);

    const { Get, Put, Delete } = useAPI();
    const { PushSuccess, PushError } = useNotifications();
    const [errors, setErrors] = useState<string[]>([]);
    const [dialogOptions, setDialogOptions] = useState<ReacherDialogOptions>(initialDialogOptions);
    const auth = useAuth();
    const nav = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        const promises = [
            Get('/api/usermanager/roles'),
            Get('/api/usermanager/groups'),
            Get('/api/usermanager/' + id)
        ];

        Promise.all(promises).then((values) => {
            setRoles(values[0]);
            setGroups(values[1]);
            setUser(values[2]);
        })
            .catch(err => {
                setErrors(["Unable to load user"]);
            });


    }, []);

    const updateUser = () => {

        let _errors = [];

        if (user === null) {
            _errors.push("Unable to edit user.");
            return;
        }

        if (!user.userName && user.userName.length < 2) {
            _errors.push("User name is required");
        }

        if (!strongPassword && password !== "") {
            _errors.push("Password must me at least 10 characters, contain a mixture of upper and lowercase characters, at least 1 number, and at least 1 symbol.");
        }

        setErrors(_errors);
        if (_errors.length === 0) {
            let userRequest: IUserRequest = {
                user: user,
                password: password
            };
            Put('/api/usermanager', userRequest)
                .then((data: any) => {
                    if (data.messages) {
                        nav(`/settings/users`);
                        PushSuccess("User updated");
                    }
                }).catch(err => {
                    console.log(err.message);
                });
        }
    }

    const validate = (value: string) => {
        setPassword(value);
        setStrongPassword(validator.isStrongPassword(value, {
            minLength: 10, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        }));
    }

    const closeDialog = () => {
        let newDialogOptions = { ...dialogOptions, isOpen: false, };
        setDialogOptions(newDialogOptions);
    }

    const deleteUser = (id: string | null) => {
        if (id === null) return;

        Delete("/api/usermanager/" + id)
            .then((data: IApiResult) => {
                if (data.success) {
                    nav(`/settings/users`);
                    PushSuccess("User Deleted");
                }
                else {
                    PushError(data.messages[0]);
                }
            });
        closeDialog();
    }

    const deleteDialog = (id: string | any) => {
        let dialogOptions: ReacherDialogOptions = {
            isOpen: true,
            title: "DELETE USER",
            action: "DELETE USER",
            message: "Are you sure you wish to delete this user?",
            onClose: () => closeDialog(),
            onCancel: () => closeDialog(),
            onConfirm: () => deleteUser(id)
        };
        setDialogOptions(dialogOptions);
    }


    const onRoleClicked = (id: string) => {
        if (user === null) {
            return;
        }
        const nextRoles = isInArray(id, user.roles)
            ? user.roles.filter(x => x !== id)
            : [...user.roles, id];

        setUser({ ...user, roles: nextRoles });

    }

    const onGroupClicked = (id: string) => {
        if (user === null) {
            return;
        }
        const nextGroups = isInArray(id, user.groups)
            ? user.groups.filter(x => x !== id)
            : [...user.groups, id];

        setUser({ ...user, groups: nextGroups });

    }

    const isInArray = (value: string, list: string[]) => {
        return list.indexOf(value) > -1;
    }




    if (user === null) {
        return <FancyLoader
            error={errors.length > 0}
            message={errors.length > 0 ? errors[0] : "Loading user ... "}
            icon={<PersonIcon />}
            errorElement={<Button data-testid="btn_userload_error_back" component={NavLink} to={`/settings/users`} variant="text" sx={{
                color: 'white',
                '&:hover': {
                    backgroundColor: grey[800]
                }
            }}>Back to users</Button>}
        />

    }

    return (
        <Box sx={{ mt: 0, p: 1, maxHeight: viewPortHeight, overflowY: 'auto', scrollbarWidth: 'thin' }} component={Paper} elevation={4}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <Typography variant="h4">
                            Edit user {user.userName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} >

                    <Alert severity="info" sx={{ my: 1 }}>
                        <Typography variant="body2">Changing password creates a temporary password, the user will be asked to set a new password when they next login.</Typography>
                    </Alert>
                    <FormControl sx={{ my: 1 }} fullWidth variant="outlined" onKeyUp={(e: any) => validate(e.target.value)} data-testid="txt_edituser_password">
                        <InputLabel htmlFor="outlined-adornment-password">Temporary password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            autoComplete="off"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6}>

                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Roles"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {roles.map(r => {
                            return (
                                <ListItemButton key={r.id} role={undefined} onClick={(e: any) => onRoleClicked(r.id)} sx={{ py: 0 }} data-testid={`btn_edituser_roles_${r.name}`}>
                                    <ListItemIcon>
                                        <Checkbox
                                            data-testid={`chk_edituser_roles_${r.name}`}
                                            edge="start"
                                            checked={isInArray(r.id, user.roles)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={r.name} />
                                </ListItemButton>
                            );
                        })}
                    </List>

                </Grid>
                <Grid item xs={12} md={6}>

                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Groups"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {groups.map(g => {
                            return (
                                <ListItemButton key={g.id} role={undefined} onClick={(e: any) => onGroupClicked(g.id)} sx={{ py: 0 }} data-testid={`btn_edituser_groups_${g.name}`}>
                                    <ListItemIcon>
                                        <Checkbox
                                            data-testid={`chk_edituser_groups_${g.name}`}
                                            edge="start"
                                            checked={isInArray(g.id, user.groups)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={g.name} />
                                </ListItemButton>
                            );
                        })}
                    </List>

                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                        <Button sx={{ mr: 1 }} variant="contained" color="success" onClick={updateUser} data-testid="btn_edituser_save">Save</Button>
                        <Button component={NavLink} to={`/settings/users`} data-testid="btn_edituser_cancel" sx={{
                            mr: 1,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: grey[800]
                            }
                        }} >Cancel</Button>
                        <Button sx={{ ml: "auto", justifyContent: "flex-end" }} disabled={auth.user?.profile.sub === id} variant="contained" color="error" onClick={() => deleteDialog(id)}>Delete User</Button>
                    </Box>
                </Grid>
            </Grid>
            <ReacherDialog {...dialogOptions} />
        </Box>


    );
}

export default UserManagerEdit;