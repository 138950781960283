import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { createContext, ReactElement, useEffect, useReducer, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import useNotifications from "../Hooks/useNotifications";
import { IApiResult } from "../Models/IApiResult";
import { IPocketLitter } from "../Models/IPocketLitter"



export type EventContextType = {
    alertingEvents: string[];
    events: IPocketLitter[];
    AcknowledgeEvent: (pockerLitter: IPocketLitter)=>void;
}

//This needs refactoring, its a complete mess. But atleast its not a mess in the apps componets.

export const EventContext = createContext<EventContextType | null>(null);



type EventProviderProps = {
    children: ReactElement | ReactElement[];
}




const EventProvider = (props: EventProviderProps) => {

    const {PushSuccess,PushError} = useNotifications();
    const auth = useAuth();

    const [events, setEvents] = useState<IPocketLitter[]>([]);
    const [alertingEvents, setAlertingEvents] = useState<string[]>([]);
    const [connection, setConnection] = useState<HubConnection | null>(null);

    const latestEvents = useRef<IPocketLitter[]>([]);
    latestEvents.current = events;
    const latestAlerts = useRef<string[]>([]);
    latestAlerts.current = alertingEvents;
    //Can this state/ref be wrapped into a custom hook?? 
    const updateSetEvents = (data:IPocketLitter[]) => {
        latestEvents.current = data;
        setEvents(data);
    }

    useEffect(() => {
        var alerts = events.filter(x => x.event.acknowledgedBy === null).map(x => x.tripwireEventId);
        latestAlerts.current = alerts;
        setAlertingEvents(alerts);
    }, [events]);

    useEffect((): (() => void) => {

        const requestHeaders : RequestInit = {
            method:"GET",
            headers: {
                "Authorization": "Bearer " + auth.user?.access_token
            }
        };

        fetch('/api/EventFeed', requestHeaders)
            .then(res => {
                if(!res.ok) {
                    throw Error(res.statusText);
                }

                return res.json(); 
            })
            .then((data: IPocketLitter[]) => {
               updateSetEvents(data);
            })
            .catch(err => {
                console.log(err);
                PushError("Unable to retrieve event feed.");
            })


        const access_token = auth.user != null ? auth.user.access_token : "";

        const conn = new HubConnectionBuilder()
            .withUrl("/hubs/events", {
                accessTokenFactory: () => { return access_token},
                transport: HttpTransportType.WebSockets,
                logger: LogLevel.None
            })
            .withAutomaticReconnect()
            .build();

        conn.start()
            .then(() => {
                conn.on('EventRecieved', EventRecieved);
                conn.on('UpdateRequestStatus', UpdateRequestStatus);
                setConnection(conn);
            })
            .catch(e => console.error(e));
        return () => connection?.stop();
    }, []);

    function UpdateRequestStatus(data: IApiResult) {    
        console.log(data);
        if(data.success){
            console.log('Response Recieved', data.messages[0])
            //PushSuccess(data.messages[0]);
        }
        else {
            console.error('Error Recieved', data.messages[0])
            //PushError(data.messages[0]);
        }
    }

    function EventRecieved(data: IPocketLitter):void {
        let currentEvents: IPocketLitter[] = [...latestEvents.current];
        const itemindex = currentEvents.findIndex(x => x.tripwireEventId === data.tripwireEventId);
        if (itemindex > -1) {
            currentEvents[itemindex] = data;
        }
        else {
            currentEvents = [data, ...currentEvents];
        }
        updateSetEvents(currentEvents);
    }

    function AcknowledgeEvent(pockerLitter: IPocketLitter):void {
        
        const ackRequestData = {
            PocketLitterId: pockerLitter.id,
            TripwireEventId: pockerLitter.tripwireEventId,
            AcknolwedgedBy: auth?.user?.profile.preferred_username
        };

        connection?.invoke('AckEvent', ackRequestData);
    }

    return (
        <EventContext.Provider value={{ alertingEvents, events, AcknowledgeEvent }}>
            {props.children}
        </EventContext.Provider>
    );

}

export default EventProvider;
