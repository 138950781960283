import { IDevice } from "../models/Device";

export interface IDeviceToggleRequest {
    deviceId: string,
    property: "BOTH" | "LIVE" | "TRAIL",
    nextState: boolean
}

export interface DeviceReducerAction {
    action: "INIT" | "UPDATE" | "TOGGLE",
    payload: IDevice | Map<string, IDevice> | IDeviceToggleRequest
}


export const DeviceReducer = (state: Map<string, IDevice>, action: DeviceReducerAction): Map<string, IDevice> => {

    switch (action.action) {
        case "INIT":
            return action.payload as Map<string, IDevice>;
        case "UPDATE":

            const currentDevice = state.get((action.payload as IDevice).id);
            const nextDevice: IDevice = {
                ...action.payload as IDevice,
                visible: currentDevice?.visible || true,
                trailVisible: currentDevice?.trailVisible || false,
                geoJSON: JSON.parse((action.payload as IDevice).featureJson),
                trailJson: JSON.parse((action.payload as IDevice).trailJson)
            };
            return new Map(state.set(nextDevice.id, nextDevice));
        case "TOGGLE":
            const payload = action.payload as IDeviceToggleRequest;
            
            let device = state.get(payload.deviceId);
            if(!device) return state;

            if(["BOTH", "LIVE"].indexOf(payload.property) > -1) { 
                device.visible = payload.nextState; 
                if(!payload.nextState) device.trailVisible = false;
            }
            if(["BOTH", "TRAIL"].indexOf(payload.property) > -1) {
                device.trailVisible = payload.nextState;
                if(payload.nextState) device.visible = true;
            }

            return new Map(state.set(device.id, device));

        default:
            return state;
    }

}
