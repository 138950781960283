import { createContext, ReactElement, useEffect, useReducer, useState } from "react";
import { useSignalr } from "../Atlas/useSignalr";
import useAPI from "../Hooks/useAPI";
import useSettings from "../Hooks/useSettings";
import { IDevice } from "./models/Device";
import { DeviceReducer } from "./reducers/deviceReducer";
import IDeviceState from "./types/IDeviceState";


export type GeoDataUpdateContextType = {
    devices: Map<string, IDevice>,
    connstate: string,
    onToggleDevice: (DeviceId: string, Property: "BOTH" | "LIVE" | "TRAIL", NextState: boolean) => void
}

export const GeoDataUpdateContext = createContext<GeoDataUpdateContextType | null>(null);

type GeoDataUpdateProviderProps = {
    children: ReactElement | ReactElement[];
}



const GeoDataUpdateProvider = (props: GeoDataUpdateProviderProps) => {
    const apiOptions = useSettings();

    const { connectionState } = useSignalr(`${apiOptions.geo}/hubs/geodata`, [{
        eventName: 'OnDeviceUpdated',
        callback: (data: any) => dispatchDevices({ action: 'UPDATE', payload: data })
    }]);

    const { Get } = useAPI();

    const [devices, dispatchDevices] = useReducer(DeviceReducer, new Map<string, any>());

    useEffect(() => {
        Get(`${apiOptions.geo}/api/device`)
            .then((data) => {

                let hash = new Map<string, IDevice>();
                data.devices.forEach((device: IDevice) => {
                    hash.set(device.id, { ...device, geoJSON: JSON.parse(device.featureJson), trailJson: JSON.parse(device.trailJson), visible:true, trailVisible:false });
                });
                dispatchDevices({ action: "INIT", payload: hash });

            });
    }, []);

    const onToggleDevice = (DeviceId: string, Property: "BOTH" | "LIVE" | "TRAIL", NextState: boolean): void => {
        dispatchDevices({action: "TOGGLE", payload: {deviceId: DeviceId, property: Property, nextState: NextState}});
    }

    return (
        <GeoDataUpdateContext.Provider value={{ devices, connstate: connectionState, onToggleDevice }}>
            {props.children}
        </GeoDataUpdateContext.Provider>
    );
}

export default GeoDataUpdateProvider;
