import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

const useRoles = (role: string[]): boolean => {

    const auth = useAuth();
    const [inRole, setInRole] = useState<boolean>(false);

    useEffect(() => {
            const usersRoles: string[] = (auth.user?.profile as any).roles;
            const intersection = (role as string[]).filter(x => usersRoles.includes(x));
            const userHasRole = intersection.length > 0;
            setInRole(userHasRole);
    }, [auth.isAuthenticated, role, auth.user?.profile]);

    return inRole;
}

export default useRoles;
