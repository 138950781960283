import { useContext, useState } from 'react';
import { EventContext, EventContextType } from '../Context/EventContext';
import { IPocketLitter } from '../Models/IPocketLitter';
import EventMap from './EventMap';
import './PocketLitter.scss';
import dayjs from 'dayjs'
import { Button, Card, CardActions, CardContent, CardHeader, CardProps, Collapse, Divider, IconButton, IconButtonProps, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface EventCardProps extends CardProps {
    isAcknowledged: boolean;
}

const EventCard = styled((props: EventCardProps) => {
    const { isAcknowledged: IsAcknowledged, ...other } = props;
    return <Card {...other} />;
})(({ isAcknowledged }) => ({
    background: isAcknowledged ? "" : "darkred"
}));

interface BodyItemProps {
    title: string,
    info: string
}

const InfoItem = (props: BodyItemProps) => {
    return <li><span className="title">{props.title}: </span>{props.info}</li>
}

const PocketLitter = (data: IPocketLitter) => {

    const { AcknowledgeEvent } = useContext(EventContext) as EventContextType;
    const isAcknowledged = data.event.acknowledgedBy !== null;

    const dateStringFormat = "DD-MM-YYYY HH:mm:ss";

    const handleAckClick = () => {
        AcknowledgeEvent(data);
    }

    const longLat = data.event.ipInfo ? data.event.ipInfo.latitude + ", " + data.event.ipInfo.longitude : "no ip info";

    const subHeaderText = data.event.acknowledgedBy ? `Acknowledged by ${data.event.acknowledgedBy} on ${dayjs(data.event.dateAcknowledged).format(dateStringFormat)}` : '';

    return (

        <EventCard isAcknowledged={isAcknowledged} elevation={6} sx={{ mb: 2 }}>
            
            <CardHeader
                title={`Profile Web Page Tripped: ${data.username}`}
                subheader={subHeaderText}

            />


            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {dayjs(data.event.eventDate).format(dateStringFormat)}
                </Typography>
                <Box>
                    <ul>
                        <InfoItem title="IP" info={data.event.ip} />

                        {data.event.ipInfo && <InfoItem title="City" info={data.event.ipInfo.city} />}
                        {data.event.ipInfo && <InfoItem title="Region" info={data.event.ipInfo.region} />}
                        {data.event.ipInfo && <InfoItem title="Postal Area" info={data.event.ipInfo.postal} />}
                        {data.event.ipInfo && <InfoItem title="location" info={longLat} />}
                    </ul>
                    {!data.event.ipInfo && <p>No additional IP information for request</p>}
                </Box>
            </CardContent>


            {!isAcknowledged && <CardActions>
                <Button data-testid="eventAckButton" variant="contained" color="error" sx={{ width: "100%" }} onClick={handleAckClick}>Acknowledge Event</Button>
            </CardActions>}
        </EventCard>
    )

}


export default PocketLitter;