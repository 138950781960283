import { Box, Paper, Grid, Typography } from "@mui/material";

const DeviceFeed = (props: { title: string, devices: JSX.Element[]}) => {

    return (
        <Box component={Paper} elevation={2} sx={{ overflowX: 'auto', p: 1, scrollbarWidth: 'thin', mb: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', flexDirection: 'row', flex: '1 1 1px' }}>
                    <Typography variant="h5">{props.title}</Typography>
                </Grid>
            </Grid>
            {props.devices}
        </Box>
    );
}

export default DeviceFeed;