import { useContext } from "react";
import LogCommands from "./LogCommands";
import { LogContext, LogContextType } from "./LogContext";
import LogViewer from "./LogViewer";
import Loading from "../Common/Loading";

const LogContainer = () => {

    const {loading} = useContext(LogContext) as LogContextType;

    if(loading) {
        return (
            <div>Loading log viewer ... </div>
        )
    }

    if(loading)
    {
        return (
            <Loading />
        )
    }

    return (
        <>
            <LogCommands />
            <LogViewer />
        </>
    )
}
export default LogContainer;