import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { IUser } from "../../Models/IUser";
import { IUserRequest } from "../../Models/IUserRequest";
import validator from 'validator'
import { IRole } from "../../Models/IRole";
import useViewPortDimensions from "../../Hooks/useViewPort";
import { grey } from "@mui/material/colors";
import { IGroup } from "../../Models/IGroup";

const UserManagerCreate = () => {

    const { viewPortHeight } = useViewPortDimensions();
    const [user, setUser] = useState<IUser>({ id: "00000000-0000-0000-0000-000000000000", userName: "", enabled: true, roles: [], groups: [] });
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);

    const [groups, setGroups] = useState<IGroup[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const { PushSuccess } = useNotifications();
    const { Get, Post } = useAPI();
    const nav = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {

        const promises = [
            Get('/api/usermanager/roles'),
            Get('/api/usermanager/groups')
        ];

        Promise.all(promises).then((values) => {
            setRoles(values[0]);
            setGroups(values[1]);
        });


    }, []);

    const createUser = () => {

        let _errors = [];

        if (!user.userName && user.userName.length < 2) {
            _errors.push("User name is required");
        }

        if (!strongPassword) {
            _errors.push("Password must me at least 10 characters, contain a mixture of upper and lowercase characters, at least 1 number, and at least 1 symbol.");
        }

        setErrors(_errors);

        if (_errors.length === 0) {
            const userRequest: IUserRequest = {
                user: user,
                password: password
            };
            Post('/api/usermanager', userRequest)
                .then(() => {
                    PushSuccess("User created");
                    nav("/settings/users");
                })
        }
    }

    const validate = (value: string) => {
        setPassword(value);
        setStrongPassword(validator.isStrongPassword(value, {
            minLength: 10, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        }));
    }

    const isInArray = (value: string, list: string[]):boolean => {
        return list.indexOf(value) > -1;
    }

    const onRoleClicked = (id: string) => {
        console.log('toggle role', id);
        const nextRoles = isInArray(id, user.roles) ?
            user.roles.filter(x => x !== id)
            :
            [...user.roles, id];

        setUser({ ...user, roles: nextRoles });
    }

    const onGroupClicked = (id: string) => {
        console.log('toggle group', id);

        const nextGroups = isInArray(id, user.groups) ?
            user.groups.filter(x => x !== id)
            :
            [...user.groups, id];

        setUser({ ...user, groups: nextGroups });

    }



    return (

        <Box sx={{ mt: 0, p: 1, maxHeight: viewPortHeight, overflowY: 'auto', scrollbarWidth: 'thin' }} component={Paper} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">Create user</Typography>
                </Grid>
                {errors.length > 0 && (
                    <Grid item xs={12}>
                        <List dense>
                            {errors.map((err: string, i: number) => {
                                return <ListItem key={i}><ListItemText primary={err} primaryTypographyProps={{color:'red'}} /></ListItem>
                            })}
                        </List>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField autoComplete="off" sx={{ my: 1, width: '100%' }} label="Username" onKeyUp={(e: any) => setUser({ ...user, userName: e.target.value })} required data-testid="txt_createuser_username"/>
                    <FormControl sx={{ width: '100%' }} variant="outlined" onKeyUp={(e: any) => validate(e.target.value)}>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            data-testid="txt_createuser_password"
                            autoComplete="off"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Roles"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {roles.map(r => <ListItem key={r.id} sx={{ py: 0 }}>

                            <ListItemButton role={undefined} onClick={(e: any) => onRoleClicked(r.id)} sx={{ py: 0 }} data-testid={`btn_createuser_role_${r.name}`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isInArray(r.id, user.roles)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={r.name} />
                            </ListItemButton>
                        </ListItem>)}
                    </List>
                </Grid>
                <Grid item sm={12} md={6}>
                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Groups"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {groups.map(g => <ListItem key={g.id} sx={{ py: 0 }}>

                            <ListItemButton role={undefined} onClick={(e: any) => onGroupClicked(g.id)} sx={{ py: 0 }} data-testid={`btn_createuser_group_${g.name}`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isInArray(g.id, user.groups)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={g.name} />
                            </ListItemButton>
                        </ListItem>)}

                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                        <Button sx={{ mr: 1 }} variant="contained" color="success" onClick={e => createUser()} data-testid="btn_createuser_save">Create</Button>
                        <Button component={NavLink} to={`/settings/users`} data-testid="btn_createuser_cancel" sx={{
                            mr: 1,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: grey[800]
                            }
                        }} >Cancel</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
}

export default UserManagerCreate;