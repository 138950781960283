import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField, Tooltip } from "@mui/material";
import { KeyboardEvent, useContext } from "react";
import { TripwireRoles } from "../../../constants/TripwireRoles";
import useRoles from "../Hooks/useRoles";
import { LogContext, LogContextType } from "./LogContext";
import useAPI from '../Hooks/useAPI';
import { useAuth } from "react-oidc-context";
import dayjs from "dayjs";


const LogCommands = () => {

    const isAdmin = useRoles([TripwireRoles.Admin]);
    const { Get } = useAPI();
    const auth = useAuth();

    const { query, options, updateQuery, clearLogs } = useContext(LogContext) as LogContextType;


    //Todo: debounce.
    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        updateQuery("searchvalue", value);
    }

    const handleMessageIdChanged = (e: any) => {
        const { target: { value } } = e;

        const next = typeof value === 'string' ? value.split(',') : value;
        updateQuery("messageids", next);
    }


    const onSeverityChanged = (e: any) => {
        const { target: { value } } = e;
        const next = typeof value === 'string' ? value.split(',') : value;
        updateQuery("loglevels", next);
    }

    const download = async (e: any) => {
        const data = await Get(`/api/TripwireLog/download?searchValue=${query.searchValue}&logLevels=${query.severity.join(',')}&messageIds=${query.messageIds.join(',')}`)
        const newLinesData = data.map((x:string) => x + "\r\n");
        const blob = new Blob([...newLinesData], { type: "text/plain" });
        const filename = `Logs-${dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss")}.txt`;
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    return (
        <>
            <Box component={Paper} elevation={3} sx={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                <TextField
                    onKeyUp={handleKeyUp}
                    sx={{ display: "flex", flexGrow: 1 }}
                    placeholder="search logs messages ... "
                    size="small"
                    data-testid="logviewer_txt_searchlogs"
                />

                <Tooltip title="Download logs">
                    <IconButton onClick={download} data-testid="logviewer_btn_downloadlogs">
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>

                {isAdmin && (
                    <Tooltip title="Clear logs">
                        <IconButton color="error" onClick={(e) => clearLogs()} data-testid="logviewer_btn_clearlogs">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>)}
            </Box>
            <Box component={Paper} sx={{ my: 1, p: 1 }}>
                <FormControl>
                    <InputLabel id="">Log Level</InputLabel>
                    <Select
                        size="small"
                        multiple
                        onChange={onSeverityChanged}
                        input={<OutlinedInput label="Log Level" />}
                        value={query.severity}
                        renderValue={(selected) => selected.join(", ")}
                        sx={{ minWidth: 130 }}
                        data-testid="logviewer_ddl_loglevel"
                    >
                        {options?.logLevels.map((sev) => {
                            return (
                                <MenuItem key={sev} value={sev}>
                                    <Checkbox checked={query.severity.indexOf(sev) > -1} />
                                    <ListItemText primary={sev} />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ ml: 1 }}>
                    <InputLabel>Message ID</InputLabel>
                    <Select
                        size="small"

                        multiple
                        onChange={handleMessageIdChanged}
                        input={<OutlinedInput label="Message Id" />}
                        value={query.messageIds}
                        renderValue={(selected) => selected.join(", ")}
                        sx={{ minWidth: 130 }}
                        data-testid="logviewer_ddl_messageid"
                    >
                        {options?.messageIds.map((mid) => {
                            return (
                                <MenuItem key={mid} value={mid}>
                                    <Checkbox checked={query.messageIds.indexOf(mid) > -1} />
                                    <ListItemText primary={mid} />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
}

export default LogCommands;