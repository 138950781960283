import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useEffect, useState } from 'react';
import {useControl} from 'react-map-gl';

import type { ControlPosition} from 'react-map-gl';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  selectedFeature: any | null;
  onCreate: (evt: {features: object[]}) => void;
  onUpdate: (evt: {features: object[]; action: string}) => void;
  onDelete: (evt: {features: object[]}) => void;  
  onModeChanged: (evt:any) => void;
};

export default function DrawControl(props: DrawControlProps) {

  const [draw] = useState<MapboxDraw>(new MapboxDraw(props));


  const draw_create = (e:any) => {
    props.onCreate(e);
    draw.delete(e.features[0].id);
  }

  const draw_update = (e:any) => {
    props.onUpdate(e);
  }

  const draw_delete = (e:any) => {
    props.onDelete(e);
  }

  const draw_modechange = (e:any) => {
    if(e.mode === "simple_select") {
      draw.deleteAll();
      props.onModeChanged(e);
    }
    
  }

  useEffect(() => {

    
    const feature = props.selectedFeature;
    if(feature === null) {
      try {
        //this is to handle clicking off a feature and getting rid of the blue border.
        //struggling to find a way to tell if draw is initialized, this only seems to blow up on mount so wrapping
        //in a tryCatch for now. 
        draw.deleteAll();
      }
      catch{}
      return;
    }

    const drawAblefeature = {
      ...feature,
      id: feature.properties.UserDataId,
    };

    draw.deleteAll();
    draw.add(drawAblefeature);

    //this requires some features to be double clicked before they can be actioned.
    //going direct to direct_select mode for polygons and lines causes an issues
    //where that feature will not trigger the draw.delete action 
    draw.changeMode(draw.modes.SIMPLE_SELECT);
  }, [props.selectedFeature]);

  useControl<MapboxDraw>(
    () => draw,
    (context: MapContextValue) => {
      context.map.on('draw.create', draw_create);
      context.map.on('draw.update', draw_update);
      context.map.on('draw.delete', draw_delete);
      context.map.on('draw.modechange',draw_modechange)
    },
    (context: MapContextValue) => {
        context.map.off('draw.create', draw_create);
        context.map.off('draw.update', draw_update);
        context.map.off('draw.delete', draw_delete);
        context.map.off('draw.modechange',draw_modechange)
    },
    {
      position: props.position
    }
  );

  return null;
}
