import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loading from "../../Common/Loading";
import PageTemplate from "../../Common/Page";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { IApiResult } from "../../Models/IApiResult";
import { INomadLink } from "../../Models/INomadLink";

const LinkManagerEdit = () => {

    const { id } = useParams();
    const [link, setLink] = useState<INomadLink | null>(null);
    const { Get, Put } = useAPI();
    const { PushSuccess, PushError } = useNotifications();
    const [errors, setErrors] = useState<string[]>([]);
    const nav = useNavigate();

    useEffect(() => {
        Get('/api/nomad/linkmanager/' + id)
            .then((data: INomadLink) => {
                setLink(data);
            });
    }, []);

    const updateLink = () => {

        let _errors = [];

        if(link === null) {
            _errors.push("Unable to edit link.");
            return;
        }

        if (!link.linkText && link.linkText.length < 2) {
            //link text is required
            _errors.push("Link text is required");
        }

        if (!link.linkUrl || !link.linkUrl.startsWith("http")) {
            _errors.push("Link URL must be a valid URL");
            //link url must be populated and start with http. 
        }

        setErrors(_errors);

        if (errors.length === 0) {

            Put('/api/nomad/linkmanager', link)
                .then((data:IApiResult) => {
                    if(data.success) {
                        nav(`/settings/links`);
                        PushSuccess(data.messages[0]);
                    }
                    else {
                        PushError("Unable to save link changes.");
                        setErrors(errors);
                    }
                });
        }

    }

    if (link === null) {
        return <PageTemplate heading="Edit Link" subtitle=""><Loading /></PageTemplate>
    }

    return (
        <PageTemplate heading="Edit Link" subtitle="">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TextField value={link.linkText} sx={{ my: 1 }} label="Link Text" onChange={(e: any) => setLink({ ...link, linkText: e.target.value })} required />
                <TextField value={link.linkUrl} sx={{ my: 1 }} label="Link URL" onChange={(e: any) => setLink({ ...link, linkUrl: e.target.value })} required />
                <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                    <Button sx={{ mr: 1 }} variant="contained" onClick={updateLink} >Save</Button>
                    <Button component={NavLink} to={`/settings/links`} variant="contained" color="error"  >Cancel</Button>
                </Box>
            </Box>
        </PageTemplate>
    );
}

export default LinkManagerEdit;