import { Box, Card, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Drawer, Paper, Grid, TextField, Chip } from "@mui/material";
import { ReacherDevice } from "../Models/ReacherDevice";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ScienceIcon from '@mui/icons-material/Science';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useContext, useState } from "react";
import { ReacherContext, ReacherContextType } from "../ReacherContext";
import { blue, green, red } from "@mui/material/colors";
import useAPI from "../../Hooks/useAPI";
import useNotifications from "../../Hooks/useNotifications";
import { IApiResult } from "../../Models/IApiResult";
import ReacherDialog from "../Components/ReacherDialog";
import { ReacherDialogOptions } from "../Components/ReacherDialogOptions";
import EditDrawer from "../Components/EditDrawer";
import { EditDrawerOptions } from "../Components/EditDrawerOptions";

const Device = (device: ReacherDevice) => {
    let initialOptions: ReacherDialogOptions = {
        isOpen: false,
        title: "",
        action: "",
        message: "",
        onClose: () => { },
        onCancel: () => { },
        onConfirm: () => { }
    };
    const [dialogOptions, setDialogOptions] = useState<ReacherDialogOptions>(initialOptions);

    let initialEditOptions: EditDrawerOptions = {
        isOpen: false,
        onClose: () => { },
        onCancel: () => { },
        onChange: () => { },
        onConfirm: () => { },
        device: device
    };
    const [editDrawerOptions, setEditDrawerOptions] = useState<EditDrawerOptions>(initialEditOptions);

    const { Post } = useAPI();
    const { PushSuccess, PushError } = useNotifications();
    const { SelectDevice, DeSelectDevice } = useContext(ReacherContext) as ReacherContextType;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onPanelClick = () => {
        if (device.selected)
            DeSelectDevice(device.id);
        else
            SelectDevice(device.id);
    }

    const onDeviceMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    }

    const onDeviceMenuClose = (event: any) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const menuAction = (event: any, action: string) => {
        switch (action) {
            case "DELETE":
                setDialogOptions({
                    onClose: (event) => closeMenuAndDialog(event),
                    onCancel: (event) => closeMenuAndDialog(event),
                    onConfirm: (event) => deleteAction(event),
                    isOpen: true, action: action, title: action,
                    message: "This will permanently delete the content and remove the device are you sure?"
                });
                break;
            case "REMOVE":
                setDialogOptions({
                    onClose: (event) => closeMenuAndDialog(event),
                    onCancel: (event) => closeMenuAndDialog(event),
                    onConfirm: (event) => removeAction(event),
                    isOpen: true, action: action, title: action, message: "This will remove the device are you sure?"
                });
                break;
            case "RENAME":
                setEditDrawerOptions({
                    isOpen: true,
                    onClose: (event) => closeEditDrawer(event),
                    onCancel: (event) => closeEditDrawer(event),
                    onConfirm: (event, data) => renameAction(event, data),
                    onChange: (data) => editData(data),
                    device: device
                })
                setAnchorEl(null);
                break;
            case "CLEARFEED":
                clearFeed();
                break;
            default:
        }

        event.stopPropagation();
    };

    const closeMenuAndDialog = (event: any) => {
        setDialogOptions(initialOptions);
        setAnchorEl(null);
        event.stopPropagation();
    };

    const closeEditDrawer = (event: any) => {
        setEditDrawerOptions(initialEditOptions);
        setAnchorEl(null);
        event.stopPropagation();
    };

    const deleteAction = (event: any) => {
        Post('/api/reacher/device/delete', { deviceId: device.id })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => closeMenuAndDialog(event));
    }

    const removeAction = (event: any) => {
        Post('/api/reacher/device/remove', { deviceId: device.id })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => closeMenuAndDialog(event));
    }

    const editData = (modified: EditDrawerOptions) => {
        setEditDrawerOptions(modified);
    }

    const renameAction = (event: any, data: EditDrawerOptions) => {
        Post('/api/reacher/device/rename', { DeviceId: device.id, DeviceName: data.device.friendlyDeviceName })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => closeMenuAndDialog(event));


        closeEditDrawer(event);
        closeMenuAndDialog(event);
    }

    const clearFeed = () => {
        Post('/api/reacher/device/clearfeed', { deviceId: device.id })
            .then((data: IApiResult) => {
                if (data.success) {
                    PushSuccess(data.messages[0]);
                }
                else {
                    PushError(data.messages[0]);
                }
            })
            .finally(() => setAnchorEl(null));
    }

    const colour = device.alive ? green[500] : red[500];

    const testChip = device.testMode ? <ScienceIcon color="info" sx={{ m: 0, p: 0 }} /> : <></>;
    const trackChip = device.trackMode ? <GpsFixedIcon color="warning" sx={{ m: 0, p: 0 }} /> : <></>;

    return (
        <Card key={device.id} sx={{ display: 'flex', my: 1, p: 0 }} elevation={6} >
            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center', pl: 0, pr: 1, py: 0 }} onClick={onPanelClick} data-testid={"device" + device.androidDeviceId + "button"}>
                {/* temp hack to get around controlled/uncontrolled forms. this should be a checkbox with a ref.  */}
                <div style={{ height: '100%', width: '8px', backgroundColor: colour, content: ' ' }}></div>
                <IconButton>
                    {device.selected && <CheckBoxIcon />}
                    {!device.selected && <CheckBoxOutlineBlankIcon />}
                </IconButton>

                <Box sx={{  }} >
                    <Typography noWrap>{device.friendlyDeviceName ? device.friendlyDeviceName : device.androidDeviceName}</Typography>
                </Box>
                <Grid container sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center', flexDirection: 'row', p: 0, m: 0 }} >
                    {testChip}
                    {trackChip}
                    <IconButton onClick={onDeviceMenuOpen}  >
                        <MoreVertIcon />
                    </IconButton>
                </Grid>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={onDeviceMenuClose}
                >
                    <MenuItem onClick={e => menuAction(e, "RENAME")}>Edit</MenuItem>
                    <MenuItem onClick={e => menuAction(e, "CLEARFEED")}>Clear Feed</MenuItem>
                    <MenuItem onClick={e => menuAction(e, "REMOVE")}>Remove</MenuItem>
                    <MenuItem onClick={e => menuAction(e, "DELETE")}>Delete</MenuItem>
                </Menu>

            </Box>
            <ReacherDialog {...dialogOptions} />
            <EditDrawer {...editDrawerOptions} />
        </Card>);
}

export default Device;