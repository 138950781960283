import { Box, Button, Checkbox, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material";
import useViewPortDimensions from "../../Hooks/useViewPort";
import useAPI from "../../Hooks/useAPI";
import { useEffect, useState } from "react";
import { IRole } from "../../Models/IRole";
import { IUser } from "../../Models/IUser";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { IUserGroup } from "./Models/IUserGroup";
import useNotifications from "../../Hooks/useNotifications";
import { grey } from "@mui/material/colors";
import FancyLoader from "../../Common/FancyLoader";
import GroupIcon from '@mui/icons-material/Group';

const CreateUserGroup = () => {

    const { groupid } = useParams();
    const [mode, setMode] = useState<"Create" | "Edit">("Create");
    const { PushSuccess, PushError } = useNotifications();
    const { viewPortHeight } = useViewPortDimensions();
    const { Get, Post, Put } = useAPI();
    const [details, setDetails] = useState<IUserGroup>({ id: null, name: "", roles: [], users: [] });
    const [nameChanged, setNameChanged] = useState<boolean>(false);
    const [groupNameHelperText, setGroupNameHelperText] = useState<string>("");
    const [users, setUsers] = useState<IUser[]>([]);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const nav = useNavigate();

    const [groupNameForTitle, setGroupNameForTitle] = useState<string>("");

    useEffect(() => {

        setMode(groupid ? "Edit" : "Create");

        const promises = [
            Get('/api/usermanager/roles'),
            Get('/api/usermanager'),
            groupid && Get(`/api/usermanager/usergroups/${groupid}`)
        ];


        Promise.all(promises).then((values) => {
            setRoles(values[0]); //role data
            setUsers(values[1]); //user data
            if (values[2]) {
                setDetails(values[2]); //user group details
                setGroupNameForTitle(values[2].name)
            }
            setLoading(false);
        });


    }, []);

    const onRoleClicked = (roleId: string) => {
        const current = details.roles;
        const next = isIdInList(roleId, current) ?
            current.filter(x => x !== roleId)
            :
            [...current, roleId];

        setDetails({ ...details, roles: next });
    }

    const onUserClicked = (userId: string) => {
        const current = details.users;
        const next = isIdInList(userId, current) ?
            current.filter(x => x !== userId)
            :
            [...current, userId];

        setDetails({ ...details, users: next });
    }

    const isIdInList = (id: string, list: string[]) => {
        return list.indexOf(id) > -1;
    }

    const onSaveGroup = (e: any) => {

        if (!details.name) {
            setGroupNameHelperText("Group name is required")
            setNameChanged(true);
            return;
        }


        var promise = mode === "Edit" ? Put('/api/usermanager/usergroups', details) : Post('/api/usermanager/usergroups', details);

        promise
            .then(result => {
                if (result.success) {
                    PushSuccess(result.message);
                    nav('/settings/users');
                }
                else {
                    PushError(result.message);
                    setNameChanged(true);
                    setGroupNameHelperText("Group name in use.");
                }
            })

    }

    const onGroupNameChanged = (e: any) => {
        setNameChanged(true);
        setDetails({ ...details, name: e.target.value });
    }

    if (loading) {
        return (
            <Box sx={{ mt: 1, p: 0, height: viewPortHeight }}>
                <FancyLoader error={false} message={`Loading group data`} icon={<GroupIcon />} />
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 0, p: 1, maxHeight: viewPortHeight, overflowY:'auto', scrollbarWidth:'thin'  }} component={Paper} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <Typography variant="h4">
                            {mode} user group {groupNameForTitle}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        data-testid="txt_usergroupname"
                        label="Group name *"
                        fullWidth
                        value={details.name}
                        onChange={onGroupNameChanged}
                        error={nameChanged && (details.name ?? "").length === 0}
                        helperText={groupNameHelperText}
                    />

                </Grid>
                <Grid item xs={12} md={6} >

                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Group roles"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {roles.map(r => <ListItem key={r.id} sx={{ py: 0 }}>

                            <ListItemButton role={undefined} onClick={(e: any) => onRoleClicked(r.id)} sx={{ py: 0 }} data-testid={`btn_usergroup_role_${r.name}`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isIdInList(r.id, details.roles)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={r.name} />
                            </ListItemButton>
                        </ListItem>)}

                    </List>

                </Grid>
                <Grid item xs={12} md={6} >

                    <List dense>
                        <ListItem>

                            <ListItemText
                                primary="Group users"
                                primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0
                                }}
                            />
                        </ListItem>
                        {users.map(u => <ListItem key={u.id} sx={{ py: 0 }}>

                            <ListItemButton role={undefined} onClick={(e: any) => onUserClicked(u.id)} sx={{ py: 0 }} data-testid={`btn_usergroup_user_${u.userName}`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isIdInList(u.id, details.users)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={u.userName} />
                            </ListItemButton>
                        </ListItem>)}

                    </List>

                </Grid>
                <Grid item xs={12}>
                <Button data-testid="btn_saveusergroup" variant="contained" color="success" onClick={onSaveGroup}>{mode == "Edit" ? "Save" : mode} group</Button>
                <Button data-testid="btn_cancelusergroup" component={NavLink} to={`/settings/users`}
                        sx={{
                            ml: 1,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: grey[800]
                            }
                        }}>cancel</Button>
                    
                </Grid>
            </Grid>

        </Box>

    );
}

export default CreateUserGroup;
