import { Box, CardMedia, Skeleton } from "@mui/material";
import { ReacherDeviceCommand } from "../../../Models/ReacherDeviceCommand";
import ImageThumbnail from "../Modals/ImageThumbnail";
import { useMemo } from "react";

const ReacherMediaPicture = (deviceCommand: ReacherDeviceCommand) => {

    const thumbnail_memo = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'left' }}>
            {deviceCommand.fileIds && deviceCommand.fileIds.map((f) => <ImageThumbnail DeviceCommandId={deviceCommand.id} imageId={f} key={f} />)}
        </Box>
        );
    }, [deviceCommand.fileIds])

    const media = deviceCommand.status.toUpperCase() === "COMPLETED" ?
    thumbnail_memo
        : <Skeleton variant="rectangular" width={100} height={100}></Skeleton>;

    return <CardMedia sx={{ display: 'flex', flexDirection: 'row' }}>{media}</CardMedia>;
}

export default ReacherMediaPicture;