import { Box, IconButton, Paper, Typography, capitalize } from "@mui/material";
import { ReactElement, useCallback, useContext, useMemo, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import useViewPortDimensions from "../../Hooks/useViewPort";
import { NomadMapControl, NomadMapControlContainer } from "./NomadMapControls";
import PolylineIcon from '@mui/icons-material/Polyline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DeviceList from "./atlasDevice/DeviceList";
import { IFeatureCollection } from "../types/IFeatureCollection";
import { AtlasFeatureList } from "./AtlasFeatures/AtlasFeatureList";


export type AtlasSidebarModes = "CLOSED" | "DEVICES" | "FEATURES"

export interface IAtlasSidebarProps {
    userdata : IFeatureCollection,
    onEditFeature: (id: string, label: string, colour: string) => void,
    onDeleteFeature: (id:string) => void,
    panTo: (coordinates: number[]) => void,
    
}

const AtlasSidebar = (props: IAtlasSidebarProps) => {

    const { viewPortHeight } = useViewPortDimensions();
    const [activeMode, setActiveMode] = useState<AtlasSidebarModes>("CLOSED");

    const toggleActiveMode = (nextMode: AtlasSidebarModes) => {
        const next = nextMode === activeMode ? "CLOSED" : nextMode;
        setActiveMode(next);
    }

    let active: ReactElement | ReactElement[] | Element[] | Element = <></>
    switch (activeMode) {
        case "DEVICES":
            active = <DeviceList panTo={props.panTo} />;
            break;
        case "FEATURES":
            active = <AtlasFeatureList panTo={props.panTo} userdata={props.userdata} onDeleteFeature={props.onDeleteFeature} onEditFeature={props.onEditFeature} />
            break;
        default:
            <></>
    }

    return (
        <>
            <NomadMapControlContainer>
                <NomadMapControl onClick={(e) => toggleActiveMode("DEVICES")} icon={<PhoneIphoneIcon />} active={activeMode === "DEVICES"} data-testid="atlas_device_panel_toggle_button"></NomadMapControl>
                <NomadMapControl onClick={(e) => toggleActiveMode("FEATURES")} icon={<PolylineIcon />} active={activeMode === "FEATURES"} data-testid="atlas_feature_panel_toggle_button"></NomadMapControl>
            </NomadMapControlContainer>

            {activeMode !== "CLOSED" &&
                <Box
                    component={Paper}
                    sx={{
                        position: 'absolute',
                        right: 48,
                        top: 8,
                        width: 400,
                        height: viewPortHeight - 16
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: 20,
                            px: 2,
                            py: 2
                        }}
                    >
                        <Typography variant="h6" sx={{ display: 'inline-block', textTransform: 'capitalize' }}>{(activeMode as string).toLowerCase()}</Typography>
                        <IconButton sx={{ ml: 'auto' }} size="small" onClick={(e: any) => setActiveMode("CLOSED")}><CloseIcon /></IconButton>
                    </Box>
                    <Box
                        sx={{
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            height: viewPortHeight - 46
                        }}>
                        
                        {active}
                    </Box>


                </Box>
            }

        </>

    );

}

export default AtlasSidebar;