import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import RoomIcon from '@mui/icons-material/Room';
import { Box, Typography } from "@mui/material";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Map, {
    FullscreenControl,
    Layer,
    LayerProps,
    NavigationControl,
    ScaleControl,
    Source
} from 'react-map-gl/maplibre';
import { useSearchParams } from "react-router-dom";
import FancyLoader from '../Common/FancyLoader';
import useAPI from '../Hooks/useAPI';
import useSettings from '../Hooks/useSettings';
import useViewPortDimensions from '../Hooks/useViewPort';
import AtlasSidebar from "./components/AtlasSidebar";
import DrawControl from './draw-control';
import { GeoDataUpdateContext, GeoDataUpdateContextType } from "./GeoDataUpdateContext";

import { updateUserData } from "./reducers/dispatchUserData";
import { MapLayerMouseEvent } from "maplibre-gl";
import { Popup } from "react-map-gl";
import * as turf from '@turf/turf';
interface EditDrawerOptions {
    open: boolean
}



const MapContainer = () => {
    const mapRef = useRef<any>(null);

    const { viewportWidth, viewPortHeight } = useViewPortDimensions();
    const apiOptions = useSettings();
    const { Get, Post, Put, Delete } = useAPI();
    const { devices, connstate } = useContext(GeoDataUpdateContext) as GeoDataUpdateContextType;
    const [deviceGEO, setDeviceGEO] = useState<any | null>(null);
    const [deviceTrails, setDeviceTrails] = useState<any | null>(null);


    const [mapStyle, setMapStyle] = useState<any | null>(null);

    const [selectedFeature, setSelectedFeature] = useState<any | null>(null);

    const [searchParams] = useSearchParams();
    const lat = searchParams.get("lat")?.toLowerCase();
    const lng = searchParams.get("lng")?.toLowerCase();

    const [trailPopup, setTrailPopup] = useState<any>({ visible: false, lat: 0, lng: 0, data:null });

    const [viewState, setViewState] = useState({
        latitude: lat ? Number(lat) : 51.5,
        longitude: lng ? Number(lng) : 0,
        zoom: 14
    });




    const [userData, dispatchUserData] = useReducer(updateUserData, { type: "FeatureCollection", features: [], lastUpdated: new Date().getTime() });

    useEffect(() => {

        const promises = [
            Get(`${apiOptions.geo}/api/geodata/mapstyle`),
            Get(`${apiOptions.geo}/api/geodata/userdata`)
        ];

        Promise.all(promises).then((values) => {
            setMapStyle(values[0]);
            dispatchUserData({ type: 'INIT', event: values[1] });
        });
    }, []);


    useEffect(() => {

        if (!devices) return;

        const features = Array.from(devices.values()).filter(x => x.visible).map((x: any) => x.geoJSON);
        const collection = {
            lastChanged: new Date().getTime(),
            type: "FeatureCollection",
            features
        };

        const trails = Array.from(devices.values()).filter(x => x.trailVisible).map((x: any) => x.trailJson);
        const trailCollection = {
            lastChanged: new Date().getTime(),
            type: "FeatureCollection",
            features: trails
        };
        setDeviceTrails(trailCollection);
        setDeviceGEO(collection);
    }, [devices])

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.resize();
        }
    }, [viewportWidth])

    const onMapLoaded = (e: any) => {

        const loadPNG = (name: string, icon_location: string) => {
            mapRef.current.loadImage(icon_location, (error: any, image: any) => {
                if (error) {
                    console.error(error);
                    return;
                }
                mapRef.current.addImage(name, image);
            })
        };

        loadPNG('location', './mapicons/location.png');
        loadPNG('phone', './mapicons/smartphone.png');
        loadPNG('alert', './mapicons/alert.png');
    }

    const onFeatureClicked = (features: any[]) => {
        const id = features[0].properties.UserDataId;
        const matchingFeatures = userData.features.filter(x => x.properties.UserDataId === id);

        if (matchingFeatures.length > 0) {
            setSelectedFeature(matchingFeatures[0]);
        }
        else {
            console.error(`No feature found with UserDataId of ${id}`);
            console.error(userData);
        }

    };

    const getItem = (id: string) => {
        if (!userData?.features) return;
        return userData.features.find((f: any) => f.properties['UserDataId'] == id);
    }

    const onEditData = (id: string, label: string, colour: string) => {
        const currentFeature = userData.features.find(x => x.properties.UserDataId === id);
        if (!currentFeature) return;

        const updatedFeature = {
            ...currentFeature,
            properties: {
                ...currentFeature.properties,
                fillColour: colour,
                label: label
            }
        };

        Put(`${apiOptions?.geo}/api/geodata/userdata`, JSON.stringify(updatedFeature))
            .then((data: any) => {
                var geo = JSON.parse(data.geoJson);

                dispatchUserData({ type: "UPDATE", event: { features: [geo] } });
            });

    }

    const onDeleteData = (id: string) => {
        let target = getItem(id);
        if (!target) return;

        let feature = userData.features.find((f: any) => f.properties['UserDataId'] == id);
        Delete(`${apiOptions?.geo}/api/geodata/userdata`, JSON.stringify(feature))
            .then((data: any) => {
                dispatchUserData({ type: "DELETE", event: { features: [feature] } });
            });

    }

    const onUpdate = useCallback((e: any) => {

        const feature = {
            ...e.features[0]
        };

        feature.properties.drawId = feature.id;
        delete feature.id;

        Put(`${apiOptions?.geo}/api/geodata/userdata`, JSON.stringify(feature))
            .then((data: any) => {
                var geo = JSON.parse(data.geoJson);
                dispatchUserData({ type: "UPDATE", event: { features: [geo] } });
            })

    }, [apiOptions, userData]);

    const onCreate = useCallback((e: any) => {
        const feature = {
            ...e.features[0]
        };

        feature.properties.drawId = feature.id;
        delete feature.id;
        Post(`${apiOptions?.geo}/api/geodata/userdata`, JSON.stringify(feature))
            .then((data: any) => {
                var geo = JSON.parse(data.geoJson);
                dispatchUserData({ type: "CREATE", event: { features: [geo] } });
            })
    }, [apiOptions, userData]);

    const onDelete = useCallback((e: any) => {
        setSelectedFeature(null);
        const feature = {
            ...e.features[0]
        };
        Delete(`${apiOptions?.geo}/api/geodata/userdata`, JSON.stringify(feature))
            .then((data: any) => {
                dispatchUserData({ type: "DELETE", event: { features: [feature] } });
            });


    }, [apiOptions, userData]);



    const feature_memo = useMemo(() => {

        const pointLayerStyle: LayerProps = {
            type: 'circle',
            paint: {
                "circle-color": ['get', 'fillColour'],
                "circle-radius": 6
            },
        };

        const polyLayerStyle: LayerProps = {
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': ['get', 'fillColour'],
                'fill-opacity': 0.3
            },
        };

        const polyStrokeLayer: LayerProps = {
            type: 'line',
            paint: {
                "line-color": ['get', 'fillColour'],
                "line-width": 2,
                "line-opacity": 0.6
            },
        };

        const glyphLayerStyle: LayerProps = {
            type: 'symbol',
            paint: {
                'text-color': 'black'
            },
            layout: {
                //'icon-image':'location',
                'text-field': ['get', 'label'],
                'text-font': ['Noto Sans Regular'],
                'text-anchor': 'top',
                'text-radial-offset': 1
            }
        }

        const lineLayerStyle: LayerProps = {
            type: 'line',
            paint: {
                'line-width': 2,
                'line-color': ['get', 'fillColour']
            }
        }

        const points = {
            type: 'FeatureCollection',
            features: userData.features.filter(p => p.geometry.type === "Point")
        }
        const polys = {
            type: 'FeatureCollection',
            features: userData.features.filter(p => p.geometry.type === "Polygon")
        }

        const lines = {
            type: 'FeatureCollection',
            features: userData.features.filter(l => l.geometry.type === "LineString")
        }

        return (
            <>
                <Source id="features_points" type="geojson" data={points}>
                    <Layer id="points" {...pointLayerStyle} />
                    <Layer id="points_glyph" {...glyphLayerStyle} />
                </Source>

                <Source id="features_polys" type="geojson" data={polys}>
                    <Layer id="polys" {...polyLayerStyle} />
                    <Layer id="polys_glyphs" {...glyphLayerStyle} />
                    <Layer id="polys_points" {...polyStrokeLayer} />

                </Source>

                <Source id="features_lines" type="geojson" data={lines}>
                    <Layer id="lines" {...glyphLayerStyle} />
                    <Layer id="lines_glyphs" {...lineLayerStyle} />
                </Source>
            </>
        );

    }, [userData]);


    const device_memo = useMemo(() => {

        const deviceLayer: LayerProps = {
            type: 'symbol',
            paint: {
                'text-color': 'black',
                'icon-color': ['get', 'fillColour'],
            },
            layout: {
                'icon-image': ['get', 'icon_type'],
                'icon-size': 0.6,
                'text-field': ['get', 'label'],
                'text-font': ['Noto Sans Regular'],
                'text-anchor': 'top',
                'text-radial-offset': 1,
            }
        }


            const deviceBackgroundLayer: LayerProps = {
                type: 'circle',
                paint: {
                    "circle-color": 'white',
                    "circle-radius": 18
                },
            };
        

        if (deviceGEO === null) return <></>

        return (
            <Source id="devices" type="geojson" data={deviceGEO}>
                <Layer id='device_background_layer' {... deviceBackgroundLayer } />
                <Layer id="device_layer" {...deviceLayer} />
                
            </Source>
        )
    }, [deviceGEO]);


    const trail_memo = useMemo(() => {

        if (deviceTrails === null) return <></>


        const lineLayerStyle: LayerProps = {
            type: 'line',
            paint: {
                'line-width': 2,
                'line-color': ['get', 'fillColour']
            }
        }


        const pointLayerStyle: LayerProps = {
            type: 'circle',
            paint: {
                "circle-color": ['get', 'fillColour'],
                "circle-radius": 4
            },
        };


        return (
            <Source id="trails" type="geojson" data={deviceTrails}>
                <Layer id="trail_line" {...lineLayerStyle} />
                <Layer id="trail_point" {...pointLayerStyle} />
            </Source>
        )

    }, [deviceTrails]);


    if (mapStyle === null || userData === null) {
        return <FancyLoader error={false} message={'Loading maps ...'} icon={<RoomIcon />} />
    }

    const onMapClicked = (e: any) => {

        if (e.features.length > 0) {
            onFeatureClicked(e.features);
        } else {
            setSelectedFeature(null);
        }
    }

    const onMouseEnter = (e: any) => {

        if (e.features.length === 0) return;
        const trails = e.features.filter((x: any) => x.source === "trails");
        if (trails.length === 0) return;

        const from = [e.lngLat.lng, e.lngLat.lat];
        const coords: [number, number][] = trails[0].geometry.coordinates as [number, number][];
        let min : any = null;
        coords.forEach((point: any, index: number) => {
            var distance = turf.distance(from, point, { units: 'radians' });
            const compare = { d: distance, i: index };
            if (min === null) {
                min = compare;
            }
            else if (compare.d < min.d) {
                min = compare;
            }
        });

        const activePoint = trails[0].properties[`point${min.i}`];

        setTrailPopup({
            visible: true,
            lat: e.lngLat.lat,
            lng: e.lngLat.lng,
            data:JSON.parse(activePoint)
        });


    }

    const onMouseLeave = (e: any) => {
        setTrailPopup({
            visible: false,
            lat: 0,
            lng: 0,
            dat:null
        });
    }

    return (
        <Box>
            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{ position: 'relative', width: viewportWidth, height: viewPortHeight }}
                mapStyle={mapStyle}
                onLoad={onMapLoaded}
                onClick={onMapClicked}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                interactiveLayerIds={['points', 'polys', 'lines', 'polys_points', 'lines_points', 'trail_point']}
                attributionControl={false}
            >

                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <ScaleControl position='bottom-left' />

                <DrawControl
                    selectedFeature={selectedFeature}
                    position="top-left"
                    displayControlsDefault={true}
                    defaultMode="simple_select"
                    controls={{
                        point: true,
                        polygon: true,
                        trash: true,
                        line_string: true,
                        combine_features: false,
                        uncombine_features: false
                    }}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onModeChanged={(evt: any) => setSelectedFeature(null)}
                />

                {device_memo}
                {feature_memo}
                {trail_memo}

                {trailPopup.visible &&
                    <Popup
                        latitude={trailPopup.lat}
                        longitude={trailPopup.lng}
                        onClose={() => setTrailPopup({ visible: false, lat: 0,lng: 0,dat:null})}
                        >
                        <Typography variant="subtitle1" sx={{color:'black'}}>Device:</Typography>
                        <Typography variant="subtitle2" sx={{color:'black'}}>{trailPopup.data.DeviceName}</Typography>
                        <Typography variant="subtitle1" sx={{color:'black'}}>Date:</Typography>
                        <Typography variant="subtitle2" sx={{color:'black'}}>{trailPopup.data.CaptureTime}</Typography>
                        <Typography variant="subtitle1" sx={{color:'black'}}>Lat:</Typography>
                        <Typography variant="subtitle2" sx={{color:'black'}}>{trailPopup.data.Lat}</Typography>
                        <Typography variant="subtitle1" sx={{color:'black'}}>Lng:</Typography>
                        <Typography variant="subtitle2" sx={{color:'black'}}>{trailPopup.data.Lng}</Typography>
                    </Popup>
                }

                <Box sx={{
                    position: 'absolute',
                    bottom: 40,
                    left: 10,
                }}>
                    <Box
                        sx={{
                            width: 'auto',
                            height: 'auto',
                            p: 0.5,
                            borderRadius: 8,
                            backgroundColor: connstate.toLowerCase().startsWith('con') ? 'green' : 'red'
                        }}>
                        {connstate}
                    </Box>

                </Box>



                <AtlasSidebar userdata={userData} onEditFeature={onEditData} onDeleteFeature={onDeleteData} panTo={(coords: number[]) => mapRef.current.panTo(coords)} />

            </Map>
        </Box>
    )
}


export default MapContainer;