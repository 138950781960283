import { IApiResult } from "../Models/IApiResult";
import { useAuth } from "react-oidc-context";
import useNotifications from "./useNotifications";

const useAPI = () => {

    //for now  simple hook to just remove the need to setup the request headers constantly. 
    const auth = useAuth();
    const { PushError } = useNotifications();

    const requestParams: RequestInit = {
        headers: {
            "Authorization": "Bearer " + auth.user?.access_token,
            "Content-Type": "application/json"
        }
    };

    const handleResponse = async (response: Response) => {
        if (response.ok) {
            return response.json();
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            
            return response.json().then((data: IApiResult) => {
                if (data.messages)
                PushError(data.messages.join(', '));
            });



        } else {
            PushError("Unable to complete request: " + response.statusText);
            throw new Error(response.statusText);
        }
    }

    const Get = (url: string): Promise<any> => {
        const getHeaders = { ...requestParams, method: "GET" };
        return fetch(url, getHeaders)
            .then(handleResponse);
    }

    const Put = <T>(url: string, data?: T): Promise<any> => {
        const putHeaders = {
            ...requestParams,
            method: "PUT",
            body: data ? JSON.stringify(data) : null
        };

        return fetch(url, putHeaders)
            .then(handleResponse);
    }

    const Post = <T>(url: string, data: T): Promise<any> => {
        const postHeaders = { ...requestParams, method: "POST", body: JSON.stringify(data) };
        return fetch(url, postHeaders)
            .then(handleResponse);
    }

    const Delete = <T>(url: string, data?: T): Promise<any> => {
        const deleteHeaders = { ...requestParams, method: "DELETE", body: data ? JSON.stringify(data) : "" };
        return fetch(url, deleteHeaders).then(handleResponse);
    }


    return { Get, Put, Post, Delete };

}

export default useAPI;

