import { Box, Link, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";
import { ReacherDeviceCommand } from "../../../../Models/ReacherDeviceCommand"
import LocationSkeleton from "./LocationItemSkeleton";
import { useEffect, useRef, useState } from "react";
import useSettings from "../../../../../Hooks/useSettings";
import useAPI from "../../../../../Hooks/useAPI";
import Map, { Marker } from 'react-map-gl/maplibre';

const LocationItem = (deviceCommand: ReacherDeviceCommand) => {

    const loaded = deviceCommand.status.toUpperCase() === "COMPLETED" || deviceCommand.status.toUpperCase() === "ALERT CREATED" || deviceCommand.latitude != null;
    const apiOptions = useSettings();
    const { Get } = useAPI();
    const [mapStyle, setMapStyle] = useState<any | null>(null);
    const mapContainer = useRef<any>(null);

    useEffect(() => {
        if (apiOptions == null) return;

        if (mapStyle) return;

        Get(`${apiOptions.geo}/api/geodata/mapstyle`)
            .then(s => {
                try {
                    setMapStyle(s)
                } catch { }
            });
    }, [apiOptions, Get, mapStyle]);

    if (!loaded) {
        return <LocationSkeleton />
    }

    const onMapLoaded = (e: any) => {
        try {
            let attribution = mapContainer.current.getContainer();
            attribution.getElementsByTagName('summary')[0].click()  // Hide the attribution in the thumbnail
            //attribution.getElementsByTagName('details')[0].remove();  // Remove the attribution entirely
        } catch (e) {
            console.log('Failed to hide attribution: ' + e);
        }
    }

    return (
        <>
            <Link href={`/atlas/map?lat=${deviceCommand?.latitude}&lng=${deviceCommand?.longitude}`} target="_blank" rel="noreferrer" underline="hover" color={grey[50]}>
                <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto 0', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography component="div" variant="subtitle2">Lat : {deviceCommand?.latitude}</Typography>
                        <Typography component="div" variant="subtitle2">Long: {deviceCommand?.longitude}</Typography>
                        <Typography component="div" variant="subtitle2">Time: {dayjs(deviceCommand?.timeCaptured).format("DD-MM-YYYY HH:mm:ss")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                        <Box>
                            <Map
                                ref={mapContainer}
                                initialViewState={{
                                    longitude: deviceCommand?.longitude,
                                    latitude: deviceCommand?.latitude,
                                    zoom: 13
                                }}
                                style={{ position: 'relative', width: 150, height: 100 }}
                                mapStyle={mapStyle}
                                onLoad={onMapLoaded}
                                interactive={false}
                            >
                                <Marker longitude={deviceCommand?.longitude} latitude={deviceCommand?.latitude} />
                            </Map>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </>
    )
}

export default LocationItem;